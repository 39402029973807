import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
  SimpleShowLayout,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

const EstablishmentsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const EstablishmentsList = (props) => {
  const classes = useStyles();

  return (
    <List
      //filters={<EstablishmentsFilter />}
      {...props}
      title="List des etablisements"
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="type" />
        {/*<TextField source="code" />*/}
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const EstablishmentsTitle = ({ record }) => {
  return <span>Establishments {record ? `"${record.type}"` : ""}</span>;
};

const EstablishmentsField = ({ record }) => {
  return (
    <div>
      <Typography variant="h6" color="grey">
        {" "}
        Establishments :{" "}
      </Typography>
      {record.code.map((item) => (
        <div>
          <Typography variant="h8">{item}</Typography>
          <br />
        </div>
      ))}
    </div>
  );
};

export const EstablishmentsShow = (props) => (
  <Show title={<EstablishmentsTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" disabled />
      <TextField source="type" />
      <TextField source="name" />
      <EstablishmentsField />
    </SimpleShowLayout>
  </Show>
);
