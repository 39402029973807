import React from 'react';
import { AppBar } from 'react-admin';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MyUserMenu from './MyUserMenu';

const useStyles = makeStyles({
    title: {
      flex: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    spacer: {
      flex: 1,
    },
});

const MyAppBar = (props) => {
    const classes = useStyles();
    return (
      <AppBar {...props} userMenu={<MyUserMenu />}>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        Spoward Dashboard
        <span className={classes.spacer} />
      </AppBar>
    );
  };

export default MyAppBar;
