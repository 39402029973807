import React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  ArrayInput,
  SimpleFormIterator,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function CroudFundedsCreate(props) {
  const { classes } = props;

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`Croud Funded created`);
    redirect("/croudFundeds");
    refresh();
  };

  const validateCreation = (values) => {
    const errors = {};
    if (values.team_id && values.teamGroup_id) {
      errors.teamGroup_id =
        "You cannot choose a team and a team group at the same time.";
    }
    return errors;
  };
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm validate={validateCreation}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput source="name" className={classes.formInput} />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <ReferenceInput
              label="Owner"
              source="owner"
              reference="users"
              validate={[required()]}
              className={classes.formInput}
            >
              <SelectInput optionText="email" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={5}>
            <ReferenceInput
              label="Categories"
              source="categories"
              reference="categories"
              validate={[required()]}
              className={classes.formInput}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <ReferenceInput
              label="Team"
              source="team_id"
              reference="Teams"
              allowEmpty
              className={classes.formInput}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>
        </Grid>

        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <ReferenceInput
              label="Team Group"
              source="teamGroup_id"
              reference="TeamsGroups"
              allowEmpty
              className={classes.formInput}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              multiline
              source="subtitle1"
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              multiline
              source="subtitle2"
              className={classes.formInput}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <NumberInput
              source="distanceToShowDonation"
              label="Distance to show donation"
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <DateInput source="startingDate" className={classes.formInput} />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <SelectInput
              source="currency"
              className={classes.formInput}
              choices={[
                { id: "Euro", name: "Euro" },
                { id: "Spows", name: "Spows" },
              ]}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <SelectInput
              source="status"
              className={classes.formInput}
              choices={[
                { id: "DONE", name: "DONE" },
                { id: "ONGOING", name: "ONGOING" },
              ]}
            />
            {/*<ReferenceInput label="Category" source="category" reference="categories"
                        validate={[required()]} className={classes.formInput}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>*/}
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <NumberInput
              source="amount_to_rise_goal"
              label="Amount to rise goal"
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <DateTimeInput
              source="remainingTime"
              label="Remaining Time"
              validate={[required()]}
              className={classes.formInput}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <NumberInput
              source="donationInProgress"
              label="Donation In Progress"
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <NumberInput
              source="donationCeiling"
              label="Donation Ceiling"
              className={classes.formInput}
            />
          </Grid>
        </Grid>
        <TextInput
          multiline
          source="projectDescription"
          style={{ width: "100%" }}
        />
        <TextInput source="biography" style={{ width: "100%" }} />
        <ImageInput source="videoCover" label="Cover Video" accept="image/*">
          <ImageField source="videoCover" title="title" />
        </ImageInput>
        <FileInput source="video" label="Video" accept="video/mp4">
          <FileField source="video" title="title" />
        </FileInput>
        <ArrayInput source="images">
          <SimpleFormIterator>
            <ImageInput source="image" label="Image" accept="image/*">
              <ImageField source="image" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(CroudFundedsCreate);
