import React, {useState, useContext} from "react";
import { UserContext } from "../../context/UserContext";
import { Edit, TextInput, SimpleForm, SelectInput, useGetOne } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@material-ui/core/IconButton';
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";

const styles = (theme) => ({
  formInput: {
    width: "300px",
  },
});

function DiscountCodesEdit(props) {
  const { classes } = props;
  const history = useHistory();
  const {updateCodes} = useContext(UserContext);
  const [inputText, setInputText] = useState("");

  const getUrlFromPath = () => {
    const paths = window.location.href.split("/");
    let url = paths[5];
    
    return url;
  };

  const { data, loaded } = useGetOne('discountCodes', getUrlFromPath());

  const [newCode, setNewCode] = useState({
    type: data.type,
    code: data.code,
  });
  
  /*useEffect(() => {
    setNewCode(data);
  }, [data]);*/

  if (!loaded) return <span>Loading</span>; 

  const handleChange = (event) => {
    const value = event.target.value;
    setNewCode({ ...newCode, type: value });
  };

  const handleChangeCode = (event) => {
    const value = event.target.value;
    setNewCode({ ...newCode, code: value });
  };

  const handleChangeCodes = (event) => {
    const value = event.target.value;
    setInputText(value);
  };

  function addCode () {
    setNewCode((prevNewCode) => {
    
      let item= {...prevNewCode};
      let codeList = item.code;
      let itemCodes = [...codeList, inputText];
      
      const updatedRestaurant = {...prevNewCode, code: itemCodes }
      return updatedRestaurant ;
    });
    setInputText("");
  }

  function removeCodesItem(index) {
      setNewCode((prevNewCode) => {
        let item= {...prevNewCode};
        let items = [...item.code];
        items.splice(index, 1);

        const updatedCode = {...prevNewCode, code: items }
        return updatedCode;
    });
  }

  function handleSave() {
    updateCodes(getUrlFromPath(), newCode);
    history.push('/discountCodes');
  }

  return (
    <Edit {...props}>
        <SimpleForm save={handleSave} >
          <SelectInput 
            source="type" 
            choices={[
              { id: 'ONE_FOR_ALL', name: 'ONE_FOR_ALL' },
              { id: 'ONE_BY_USER', name: 'ONE_BY_USER' },
            ]} 
            name="type"
            onChange={handleChange}
            className={classes.formInput}
            disabled />

            {newCode.type === "ONE_FOR_ALL" ? (
              <TextInput 
              //source="code" 
              name="code" 
              value={newCode.code}
              onChange={handleChangeCode} 
              className={classes.formInput} />
            ):(
              <div>
                <TextInput 
                  source="codes"
                  value=""
                  onChange={handleChangeCodes}
                  className={classes.formInput} />

                <IconButton aria-label="add" style={{width: 50}} onClick={addCode}>
                  <AddCircleIcon fontSize="medium" size="small"/>
                </IconButton>

                {newCode.code && newCode.code.map((el, index) => (
                  <div
                    style={{
                      padding: 5,
                      marginBottom: 5,
                      border: "1px solid #7a7a7a",
                      borderRadius: "5px",
                      fontSize: "1.2rem",
                      color: "#000",
                      width: "100%",
                    }}
                    key={el}
                  >
                      <Grid container direction="row">
                          <Grid item xs={10}>
                              {el}
                          </Grid>
                          <Grid item xs={1}>
                              <CloseIcon
                                  style={{ color: "#ff6900" }}
                                  fontSize="small"
                                  onClick={() => removeCodesItem(index)} />
                          </Grid>
                      </Grid>
                  </div>
                ))}
              </div>
            )}
      </SimpleForm>
    </Edit>
  );
}

export default withStyles(styles)(DiscountCodesEdit);
