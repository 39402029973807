import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
  SimpleShowLayout,
  DeleteButton,
  ImageField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

const TeamsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const TeamsGroupsList = (props) => {
  const classes = useStyles();

  return (
    <List
      //filters={<TeamsFilter />}
      {...props}
      title="List des etablisements"
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField label="Team" source="team.name" />
        <EditButton />
        <ShowButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const TeamGroupTitle = ({ record }) => {
  return <span>TeamsGroups {record ? `"${record.managerId}"` : ""}</span>;
};

export const TeamGroupShow = (props) => (
  <Show title={<TeamGroupTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" disabled />
      <TextField source="description" />
      <TextField source="name" />
      <TextField source="managerId" />
      <TextField label="team" source="team.name" />
      <ImageField source="image" />
    </SimpleShowLayout>
  </Show>
);
