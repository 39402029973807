import React, { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Edit,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  DateTimeInput,
  ImageField,
  FileField,
  useGetOne,
  Toolbar,
  SaveButton,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router";

const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
  listOfIngredients: {
    overflowX: "scroll",
    overflowY: "hidden",
    display: "flex",
    width: 900,
    marginLeft: "30%",
  },
});

function CroundFundedsEdit(props) {
  const { classes } = props;
  const history = useHistory();
  const { updateCroudFunded, reloadCrouds } = useContext(UserContext);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedVideoImage, setUploadedVideoImage] = useState(null);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [showNewImage, setShowNewImage] = useState(false);
  //const [image, setImage] = useState(null);
  //const [imageVideo, setImageVideo] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [nameVideo, setNameVideo] = useState(null);

  const getUrlFromPath = () => {
    const paths = window.location.href.split("/");
    let url = paths[5];

    return url;
  };

  const { data, loaded } = useGetOne("croudFundeds", getUrlFromPath());
  const [newCroud, setNewCroud] = useState({
    name: data.name,
    owner: data.owner,
    subtitle1: data.subtitle1,
    subtitle2: data.subtitle2,
    distanceToShowDonation: data.distanceToShowDonation,
    startingDate: data.startingDate,
    currency: data.currency,
    amount_to_rise_goal: data.amount_to_rise_goal,
    remainingTime: data.remainingTime,
    status: data.status,
    donationInProgress: data.donationInProgress,
    donationCeiling: data.donationCeiling,
    projectDescription: data.projectDescription,
    oldImages: data.images,
    images: [],
    videoCover: data.videoCover,
    categories: data.categories,
    team_id: data.team_id,
    teamGroup_id: data.teamGroup_id,
    teamSubGroup_id: "",
    video: data.video,
  });

  const [image, setImage] = useState(data.coverImage);
  const [imageVideo, setImageVideo] = useState(data.videoCover);
  const [video, setVideo] = useState(data.video);
  const [loader, setLoader] = useState(false);

  if (!loaded) return <span>Loading</span>;

  const handleChange = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    console.log("date value:", value);
    setNewCroud({ ...newCroud, [field]: value });
  };
  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setNewCroud({ ...newCroud, categories: value });
  };
  const handleCoverVideo = () => {
    const fileInput = document.getElementById("imageVideoInput");
    fileInput.click();
  };

  const handleVideo = () => {
    const fileInput = document.getElementById("videoInput");
    fileInput.click();
  };

  const handleImageVideoChange = (event) => {
    const img = event.target.files[0];
    if (img) {
      setUploadedVideoImage(URL.createObjectURL(img));
      //setShowImage(true);
      setImageVideo(img);
    }
  };

  const handleVideoChange = (event) => {
    const img = event.target.files[0];
    if (img) {
      setUploadedVideo(URL.createObjectURL(img));
      //setShowImage(true);
      setNameVideo(img.name);
      setVideo(img);
    }
  };

  function removeItem(index) {
    setNewCroud((prevNewCroud) => {
      let items = prevNewCroud;
      let listImages = [...items.oldImages];
      listImages.splice(index, 1);
      const updatedCroud = { ...prevNewCroud, oldImages: listImages };
      return updatedCroud;
    });
  }

  const handleAddPicture = () => {
    const fileInput = document.getElementById("newImageInput");
    fileInput.click();
  };

  const handleNewImageChange = (event) => {
    const img = event.target.files[0];
    if (img) {
      setNewCroud((prevNewCroud) => {
        let items = { ...prevNewCroud };
        let listNewImages = items.images;

        const updatedNewImages = [...listNewImages, img];
        items = { ...prevNewCroud, images: updatedNewImages };
        return items;
      });
      setShowNewImage(true);
      setNewImage(img);
    }
  };

  function removeNewItem(index) {
    setNewCroud((prevNewCroud) => {
      let items = prevNewCroud;
      let listNewImages = [...items.images];
      listNewImages.splice(index, 1);
      const updatedCroud = { ...prevNewCroud, images: listNewImages };
      return updatedCroud;
    });
  }

  function handleSave() {
    console.log("update:", newCroud);
    updateCroudFunded(getUrlFromPath(), newCroud, imageVideo, video);
    setLoader(!loader);
    setTimeout(() => {
      history.push("/croudFundeds");
    }, 3000);
  }

  /*if(reloadCrouds === true) { 
        setTimeout(() => {
            history.push('/croudFundeds');
            window.location.reload(true);
        }, 3000);
    }*/
  const validateCreation = (values) => {
    const errors = {};
    if (values.team_id && values.teamGroup_id) {
      errors.teamGroup_id =
        "You cannot choose a team and a team group at the same time.";
    }
    return errors;
  };
  const ImagesListField = ({ record }) => {
    return (
      <div>
        <Typography variant="h6" color="grey">
          {" "}
          Images :{" "}
        </Typography>
        {newCroud.oldImages.map((item, index) => (
          <div>
            <Grid container direction="row">
              <Grid item xs={2}>
                <img
                  src={item}
                  alt="image"
                  style={{ height: 100, width: 200 }}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => removeItem(index)}
                >
                  <DeleteIcon style={{ color: "#FF0000" }} />
                </IconButton>
              </Grid>
            </Grid>
            <br />
          </div>
        ))}
      </div>
    );
  };

  const NewImagesListField = () => {
    return (
      <div style={{ display: showNewImage ? "block" : "none" }}>
        {newCroud.images.map((item, index) => (
          <div>
            <Grid container direction="row">
              <Grid item xs={2}>
                <img
                  src={URL.createObjectURL(item)}
                  alt="image"
                  style={{ height: 100, width: 200 }}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => removeNewItem(index)}
                >
                  <DeleteIcon style={{ color: "#FF0000" }} />
                </IconButton>
              </Grid>
            </Grid>
            <br />
          </div>
        ))}
      </div>
    );
  };

  const CroundFundedTitle = ({ record }) => {
    return <span>Cround Funded {record ? `"${record.name}"` : ""}</span>;
  };

  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };

  return (
    <Edit title={<CroundFundedTitle />} {...props}>
      <SimpleForm
        validate={validateCreation}
        toolbar={<CustomToolbar />}
        save={handleSave}
      >
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              source="name"
              name="name"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <ReferenceInput
              label="Owner"
              source="owner"
              reference="users"
              validate={[required()]}
              name="owner"
              onChange={handleChange}
              disabled
              className={classes.formInput}
            >
              <SelectInput optionText="email" />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <ReferenceInput
              label="Categories"
              source="categories[0]._id"
              reference="categories"
              validate={[required()]}
              //name="categories"
              onChange={handleCategoryChange}
              className={classes.formInput}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <ReferenceInput
              label="Team"
              source="team_id"
              allowEmpty
              reference="Teams"
              onChange={handleChange}
              className={classes.formInput}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <ReferenceInput
              label="Team Group"
              source="teamGroup_id"
              reference="TeamsGroups"
              allowEmpty
              onChange={handleChange}
              className={classes.formInput}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              multiline
              source="subtitle1"
              name="subtitle1"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              multiline
              source="subtitle2"
              name="subtitle2"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <NumberInput
              source="distanceToShowDonation"
              label="Distance to show donation"
              name="distanceToShowDonation"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <DateInput
              source="startingDate"
              name="startingDate"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <NumberInput
              source="amount_to_rise_goal"
              label="Amount to rise goal"
              name="amount_to_rise_goal"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            {/*<NumberInput 
                        source="remainingTime" 
                        label="Remaining Time" 
                        name="remainingTime"
                        onChange={handleChange}
                    className={classes.formInput} />*/}
            <DateTimeInput
              source="remainingTime"
              name="remainingTime"
              label="Remaining Time"
              validate={[required()]}
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <SelectInput
              source="currency"
              name="currency"
              onChange={handleChange}
              className={classes.formInput}
              choices={[
                { id: "Euro", name: "Euro" },
                { id: "Spows", name: "Spows" },
              ]}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <SelectInput
              source="status"
              name="status"
              onChange={handleChange}
              className={classes.formInput}
              choices={[
                { id: "DONE", name: "DONE" },
                { id: "ONGOING", name: "ONGOING" },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <NumberInput
              source="donationInProgress"
              label="Donation In Progress"
              name="donationInProgress"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <NumberInput
              source="donationCeiling"
              label="Donation Ceiling"
              name="donationCeiling"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
        </Grid>
        <TextInput
          multiline
          source="projectDescription"
          name="projectDescription"
          onChange={handleChange}
          style={{ width: "100%" }}
        />
        <TextInput
          multiline
          source="biography"
          name="biography"
          onChange={handleChange}
          style={{ width: "100%" }}
        />

        {uploadedVideoImage ? (
          <img
            src={uploadedVideoImage}
            alt=""
            style={{ width: 170, height: 150 }}
          />
        ) : (
          <ImageField source="videoCover" title="title" />
        )}
        <div
          onClick={() => handleCoverVideo()}
          style={{ backgroundColor: "#DCE1DD", width: "50%", height: 50 }}
        >
          <br />
          <span style={{ marginLeft: "10%" }}>
            Click to select a picture to upload
          </span>
        </div>
        <input
          type="file"
          id="imageVideoInput"
          onChange={handleImageVideoChange}
          hidden="hidden"
          accept="image/*"
        />

        {uploadedVideo ? (
          <div
            style={{
              backgroundColor: "#fafafa",
              width: "50%",
              height: 50,
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            <br />
            <span style={{ marginLeft: "10%", color: "#0000ee" }}>
              {nameVideo}
            </span>
          </div>
        ) : (
          <FileField source="video" title="Video" />
        )}

        <div
          onClick={() => handleVideo()}
          style={{ backgroundColor: "#DCE1DD", width: "50%", height: 50 }}
        >
          <br />
          <span style={{ marginLeft: "10%" }}>
            Click to select a video to upload
          </span>
        </div>
        <input
          type="file"
          id="videoInput"
          onChange={handleVideoChange}
          hidden="hidden"
          accept="video/mp4"
        />

        <ImagesListField record={data} />

        <NewImagesListField />
        <Grid container direction="row" onClick={() => handleAddPicture()}>
          <Grid item xs={1}>
            <IconButton edge="end" color="inherit">
              <AddCircleOutlineIcon />
            </IconButton>
            <input
              type="file"
              id="newImageInput"
              onChange={handleNewImageChange}
              hidden="hidden"
              accept="image/*"
            />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>

        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Edit>
  );
}

export default withStyles(styles)(CroundFundedsEdit);
