import React from "react";
import { 
    Create, 
    TextInput, 
    SimpleForm, 
    ReferenceInput, 
    SelectInput,
    required,
    NumberInput,
    DateInput,
    BooleanInput,
    DateTimeInput,
    ImageField, 
    ImageInput,
    ArrayInput,
    SimpleFormIterator,
    useNotify, useRefresh, useRedirect
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
 
const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function OffersCreate(props) {
  const { classes } = props;

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`Offer created`)
    redirect('/offers');
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
        <SimpleForm>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} ></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <BooleanInput label="Active Offer" source="active" />
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <TextInput source="name" className={classes.formInput} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <ReferenceInput label="Partner / Owner" source="owner" reference="partners" 
                        validate={[required()]} className={classes.formInput}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <TextInput source="city" className={classes.formInput} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <TextInput source="country" className={classes.formInput}/>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <TextInput source="discount" className={classes.formInput}/>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <ReferenceInput label="Discount Code" source="discountCode" reference="discountCodes" 
                        validate={[required()]} 
                        className={classes.formInput}
                    >
                        <SelectInput optionText="id" />
                    </ReferenceInput>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <NumberInput source="distanceToShowOffer" label="Distance to show offer" className={classes.formInput} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    {/*<TextInput source="duration" className={classes.formInput}/>*/}
                    <DateTimeInput source="duration" label="Duration" className={classes.formInput} />
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <TextInput source="subtitle1" className={classes.formInput}/>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <TextInput source="subtitle2" className={classes.formInput}/>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <NumberInput source="price" label="Price" className={classes.formInput} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <DateInput source="startDate" className={classes.formInput}/>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <SelectInput 
                        source="type" 
                        className={classes.formInput}
                        choices={[
                            { id: 'PRODUCT_DISCOUNT', name: 'PRODUCT_DISCOUNT' },
                            { id: 'WEBSITE_DISCOUNT', name: 'WEBSITE_DISCOUNT' },
                            { id: 'SPECIAL_OFFER', name: 'SPECIAL_OFFER' },
                        ]} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <ReferenceInput label="Categories" 
                        source="categories" 
                        reference="categories" 
                        validate={[required()]} 
                        className={classes.formInput}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <SelectInput 
                        source="currency"
                        name={"currency"}
                        className={classes.formInput}
                        choices={[
                            { id: 'Euro', name: 'Euro' },
                            { id: 'Spows', name: 'Spows' },
                        ]} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    
                </Grid>
            </Grid>
            <TextInput multiline source="UrlLinkOffer" style={{width: "100%"}}/>
            <TextInput multiline source="description" style={{width: "100%"}}/>
            <TextInput multiline source="offerDetail" className={classes.formInput}/>
            <TextInput multiline source="offerInstruction" className={classes.formInput}/>
            <ArrayInput source="images">
                <SimpleFormIterator>
                    <ImageInput source="image" label="Image" accept="image/*">
                        <ImageField source="image" title="title" />
                    </ImageInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(OffersCreate);
