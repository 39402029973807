import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

const GainsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const GainsList = (props) => {
  const classes = useStyles();

  return (
    <List
      //filters={<GainsFilter />}
      {...props}
      title="List des Gains"
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="subtitle" />
        <TextField source="description" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
