import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
  SimpleShowLayout,
  DeleteButton,
  ImageField,
  ArrayField,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

const TeamsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const TeamsList = (props) => {
  const classes = useStyles();

  return (
    <List
      //filters={<TeamsFilter />}
      {...props}
      title="List des etablisements"
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="managerId" />
        {/*<TextField source="code" />*/}
        <EditButton />
        <ShowButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const TeamsTitle = ({ record }) => {
  return <span>Teams {record ? `"${record.managerId}"` : ""}</span>;
};

export const TeamsShow = (props) => (
  <Show title={<TeamsTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" disabled />
      <TextField source="description" />
      <TextField source="name" />
      <TextField source="managerId" />
      <TextField source="code" />
      <ImageField source="image" />
      <ArrayField source="teamGroups">
        <Datagrid>
          <TextField source="_id" />
          <TextField source="name" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
