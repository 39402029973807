import React from "react";
import { RadialBarChart, RadialBar, Legend } from "recharts";

const data = [
  {
    name: "18-24",
    val: 31.47,
    fill: "#8884d8",
  },
  {
    name: "25-29",
    val: 26.69,
    fill: "#83a6ed",
  },
  {
    name: "30-34",
    val: 15.69,
    fill: "#8dd1e1",
  },
  {
    name: "35-39",
    val: 8.22,
    fill: "#82ca9d",
  },
  {
    name: "40-49",
    val: 8.63,
    fill: "#a4de6c",
  },
  {
    name: "50+",
    val: 2.63,
    fill: "#d0ed57",
  },
];

const style = {
  top: 0,
  left: 350,
  lineHeight: "24px",
};

function Donuts() {
  return (
    <div>
      <h1 style={{ color: "#4ad991" }}>Donuts1</h1>
      <h4>Regroupement par tranche d'âge</h4>
      <RadialBarChart
        width={490}
        height={280}
        cx={150}
        cy={150}
        innerRadius={20}
        outerRadius={140}
        barSize={20}
        data={data}
      >
        <RadialBar
          minAngle={15}
          label={{ position: "insideStart", fill: "#fff" }}
          background
          clockWise
          dataKey="val"
        />
        <Legend
          iconSize={10}
          width={120}
          height={140}
          layout="vertical"
          verticalAlign="middle"
          wrapperStyle={style}
        />
      </RadialBarChart>
    </div>
  );
}

export default Donuts;
