import React, {useState, useContext} from "react";
import { UserContext } from "../../context/UserContext";
import { Create, TextInput, SimpleForm, SelectInput } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@material-ui/core/IconButton';
import { Grid } from "@material-ui/core";
import { us, useHistory } from "react-router";

const styles = (theme) => ({
  paper: {
    backgroundColor: "#fff",
    width: 900,
    heigth: 500,
  },
  formInput: {
    width: "300px",
  },
});

function DiscountCodesCreate(props) {
  const { classes } = props;
  const history = useHistory()
  const {createCodes} = useContext(UserContext);
  const [inputText, setInputText] = useState("");
  const [typeInput, setTypeInput] = useState("ONE_FOR_ALL");

  const [newCode, setNewCode] = useState({
    type: "",
    code: [],
  });

  const handleChange = (event) => {
    const value = event.target.value;
    setNewCode({ ...newCode, type: value });
    setTypeInput(value);
  };

  const handleChangeCode = (event) => {
    const value = event.target.value;
    setNewCode({ ...newCode, code: value });
  };

  const handleChangeCodes = (event) => {
    const value = event.target.value;
    setInputText(value);
  };

  function addCode () {
    setNewCode((prevNewCode) => {
    
      let item= {...prevNewCode};
      let codeList = item.code;
      let itemCodes = [...codeList, inputText];
      
      const updatedRestaurant = {...prevNewCode, code: itemCodes }
      return updatedRestaurant ;
    });
    setInputText("");
  }

  function removeCodesItem(index) {
      setNewCode((prevNewCode) => {
        let item= {...prevNewCode};
        let items = [...item.code];
        items.splice(index, 1);

        const updatedCode = {...prevNewCode, code: items }
        return updatedCode;
    });
  }

function handleSave() {
  createCodes(newCode);
  history.push('/discountCodes');
}

  return (
    <Create {...props}>
        <SimpleForm save={handleSave} >
          <SelectInput 
            source="type" 
            choices={[
              { id: 'ONE_FOR_ALL', name: 'ONE_FOR_ALL' },
              { id: 'ONE_BY_USER', name: 'ONE_BY_USER' },
            ]} 
            name="type"
            onChange={handleChange}
            className={classes.formInput} />

          {typeInput === "ONE_FOR_ALL" ? (
            <TextInput 
              //source="code" 
              name="code" 
              value={newCode.code}
              onChange={handleChangeCode} 
              className={classes.formInput} />
          ):(
            <div>
              <TextInput 
                //source="code" 
                name="code" 
                value={inputText}
                onChange={handleChangeCodes}
                className={classes.formInput} />
                
              <IconButton aria-label="add" style={{width: 50}} onClick={addCode}>
                <AddCircleIcon fontSize="medium" size="small"/>
              </IconButton>
              {newCode.code && newCode.code.map((el, index) => (
                <div
                  style={{
                    padding: 5,
                    marginBottom: 5,
                    border: "1px solid #7a7a7a",
                    borderRadius: "5px",
                    fontSize: "1.2rem",
                    color: "#000",
                    width: "100%",
                  }}
                  key={el}
                >
                    <Grid container direction="row">
                        <Grid item xs={10}>
                            {el}
                        </Grid>
                        <Grid item xs={1}>
                            <CloseIcon
                                style={{ color: "#ff6900" }}
                                fontSize="small"
                                onClick={() => removeCodesItem(index)} />
                        </Grid>
                    </Grid>
                </div>
              ))}
            </div>
          )}          
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(DiscountCodesCreate);
