import React, {useState, useContext} from "react";
import { UserContext } from "../../context/UserContext";
import { 
    Edit, 
    TextInput, 
    SimpleForm, 
    ReferenceInput, 
    SelectInput,
    required,
    NumberInput,
    DateInput,
    ImageField, 
    BooleanInput,
    DateTimeInput,
    SaveButton,
    Toolbar,
    useGetOne
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import { useHistory } from "react-router";

const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
  listOfIngredients: {
    overflowX: "scroll",
    overflowY: "hidden",
    display: "flex",
    width: 900,
    marginLeft: "30%"
  }
});

function OffersEdit(props) {
  const { classes } = props;
  const history = useHistory();
  const {updateOffer, reloadOffers, setReloadOffers, updateActiveOffre} = useContext(UserContext);
  const [showNewImage, setShowNewImage] = useState(false);
  const [loader, setLoader] = useState(false);

  const getUrlFromPath = () => {
    const paths = window.location.href.split("/");
    let url = paths[5];
    
    return url;
  };

  const { data, loaded } = useGetOne('offers', getUrlFromPath());
console.log("offers:", data)
  const [newOffer, setNewOffer] = useState({
    name: data.name,
    owner: data.owner,
    country: data.country,
    city: data.city,
    discount: data.discount,
    discountCode: data.discountCode,
    //discountCodeCode: data.discountCode.code,
    distanceToShowOffer: data.distanceToShowOffer,
    duration: data.duration,
    subtitle1: data.subtitle1,
    subtitle2: data.subtitle2,
    price: data.price,
    startDate: data.startDate,
    type: data.type,
    categories: data.categories,
    UrlLinkOffer: data.UrlLinkOffer,
    description: data.description,
    offerDetail: data.offerDetail,
    offerInstruction: data.offerInstruction,
    images: data.images,
    oldImages: data.images,
    images: [],
    active: data.active,
  });

  if (!loaded) return <span>Loading</span>;

    const handleChange = (event) => {
        const value = event.target.value;
        const field = event.target.name;
        setNewOffer({ ...newOffer, [field]: value });
    };

    const handleChangeOwner = (event) => {
        const value = event.target.value;
        setNewOffer({ ...newOffer, owner: value });
    };

    const handleCategoryChange = (event) => {
        const value = event.target.value;
        setNewOffer({ ...newOffer, categories: value });
    };

    const handleActiveChange = (event) => {
        setNewOffer({ ...newOffer, active: event.target.checked });
    };

    function removeItem(index) {
        setNewOffer((prevNewOffer) => {
            let items = prevNewOffer;
            let listImages = [...items.oldImages];
            listImages.splice(index, 1);
            const updatedOffer = {...prevNewOffer, oldImages: listImages};
            return updatedOffer;
        });
    };

    const handleAddPicture = () => {
        const fileInput = document.getElementById("newImageInput");
        fileInput.click();
    };

    const handleNewImageChange = (event) => {
        const img = event.target.files[0];
        if (img) {
            setNewOffer((prevNewOffer) => {
                let items = { ...prevNewOffer };
                let listNewImages = items.images;
                const updatedNewImages = [...listNewImages, img];
                items = {...prevNewOffer, images: updatedNewImages};
                return items;
            });
            setShowNewImage(true);
        }
    };

    function removeNewItem(index) {
        setNewOffer((prevNewOffer) => {
          let items = prevNewOffer;
          let listNewImages = [...items.images];
          listNewImages.splice(index, 1);
          const updatedOffer = {...prevNewOffer, images: listNewImages};
          return updatedOffer;
        });
    };

    function handleSave() {

        updateOffer(getUrlFromPath(), newOffer);
        
        updateActiveOffre(getUrlFromPath(), newOffer.active);

        setLoader(!loader);
            setTimeout(() => {
                history.push('/offers');
            }, 3000);

        console.log("newOffer:", newOffer);
    };

    /*if(reloadOffers === true){
        setTimeout(() => {
            history.push('/offers');
            window.location.reload(true); 
        }, 3000);
    }*/

    const ImagesListField = ({ record }) => {
        return <div>
          <Typography variant="h6" color="grey"> Images : </Typography>
          {newOffer.oldImages && newOffer.oldImages.map((item, index) => (
              <div>
                <Grid container direction="row">
                    <Grid item xs={2}>
                        <img src={item} alt="image" style={{ height: 100, width: 200}}/>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={1}>
                        <IconButton edge="end" color="inherit" onClick={() => removeItem(index)} >
                            <DeleteIcon style={{ color: "#FF0000" }} />
                        </IconButton>
                    </Grid>
                </Grid>
                <br/>
            </div>
          ))}
        </div>
    };

    const NewImagesListField = () => {
        return <div style={{ display: showNewImage ? "block" : "none" }}>
            {newOffer.images.map((item, index) => (
                <div>
                    <Grid container direction="row" >
                        <Grid item xs={2}>
                            <img src={URL.createObjectURL(item)} alt="image" style={{ height: 100, width: 200}}/>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1}>
                            <IconButton edge="end" color="inherit" onClick={() => removeNewItem(index)} >
                                <DeleteIcon style={{ color: "#FF0000" }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <br/>
                </div>
            ))}
        </div>
    };

    const OfferTitle = ({ record }) => {
        return <span>Offer {record ? `"${record.name}"` : ''}</span>;
    };

    const CustomToolbar = (props) => {
        return (
          <Toolbar {...props}>
            <SaveButton submitOnEnter={true} />
          </Toolbar>
        );
    }

  return (
    <Edit title={<OfferTitle />} submitOnEnter={true} {...props}>
        <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} ></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}><span>Active Offer</span></Grid>
                <Grid item xs={4} >
                    <Switch
                        checked={newOffer.active}
                        onChange={handleActiveChange}
                        color="primary"
                        name="active"
                        label="Active Offer"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>     
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <TextInput 
                        source="name" 
                        name="name"
                        onChange={handleChange}
                        className={classes.formInput} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <ReferenceInput label="Partner / Owner" source="owner._id" reference="partners" 
                        validate={[required()]}
                        //disabled
                        onChange={handleChangeOwner}
                        className={classes.formInput}>
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <TextInput 
                        source="city" 
                        name="city"
                        onChange={handleChange}
                        className={classes.formInput} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <TextInput 
                        source="country" 
                        name="country"
                        onChange={handleChange}
                        className={classes.formInput}/>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <TextInput 
                        source="discount" 
                        name="discount"
                        onChange={handleChange}
                        className={classes.formInput}/>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <ReferenceInput label="Discount Code" source="discountCodeId._id" reference="discountCodes"
                        disabled 
                        //validate={[required()]} 
                        className={classes.formInput}
                    >
                        <SelectInput optionText="id" />
                    </ReferenceInput>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <NumberInput 
                        source="distanceToShowOffer" 
                        label="Distance to show offer" 
                        name="distanceToShowOffer"
                        onChange={handleChange}
                        className={classes.formInput} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    {/*<TextInput 
                        source="duration" 
                        name="duration"
                        onChange={handleChange}
                        className={classes.formInput}/>*/}
                    <DateTimeInput source="duration" label="Duration" onChange={handleChange} className={classes.formInput} />
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <TextInput 
                        source="subtitle1" 
                        name="subtitle1"
                        onChange={handleChange}
                        className={classes.formInput}/>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <TextInput 
                        source="subtitle2" 
                        name="subtitle2"
                        onChange={handleChange}
                        className={classes.formInput}/>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <NumberInput 
                        source="price" 
                        label="Price" 
                        name="price"
                        onChange={handleChange}
                        className={classes.formInput} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <DateInput 
                        source="startDate" 
                        name="startDate"
                        onChange={handleChange}
                        className={classes.formInput}/>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <SelectInput 
                        source="type" 
                        name="type"
                        onChange={handleChange}
                        className={classes.formInput}
                        choices={[
                            { id: 'PRODUCT_DISCOUNT', name: 'PRODUCT_DISCOUNT' },
                            { id: 'WEBSITE_DISCOUNT', name: 'WEBSITE_DISCOUNT' },
                            { id: 'SPECIAL_OFFER', name: 'SPECIAL_OFFER' },
                        ]} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    <ReferenceInput label="Categories" source="categories[0]._id" reference="categories" 
                        validate={[required()]} 
                        //name="categories"
                        onChange={handleCategoryChange}
                        className={classes.formInput}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{width: "100%"}} >
                <Grid item xs={5} >
                    <SelectInput 
                        source="currency"
                        name={"currency"}
                        className={classes.formInput}
                        choices={[
                            { id: 'Euro', name: 'Euro' },
                            { id: 'Spows', name: 'Spows' },
                        ]} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} >
                    
                </Grid>
            </Grid>
            <TextInput 
                source="UrlLinkOffer" 
                name="UrlLinkOffer"
                onChange={handleChange}
                style={{width: "100%"}}/>
            <TextInput 
                multiline 
                source="description" 
                name="description"
                onChange={handleChange}
                style={{width: "100%"}}/>
            <TextInput 
                multiline 
                source="offerDetail" 
                name="offerDetail"
                onChange={handleChange}
                className={classes.formInput}/>
            <TextInput 
                multiline 
                source="offerInstruction" 
                name="offerInstruction"
                onChange={handleChange}
                className={classes.formInput}/>

            <ImagesListField record={data} source="images" />
            <NewImagesListField />

            <Grid container direction="row">
                <Grid item xs={1}>
                    <IconButton edge="end" color="inherit" onClick={() => handleAddPicture()}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                    <input
                        type="file"
                        id="newImageInput"
                        onChange={handleNewImageChange}
                        hidden="hidden"
                        accept="image/*"
                    />
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
            
            <LinearProgress style={{ display: loader ? "block" : "none", width: "100%" }}/>
        </SimpleForm>
    </Edit>
  );
}

export default withStyles(styles)(OffersEdit);
