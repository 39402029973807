import React, { useState, useEffect, useContext } from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  RadioButtonGroupInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  Toolbar,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  useNotify,
  LinearProgress,
  useRefresh,
  useRedirect,
  SelectArrayInput,
  NumberInput,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { UserContext } from "../../context/UserContext";
import Switch from "@material-ui/core/Switch";
const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function BlogSectionCreate(props) {
  const history = useHistory();
  const { classes } = props;
  const [loader, setLoader] = React.useState(false);
  const [Section, setSection] = React.useState({
    displayIndex: "",
    name: "",
    type: "CATEGORY",
    categories: [],
    articles: [],
    attributionType: "PUBLIC",
    teams: [],
    teamgroups: [],
  });
  const { createBlogSection } = useContext(UserContext);
  const handleChange = (event) => {
    const key = event.target.name;
    const value =
      key === "displayIndex"
        ? parseInt(event.target.value)
        : event.target.value;
    setSection({ ...Section, [key]: value });
  };
  const handleChangeType = (item) => {
    setSection({ ...Section, type: item });
  };

  const handleChangeAttributionType = (item) => {
    setSection({ ...Section, attributionType: item });
  };

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`BlogSection created`);
    redirect("/BlogSections");
    refresh();
  };
  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };

  async function handleSave() {
    console.log("update:", Section);
    await createBlogSection(Section);
    history.push("/BlogSections");
    setLoader(!loader);
    setTimeout(() => {}, 3000);
  }
  const { type, attributionType } = Section;
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <NumberInput
              name="displayIndex"
              label="displayIndex"
              className={classes.formInput}
              onChange={handleChange}
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              name="name"
              label="name"
              className={classes.formInput}
              onChange={handleChange}
              validate={[required()]}
            />
          </Grid>
          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item xs={5}>
              <RadioButtonGroupInput
                source="type"
                choices={[
                  { id: "CATEGORY", name: "CATEGORY" },
                  { id: "ARTICLE", name: "ARTICLE" },
                ]}
                onChange={handleChangeType}
                defaultValue="CATEGORY"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item xs={5}>
              {type === "CATEGORY" ? (
                <ReferenceArrayInput
                  label="categories"
                  source="categories"
                  reference="BlogCategories"
                  validate={[required()]}
                  className={classes.formInput}
                  onChange={handleChange}
                >
                  <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
              ) : (
                <ReferenceArrayInput
                  label="articles"
                  source="articles"
                  reference="BlogArticles"
                  validate={[required()]}
                  className={classes.formInput}
                  onChange={handleChange}
                >
                  <SelectArrayInput optionText="title" />
                </ReferenceArrayInput>
              )}
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item xs={5}>
              <RadioButtonGroupInput
                source="attributionType"
                choices={[
                  { id: "PUBLIC", name: "PUBLIC" },
                  { id: "PRIVATE", name: "PRIVATE" },
                ]}
                onChange={handleChangeAttributionType}
                defaultValue="PUBLIC"
              />
            </Grid>
          </Grid>
          {attributionType === "PRIVATE" && (
            <Grid container direction="row" style={{ width: "100%" }}>
              <Grid item xs={5}>
                <ReferenceArrayInput
                  label="teams"
                  source="teams"
                  reference="Teams"
                  className={classes.formInput}
                  onChange={handleChange}
                >
                  <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <ReferenceArrayInput
                  label="teamgroups"
                  source="teamgroups"
                  reference="TeamsGroups"
                  className={classes.formInput}
                  onChange={handleChange}
                >
                  <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
              </Grid>
            </Grid>
          )}
        </Grid>

        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(BlogSectionCreate);
