import * as React from "react";
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Chart from './chart/Chart';
import LineChartExemple from './chart/LineChartExemple';
import DashedLineChart from './chart/DashedLineChart';
import BarChartExemple from './chart/BarChartExemple';
import LegendEffectOpacity from './chart/LegendEffectOpacity';
import Donuts from './chart/Donuts';
import PieChartWithCustomizedLabel from './chart/PieChartWithCustomizedLabel';
import StraightAnglePieChart from './chart/StraightAnglePieChart';
import CustomActiveShapePieChart from './chart/CustomActiveShapePieChart';
import ChartExemple from './chart/ChartExemple';
import SameDataComposedChart from './chart/SameDataComposedChart';

const styles = (theme) => ({
    depositContext: {
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 280,
    },
});

function Dashboard(props) {
    const {classes} = props;
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    function preventDefault(event) {
        event.preventDefault();
    };

  return (
    <Card>
      <CardHeader
        title="Spoward Admin"
        style={{ color: "#ff6900", fontSize: "1.6rem" }}
      />

      <CardContent>
        <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
            <Paper className={fixedHeightPaper}>
                <Chart />
            </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Recent Deposits
                </Typography>
                <Typography component="p" variant="h4">
                    $3,024.00
                </Typography>
                <Typography color="textSecondary" className={classes.depositContext}>
                    on 15 March, 2019
                </Typography>
                <div>
                    <Link color="primary" href="#" onClick={preventDefault}>
                        View balance
                    </Link>
                </div>
            </Paper>
        </Grid>
    </Grid>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Paper className={classes.paper}>
                    <LineChartExemple/>
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper className={classes.paper}>
                    <DashedLineChart />
                </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Paper className={classes.paper}>
                    <BarChartExemple />
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper className={classes.paper}>
                    <LegendEffectOpacity />
                </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Paper className={classes.paper}>
                    <Donuts />
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper className={classes.paper}>
                    <PieChartWithCustomizedLabel />
                </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Paper className={fixedHeightPaper}>
                    <StraightAnglePieChart />
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper className={fixedHeightPaper}>
                    <CustomActiveShapePieChart />
                </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Paper className={fixedHeightPaper}>
                    <ChartExemple /> 
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper className={classes.paper}>
                    <SameDataComposedChart />
                </Paper>
            </Grid>
        </Grid>
        <hr style={{ marginTop: 100 }} />
        <div style={{ marginLeft: "40%" }}>
            <h5>copyright © FreinGO : Spoward 2021</h5>
        </div>
        </CardContent>
    </Card>
  );
}

export default withStyles(styles)(Dashboard);
