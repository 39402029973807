import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
  SimpleShowLayout,
  DeleteButton,
  ImageField,
  ArrayField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

export const BlogCategoriesList = (props) => {
  const classes = useStyles();

  return (
    <List {...props} title="List des categories">
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <EditButton />
        <ShowButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const BlogCategoriesTitle = ({ record }) => {
  return <span>BlogCategories {record ? `"${record.name}"` : ""}</span>;
};

export const BlogCategoriesShow = (props) => (
  <Show title={<BlogCategoriesTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" disabled />
      <TextField source="name" />
      <TextField source="description" />
      <ImageField source="image" />
      <ArrayField source="articles">
        <Datagrid>
          <TextField source="_id" />
          <TextField source="smallTitle" />
          <TextField source="title" />
          <TextField source="smallDescription" />
          <TextField source="description" />
          <ArrayField source="content">
            <Datagrid>
              <TextField source="title" />
              <TextField source="type" />
              <TextField source="data" />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
