import React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  ArrayInput,
  SimpleFormIterator,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function GainsCreate(props) {
  const { classes } = props;

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`Gain created`);
    redirect("/gains");
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput source="name" className={classes.formInput} />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <ReferenceInput
              label="Categories"
              source="categories"
              reference="categories"
              validate={[required()]}
              className={classes.formInput}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              multiline
              source="subtitle"
              className={classes.formInput}
            />
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              multiline
              source="details"
              className={classes.formInput}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <NumberInput
              source="price.spows"
              label="Value in spows"
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <NumberInput
              source="price.parrains"
              label="Value in parrains"
              className={classes.formInput}
            />
          </Grid>
        </Grid>
        <TextInput multiline source="description" style={{ width: "100%" }} />

        <ArrayInput source="images">
          <SimpleFormIterator>
            <ImageInput source="image" label="Image" accept="image/*">
              <ImageField source="image" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(GainsCreate);
