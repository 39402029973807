import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const data = [
  {
    name: "Janvier",
    nbr: 400,
  },
  {
    name: "Février",
    nbr: 300,
  },
  {
    name: "Mars",
    nbr: 200,
  },
  {
    name: "Avril",
    nbr: 278,
  },
  {
    name: "Mai",
    nbr: 189,
  },
  {
    name: "Juin",
    nbr: 230,
  },
  {
    name: "Juillet",
    nbr: 390,
  },
  {
    name: "Aout",
    nbr: 190,
  },
  {
    name: "Septembre",
    nbr: 239,
  },
  {
    name: "Octobre",
    nbr: 490,
  },
  {
    name: "Novembre",
    nbr: 190,
  },
  {
    name: "Décembre",
    nbr: 290,
  },
];

function LineChartExemple() {
  return (
    <div>
      <h1 style={{ color: "#ffa500" }}>Line Chart</h1>
      <h4>Suivi mensuel pour 2021</h4>
      <LineChart
        width={400}
        height={300}
        data={data}
        syncId="anyId"
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="nbr" stroke="#8884d8" fill="#8884d8" />
      </LineChart>
    </div>
  );
}

export default LineChartExemple;
