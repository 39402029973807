import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  Toolbar,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  useGetOne,
  useNotify,
  LinearProgress,
  TextField,
  useRefresh,
  useRedirect,
} from "react-admin";
import { QRCode } from "react-qr-svg";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router";
import {
  geocodeByAddress,
  getLatLng,
  geocodeByLatLng,
} from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { UserContext } from "../../context/UserContext";
const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function EstablishmentsEdit(props) {
  const history = useHistory();
  const qrCont = useRef();
  const getUrlFromPath = () => {
    const paths = window.location.href.split("/");
    let url = paths[5];

    return url;
  };
  const { data, loaded } = useGetOne("establishments", getUrlFromPath());
  const [qrCode, setQrCode] = useState("");
  const [address, setAddress] = useState("");

  const { classes } = props;
  useEffect(() => {
    if (data) {
      setEstablishment({
        type: data.type,
        image: data.image,
        name: data.name,
        address: { lat: data.address.lat, lng: data.address.lng },
        logo: data.logo,
        caloriesPerMinute: data.caloriesPerMinute,
        activityType: data.activityType,
        value: data.value,
        useGps: data.useGps ? data.useGps : false,
      });
      setQrCode(getUrlFromPath());
      setTimeout(() => {
        geocodeByLatLng(data.address).then((res) => {
          console.log(res[0].formatted_address);
          setAddress(res[0].formatted_address);
        });
      }, 500);
      console.log("qqqqqqqqqqqqqqqq:", {
        type: data.type,
        image: data.image,
        name: data.name,
        address: data.address,
        logo: data.logo,
        caloriesPerMinute: data.caloriesPerMinute,
        activityType: data.activityType,
        value: data.value,
      });
    }
  }, [data]);

  const [loader, setLoader] = React.useState(false);
  const [establishment, setEstablishment] = React.useState({
    type: "",
    image: "",
    name: "",
    address: "",
    logo: "",
    caloriesPerMinute: 0,
    activityType: "",
    value: 0,
    useGps: false,
  });
  const { editEstablishment } = useContext(UserContext);
  const handleChange = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    setEstablishment({ ...establishment, [key]: value });
  };

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onPlaceChange = (e) => {
    geocodeByAddress(e)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) =>
        setEstablishment({ ...establishment, address: { lat, lng } })
      );
  };
  const handleChangeImage = (e) => {
    const value = e;
    const key = "image";
    setEstablishment({ ...establishment, [key]: value });
  };
  const handleChangeLogo = (e) => {
    const value = e;
    const key = "logo";
    setEstablishment({ ...establishment, [key]: value });
  };
  const onSuccess = () => {
    notify(`establishment Edited`);
    redirect("/establishments");
    refresh();
  };
  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };
  const handleGpsChange = (event) => {
    setEstablishment({ ...establishment, useGps: event.target.checked });
  };

  async function handleSave() {
    console.log("update:", establishment);
    await editEstablishment(getUrlFromPath(), establishment);
    history.push("/establishments");
    setLoader(!loader);
    setTimeout(() => {}, 3000);
  }
  if (!data) {
    return <LinearProgress style={{ width: "100%" }} />;
  }
  return (
    <Edit onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <span>activate GPS</span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={
                establishment && establishment.useGps
                  ? establishment.useGps
                  : false
              }
              onChange={handleGpsChange}
              color="primary"
              name="useGps"
              label="activate GPS"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="name"
              label="name"
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <SelectInput
              choices={[
                { id: "SHOP", name: "Magasin" },
                { id: "GYM", name: "GYM/Sale de sport" },
                { id: "CLUB", name: "Club" },
              ]}
              name="type"
              label="Type d'etablisement"
              style={{ width: "100%" }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          {establishment.type === "GYM" || establishment.type === "CLUB" ? (
            <>
              <Grid item xs={5}>
                <TextInput
                  label="activityType"
                  name="activityType"
                  className={classes.formInput}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <NumberInput
                  label="caloriesPerMinute"
                  name="caloriesPerMinute"
                  className={classes.formInput}
                  onChange={handleChange}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={5}>
                <NumberInput
                  label="value"
                  name="value"
                  className={classes.formInput}
                  onChange={handleChange}
                />
              </Grid>
            </>
          )}
        </Grid>
        <span style={{ marginBottom: 20, display: "block", width: "100%" }}>
          old Address : {address}
        </span>
        <GooglePlacesAutocomplete
          apiKey="AIzaSyAk0IGxCNSynONzCyc5YVHdNHJfAShwNkg"
          selectProps={{
            name: "address",

            isClearable: true,
            onChange: (o) => {
              onPlaceChange(o.label);
            },
          }}
        />

        <ImageField source="image" label="old image" title="title" />
        <ImageInput
          name="image"
          label="image"
          accept="image/*"
          onChange={handleChangeImage}
        >
          <ImageField name="image" title="title" />
        </ImageInput>

        <ImageField source="logo" label="old logo" title="title" />
        <ImageInput
          name="logo"
          label="logo"
          onChange={handleChangeLogo}
          accept="image/*"
        >
          <ImageField name="logo" title="title" />
        </ImageInput>
        {qrCode && qrCode.length ? (
          <div
            ref={qrCont}
            onClick={(e) => {
              console.log(qrCont.current.children[0].outerHTML);

              var svgData = qrCont.current.children[0].outerHTML;

              var head =
                '<svg title="' +
                establishment.name +
                '" version="1.1" xmlns="http://www.w3.org/2000/svg">';

              //if you have some additional styling like graph edges put them inside <style> tag

              var full_svg = head + svgData + "</svg>";
              var svgBlob = new Blob([full_svg], {
                type: "image/svg+xml;charset=utf-8",
              });
              var svgUrl = URL.createObjectURL(svgBlob);
              var downloadLink = document.createElement("a");
              downloadLink.href = svgUrl;
              downloadLink.download = establishment.name + "-qrCode.svg";
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            }}
          >
            <QRCode label="QR CODE" key={qrCode} value={qrCode} />
          </div>
        ) : (
          <></>
        )}
        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Edit>
  );
}

export default withStyles(styles)(EstablishmentsEdit);
