import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  EditButton,
  ShowButton,
  DeleteButton,
  TextInput,
  Filter,
  SimpleShowLayout,
  ImageField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

const JackpotFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const JackpotList = (props) => {
  const classes = useStyles();

  return (
    <List
      //filters={<TeamsFilter />}
      {...props}
      title="List of Jackpot"
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="link" />
        <TextField source="image" />
        {/*<TextField source="code" />*/}
        <EditButton />
        <ShowButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const JackpotShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" disabled />
      <TextField source="text" />
      <ImageField source="image" />
    </SimpleShowLayout>
  </Show>
);
