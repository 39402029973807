import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  Show,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
  SimpleForm,
  ImageField,
  ImageInput,
  SimpleShowLayout,
  Pagination,
  useNotify, useRefresh, useRedirect
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    formInput: {
        width: "300px",
    },
}));

const PartnersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const PartnersList = (props) => {
  const classes = useStyles();

  return (
    <List 
        //filters={<PartnersFilter />} 
        {...props} title="List des Partners">
      <Datagrid>
        <TextField source="name" />
        <TextField source="adresseLine" />
        <TextField source="zipCode" />
        <TextField source="country" />
        <TextField source="city" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const PartnerTitle = ({ record }) => {
    return <span>Partner {record ? `"${record.name}"` : ''}</span>;
};

export const PartnersEdit = (props) => {
    const classes = useStyles();
    
  return (
    <Edit title={<PartnerTitle />} {...props}>
        <SimpleForm>
            <Grid container direction="row">
                <Grid item xs={4}>
                    <ImageField label="" source="logo"/>
                    <ImageInput source="logo" label="Logo" accept="image/*">
                        <ImageField source="logo" title="title" />
                    </ImageInput>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                    <TextInput source="name" className={classes.formInput} />
                    <TextInput source="adresseLine" className={classes.formInput} />
                    <TextInput source="zipCode" className={classes.formInput} />
                    <TextInput source="country" className={classes.formInput} />
                    <TextInput source="city" className={classes.formInput} />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
  );
};

export const PartnersShow = (props) => {
    const classes = useStyles();
    
  return (
    <Show title={<PartnerTitle />} {...props}>
        <SimpleShowLayout>
            <Grid container direction="row">
                <Grid item xs={3}>
                    <ImageField label="" source="logo"/>
                </Grid>
                <Grid item xs={9}>
                    <Grid container direction="row" style={{marginBottom: 15}}>
                        <Grid item xs={2}>
                            <Typography variant="h8" color="grey">
                                Name
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField source="name" />
                        </Grid>
                    </Grid>

                    <Grid container direction="row" style={{marginBottom: 15}}>
                        <Grid item xs={2}>
                            <Typography variant="h8" color="grey">
                                Adresse Line
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField source="adresseLine" />
                        </Grid>
                    </Grid>
                    
                    <Grid container direction="row" style={{marginBottom: 15}}>
                        <Grid item xs={2}>
                            <Typography variant="h8" color="grey">
                                Zip Code
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField source="zipCode" />
                        </Grid>
                    </Grid>
                    
                    <Grid container direction="row" style={{marginBottom: 15}}>
                        <Grid item xs={2}>
                            <Typography variant="h8" color="grey">
                                Country
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField source="country" />
                        </Grid>
                    </Grid>

                    <Grid container direction="row" style={{marginBottom: 15}}>
                        <Grid item xs={2}>
                            <Typography variant="h8" color="grey">
                                City
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField source="city" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleShowLayout>
    </Show>
  );
};

export const PartnersCreate = (props) => {
    const classes = useStyles();
    
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Partner created`)
        redirect('/partners');
        refresh();
    };

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <Grid container direction="row">
                    <Grid item xs={4} >
                        <ImageInput source="logo" label="Logo" accept="image/*">
                            <ImageField source="logo" title="title" />
                        </ImageInput>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3}>
                        <TextInput source="name" className={classes.formInput} />
                        <TextInput source="adresseLine" className={classes.formInput} />
                        <TextInput source="zipCode" className={classes.formInput} />
                        <TextInput source="country" className={classes.formInput} />
                        <TextInput source="city" className={classes.formInput} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};