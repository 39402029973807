import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
  SimpleShowLayout,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

const ExerciceTypesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const ExerciceTypesList = (props) => {
  const classes = useStyles();

  return (
    <List
      //filters={<ExerciceTypesFilter />}
      {...props}
      title="List des ExerciceTypes"
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const ExerciceTypesTitle = ({ record }) => {
  return <span>ExerciceTypes {record ? `"${record.managerId}"` : ""}</span>;
};

const ExerciceTypesField = ({ record }) => {
  return (
    <div>
      <Typography variant="h6" color="grey">
        {" "}
        ExerciceTypes :{" "}
      </Typography>
      {record.code.map((item) => (
        <div>
          <Typography variant="h8">{item}</Typography>
          <br />
        </div>
      ))}
    </div>
  );
};

export const ExerciceTypesShow = (props) => (
  <Show title={<ExerciceTypesTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" disabled />
      <TextField source="name" />
      <ExerciceTypesField />
    </SimpleShowLayout>
  </Show>
);
