import React, { useState, useEffect, useContext } from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  ImageInput,
  ImageField,
  Toolbar,
  SaveButton,
  useNotify,
  LinearProgress,
  useRefresh,
  useRedirect,
  FileInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { UserContext } from "../../context/UserContext";
import Switch from "@material-ui/core/Switch";
const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function BlogArticleCreate(props) {
  const history = useHistory();
  const { classes } = props;
  const [loader, setLoader] = React.useState(false);
  const [BlogArticle, setBlogArticle] = React.useState({
    image: "",
    title: "",
    smallTitle: "",
    smallDescription: "",
    description: "",
    show: true,
    categories: [],
  });
  const [content, setContent] = React.useState([]);
  const { createBlogArticle } = useContext(UserContext);

  const handleChangeImage = (e) => {
    const value = e;
    const key = "image";

    setBlogArticle((previousState) => {
      return { ...previousState, [key]: value };
    });
  };
  const handleChange = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    setBlogArticle((previousState) => {
      return { ...previousState, [key]: value };
    });
  };

  const handleContentChange = (idx, evt, oth) => {
    const key = evt.target.name;
    const value = evt.target.value;
    const newContent = content.map((content, sidx) => {
      if (idx !== sidx) return content;
      return {
        ...content,
        [key]:
          content.type === "VIMEO" && key === "data"
            ? { ...content?.data, [oth]: value }
            : value,
      };
    });
    setContent(newContent);
  };
  const handleContentImageChange = (idx, evt) => {
    const value = evt.target.files[0];
    const newContent = content.map((content, sidx) => {
      if (idx !== sidx) return content;
      return { ...content, data: value };
    });
    setContent(newContent);
  };
  const setShow = (event) => {
    const value = event.target.checked;
    setBlogArticle((previousState) => {
      return { ...previousState, show: value };
    });
  };
  const handleAddContent = (evt) => {
    let value = evt.target.value;
    if (!value) value = content.at(-1)?.type || "TEXT";
    setContent(content.concat([{ title: "", type: value, data: "" }]));
  };

  const handleRemoveContent = (idx) => {
    setContent(content.filter((s, sidx) => idx !== sidx));
  };

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`BlogArticle created`);
    redirect("/BlogArticles");
    refresh();
  };
  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };

  async function handleSave() {
    console.log("update:", BlogArticle);
    const newContent = await content.map((content) => {
      if (content.type !== "VIMEO") return content;
      return {
        ...content,
        ["data"]: JSON.stringify(content?.data),
      };
    });
    const data = { ...BlogArticle, content: newContent };
    await createBlogArticle(data);
    history.push("/BlogArticles");
    setLoader(!loader);
    setTimeout(() => {}, 3000);
  }
  const { show } = BlogArticle;
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="title"
              label="title"
              multiline
              className={classes.formInput}
              onChange={handleChange}
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              name="smallTitle"
              label="smallTitle"
              multiline
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="description"
              label="description"
              multiline
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              name="smallDescription"
              label="smallDescription"
              multilines
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid container direction="row" style={{ width: "100%" }}>
            <ImageInput
              name="image"
              label="image"
              accept="image/*"
              onChange={handleChangeImage}
            >
              <ImageField name="image" title="title" />
            </ImageInput>
          </Grid>
          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item xs={1}>
              <span>Show</span>
            </Grid>
            <Grid item xs={4}>
              <Switch
                checked={show}
                color="primary"
                name="show"
                source="show"
                label="show"
                inputProps={{ "aria-label": "primary checkbox" }}
                onChange={setShow}
              />
            </Grid>

            <Grid item xs={5}>
              <ReferenceArrayInput
                label="Associated categories"
                source="categories"
                reference="BlogCategories"
                className={classes.formInput}
                onChange={handleChange}
              >
                <SelectArrayInput optionText="name" />
              </ReferenceArrayInput>
            </Grid>
          </Grid>
          <h5>Content</h5>
          <Grid container direction="row" style={{ width: "100%" }}>
            {content?.map((content, idx) => (
              <Grid
                container
                direction="row"
                style={{ width: "100%", marginBottom: 20 }}
              >
                {/* <h1>{content.type}</h1> */}
                <Grid item xs={3}>
                  <TextField
                    label="title"
                    name="title"
                    variant="filled"
                    multiline
                    fullWidth
                    onChange={(e) => {
                      handleContentChange(idx, e);
                    }}
                    value={content.title}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  {content.type !== "IMAGE" && content.type !== "VIDEO" ? (
                    content.type === "VIMEO" ? (
                      <Grid container direction="row" style={{ width: "100%" }}>
                        <Grid item xs={5}>
                          <TextField
                            label="id"
                            name="data"
                            variant="filled"
                            fullWidth
                            onChange={(e) => {
                              handleContentChange(idx, e, "id");
                            }}
                            value={content?.data?.id}
                          />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          <TextField
                            label="params"
                            name="data"
                            variant="filled"
                            fullWidth
                            onChange={(e) => {
                              handleContentChange(idx, e, "params");
                            }}
                            value={content?.data?.params}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <TextField
                        label="data"
                        name="data"
                        variant="filled"
                        multiline={content.type === "TEXT" ? true : false}
                        fullWidth
                        onChange={(e) => {
                          handleContentChange(idx, e);
                        }}
                        value={content.data}
                      />
                    )
                  ) : (
                    <>
                      <input
                        name="data"
                        label="data"
                        type="file"
                        accept={
                          content.type === "IMAGE" ? "image/*" : "video/mp4"
                        }
                        onChange={(e) => {
                          handleContentImageChange(idx, e);
                        }}
                      />
                      {content.data && (
                        <div>
                          {content.type === "IMAGE" ? (
                            <img
                              src={URL.createObjectURL(content.data)}
                              title={content.title}
                              style={{ height: 150, width: 150 }}
                            />
                          ) : (
                            <video controls title={content.title}>
                              <source
                                style={{ height: 100, width: 100 }}
                                src={URL.createObjectURL(content.data)}
                              />
                            </video>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                  <TextField
                    disabled
                    variant="filled"
                    fullWidth
                    value={content.type}
                  />
                </Grid>

                <Button
                  variant="contained"
                  onClick={() => handleRemoveContent(idx)}
                  style={{ height: 25, minWidth: 25, marginLeft: 25 }}
                >
                  -
                </Button>
              </Grid>
            ))}

            <FormControl variant="filled" style={{ width: "30%" }}>
              <InputLabel id="type-select">Type</InputLabel>
              <Select
                labelId="type-select"
                id="select-filled"
                onClick={(e) => {
                  handleAddContent(e);
                }}
              >
                <MenuItem value="TEXT">TEXT</MenuItem>
                <MenuItem value="LINK">LINK</MenuItem>
                <MenuItem value="VIDEO">VIDEO</MenuItem>
                <MenuItem value="VIMEO">VIMEO</MenuItem>
                <MenuItem value="IMAGE">IMAGE</MenuItem>
                <MenuItem value="AUDIO">AUDIO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(BlogArticleCreate);
