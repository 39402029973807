import React from "react";
import { 
    Show, 
    SimpleShowLayout,
    TextField,
    RichTextField, 
    ImageField,
    FileField,
    useGetOne
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  
});

function CroudFundedsShow(props) {
    const { classes } = props;

    const getUrlFromPath = () => {
        const paths = window.location.href.split("/");
        let url = paths[5];
        
        return url;
      };
    
    const { data, loaded } = useGetOne('croudFundeds', getUrlFromPath());
console.log("daaaaa:", data, "videoCover:", data.videoCover);
    if (!loaded) return <span>Loading</span>;

    const ImagesListField = ({ record }) => {
        return <div>
          <Typography variant="h6" color="grey"> Images : </Typography>
          {record.images.map(item => (
            <div>
              <img src={item} alt="image" style={{ height: 100, width: 200}}/>
              <br/> <br/>
            </div>
          ))}
        </div>
    };

    const CroundFundedTitle = ({ record }) => {
        return <span>Cround Funded {record ? `"${record.name}"` : ''}</span>;
    };

  return (
    <Show title={<CroundFundedTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="email" />
        <RichTextField source="subtitle1" />
        <RichTextField source="subtitle2" />
        <TextField source="distanceToShowDonation" />
        <TextField source="startingDate" />
        <TextField source="currency" />
        <TextField source="amount_to_rise_goal" />
        <TextField source="remainingTime" />
        <TextField source="status" />
        <TextField source="donationInProgress" />
        <TextField source="donationCeiling" />
        <RichTextField source="projectDescription" />
        <RichTextField source="biography" />
        <ImageField source="videoCover" title="title" />
        <FileField source="video" title="Video" />
        <ImageField source="coverImage" title="title" />
        <ImagesListField record={data} source="images" />
      </SimpleShowLayout>
  </Show>
  );
}

export default withStyles(styles)(CroudFundedsShow);
