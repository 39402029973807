import React, { useState, useEffect, useContext } from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  ImageInput,
  ImageField,
  Toolbar,
  SaveButton,
  useNotify,
  LinearProgress,
  useRefresh,
  useRedirect,
  useGetOne,
  useGetList,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { UserContext } from "../../context/UserContext";
import Switch from "@material-ui/core/Switch";
const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function BlogCategorysEdit(props) {
  const history = useHistory();
  const { classes } = props;
  const [loader, setLoader] = React.useState(false);
  const [BlogCategory, setBlogCategory] = React.useState({
    _id: "",
    name: "",
    description: "",
    image: "",
    articles: [],
    show: true,
  });
  const { editBlogCategory } = useContext(UserContext);
  const handleChange = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    setBlogCategory({ ...BlogCategory, [key]: value });
  };
  const handleChangeMultiple = (e) => {
    setBlogCategory({ ...BlogCategory, articles: e.target.value });
  };

  const getUrlFromPath = () => {
    const paths = window.location.href.split("/");
    let url = paths[5];

    return url;
  };
  const { data, loaded } = useGetList("BlogCategories");
  const { data: articlesData, loaded: articlesLoaded } =
    useGetList("BlogArticles");
  const articlesDatafiltered = Object.values(articlesData);
  useEffect(() => {
    if (data) {
      const item = data[getUrlFromPath()];
      const articlesIds = item?.articles.map((el) => el._id);
      setBlogCategory({
        _id: item._id,
        name: item.name,
        description: item.description,
        image: item.image,
        articles: articlesIds,
        show: item.show,
      });
    }
  }, [data]);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleChangeImage = (e) => {
    const value = e;
    const key = "image";
    setBlogCategory({ ...BlogCategory, [key]: value });
  };
  const setShow = (event) => {
    const value = event.target.checked;
    setBlogCategory((previousState) => {
      return { ...previousState, show: value };
    });
  };
  const onSuccess = () => {
    notify(`BlogCategory Edit`);
    redirect("/BlogCategories");
    refresh();
  };
  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };

  async function handleSave() {
    console.log("update:", BlogCategory);
    await editBlogCategory(BlogCategory, getUrlFromPath());
    history.push("/BlogCategories");
    setLoader(!loader);
    setTimeout(() => {}, 3000);
  }
  const { show, articles } = BlogCategory;
  return (
    <Edit onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="name"
              label="name"
              multiline
              className={classes.formInput}
              onChange={handleChange}
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              name="description"
              label="description"
              multiline
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid container direction="row" style={{ width: "100%" }}>
            <ImageField source="image" label="old image" title="title" />
            <ImageInput
              name="image"
              label="image"
              accept="image/*"
              onChange={handleChangeImage}
            >
              <ImageField name="image" title="title" />
            </ImageInput>
          </Grid>

          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item xs={5}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="Articles-multiple-name-label">
                  Articles
                </InputLabel>
                <Select
                  labelId="Articles-multiple-name-label"
                  id="Articles-multiple-name"
                  name="articles"
                  multiple
                  value={articles}
                  onChange={handleChangeMultiple}
                  input={<OutlinedInput label="Articles" />}
                >
                  {articlesDatafiltered?.map((item) => (
                    <MenuItem key={item.title} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={1}>
            <span>Show</span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={show}
              color="primary"
              name="show"
              source="show"
              label="show"
              inputProps={{ "aria-label": "primary checkbox" }}
              onChange={setShow}
            />
          </Grid>
        </Grid>
        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Edit>
  );
}

export default withStyles(styles)(BlogCategorysEdit);
