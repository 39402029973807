import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  RichTextField,
  ImageField,
  FileField,
  useGetOne,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({});

function GainsShow(props) {
  const { classes } = props;

  const getUrlFromPath = () => {
    const paths = window.location.href.split("/");
    let url = paths[5];

    return url;
  };

  const { data, loaded } = useGetOne("Gains", getUrlFromPath());
  console.log("daaaaa:", data, "videoCover:", data.videoCover);
  if (!loaded) return <span>Loading</span>;

  const ImagesListField = ({ record }) => {
    return (
      <div>
        <Typography variant="h6" color="grey">
          {" "}
          Images :{" "}
        </Typography>
        {record.images.map((item) => (
          <div>
            <img src={item} alt="image" style={{ height: 100, width: 200 }} />
            <br /> <br />
          </div>
        ))}
      </div>
    );
  };

  const GainsTitle = ({ record }) => {
    return <span>Cround Funded {record ? `"${record.name}"` : ""}</span>;
  };

  return (
    <Show title={<GainsTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <RichTextField source="subtitle" />
        <RichTextField source="details" />
        <TextField source="price.spows" />
        <TextField source="price.parrains" />
        <ImagesListField record={data} source="images" />
      </SimpleShowLayout>
    </Show>
  );
}

export default withStyles(styles)(GainsShow);
