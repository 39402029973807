import {
  Grid,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect } from "react";
import {
  LinearProgress,
  NumberInput,
  RadioButtonGroupInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useGetList,
  useNotify,
  useRedirect,
  useRefresh,
  Edit,
} from "react-admin";
import { useHistory } from "react-router";
import { UserContext } from "../../context/UserContext";
const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function BlogSectionEdit(props) {
  const history = useHistory();
  const { classes } = props;
  const [loader, setLoader] = React.useState(false);
  const [Section, setSection] = React.useState({
    _id: "",
    displayIndex: "",
    name: "",
    type: "CATEGORY",
    categories: [],
    articles: [],
    attributionType: "PUBLIC",
    teams: [],
    teamgroups: [],
  });
  const { editBlogSection } = useContext(UserContext);
  const handleChange = (event) => {
    const key = event.target.name;
    const value =
      key === "displayIndex"
        ? parseInt(event.target.value)
        : event.target.value;
    setSection({ ...Section, [key]: value });
  };

  const handleChangeType = (item) => {
    setSection({ ...Section, type: item });
  };

  const handleChangeAttributionType = (item) => {
    setSection({ ...Section, attributionType: item });
  };
  const getUrlFromPath = () => {
    const paths = window.location.href.split("/");
    let url = paths[5];

    return url;
  };
  const { data, loaded } = useGetList("BlogSections");
  const { data: articlesData, loaded: articlesLoaded } =
    useGetList("BlogArticles");
  const { data: categoriesData, loaded: categoriesLoaded } =
    useGetList("BlogCategories");
  const { data: teamsData, loaded: teamsLoaded } = useGetList("Teams");
  const { data: teamsGroupsData, loaded: teamsGroupsLoaded } =
    useGetList("TeamsGroups");

  const articlesDatafiltered = Object.values(articlesData);
  const categoriesDatafiltered = Object.values(categoriesData);
  const teamsDatafiltered = Object.values(teamsData);
  const teamsGroupsDatafiltered = Object.values(teamsGroupsData);

  useEffect(() => {
    if (data) {
      const item = data[getUrlFromPath()];
      const articlesIds = item?.articles.map((el) => el._id);
      const categoriesIds = item?.categories.map((el) => el._id);
      const teamsIds = item?.teams.map((el) => el._id);
      const teamsGroupsIds = item?.teamgroups.map((el) => el._id);
      setSection({
        _id: item?._id,
        displayIndex: item?.displayIndex,
        name: item?.name,
        type: item?.type,
        categories: categoriesIds,
        articles: articlesIds,
        attributionType: item?.attributionType,
        teams: teamsIds,
        teamgroups: teamsGroupsIds,
      });
    }
  }, [data]);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`BlogSection Edit`);
    redirect("/BlogSections");
    refresh();
  };
  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };

  async function handleSave() {
    console.log("update:", Section);
    await editBlogSection(Section, getUrlFromPath());
    history.push("/BlogSections");
    setLoader(!loader);
    setTimeout(() => {}, 3000);
  }
  const { type, attributionType, categories, articles, teams, teamgroups } =
    Section;
  return (
    <Edit onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <NumberInput
              name="displayIndex"
              label="displayIndex"
              className={classes.formInput}
              onChange={handleChange}
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              name="name"
              label="name"
              className={classes.formInput}
              onChange={handleChange}
              validate={[required()]}
            />
          </Grid>
          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item xs={5}>
              <RadioButtonGroupInput
                source="type"
                choices={[
                  { id: "CATEGORY", name: "CATEGORY" },
                  { id: "ARTICLE", name: "ARTICLE" },
                ]}
                onChange={handleChangeType}
                defaultValue="CATEGORY"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item xs={5}>
              {type === "CATEGORY" ? (
                <Grid item xs={5}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="categories-multiple-name-label">
                      Categories
                    </InputLabel>
                    <Select
                      labelId="categories-multiple-name-label"
                      id="categories-multiple-name"
                      name="categories"
                      multiple
                      value={categories}
                      onChange={handleChange}
                      input={<OutlinedInput label="Categories" />}
                    >
                      {categoriesDatafiltered?.map((item) => (
                        <MenuItem key={item.name} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={5}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="Articles-multiple-name-label">
                      Articles
                    </InputLabel>
                    <Select
                      labelId="Articles-multiple-name-label"
                      id="Articles-multiple-name"
                      name="articles"
                      multiple
                      value={articles}
                      onChange={handleChange}
                      input={<OutlinedInput label="Articles" />}
                    >
                      {articlesDatafiltered?.map((item) => (
                        <MenuItem key={item.title} value={item.id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item xs={5}>
              <RadioButtonGroupInput
                source="attributionType"
                choices={[
                  { id: "PUBLIC", name: "PUBLIC" },
                  { id: "PRIVATE", name: "PRIVATE" },
                ]}
                onChange={handleChangeAttributionType}
                defaultValue="PUBLIC"
              />
            </Grid>
          </Grid>
          {attributionType === "PRIVATE" && (
            <Grid container direction="row" style={{ width: "100%" }}>
              <Grid item xs={5}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="teams-multiple-name-label">teams</InputLabel>
                  <Select
                    labelId="teams-multiple-name-label"
                    id="teams-multiple-name"
                    name="teams"
                    multiple
                    value={teams}
                    onChange={handleChange}
                    input={<OutlinedInput label="Teams" />}
                  >
                    {teamsDatafiltered?.map((item) => (
                      <MenuItem key={item.name} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={5}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="teams-multiple-name-label">teams</InputLabel>
                  <Select
                    labelId="teams-multiple-name-label"
                    id="teams-multiple-name"
                    name="teamgroups"
                    multiple
                    value={teamgroups}
                    onChange={handleChange}
                    input={<OutlinedInput label="Teamsgroups" />}
                  >
                    {teamsGroupsDatafiltered?.map((item) => (
                      <MenuItem key={item.name} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Grid>

        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Edit>
  );
}

export default withStyles(styles)(BlogSectionEdit);
