import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
  SimpleShowLayout,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  
}));

const DiscountCodesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const DiscountCodesList = (props) => {
  const classes = useStyles();

  return (
    <List 
      //filters={<DiscountCodesFilter />} 
      {...props} title="List des discount code">
      <Datagrid>
        <TextField source="id" />
        <TextField source="type" />
        {/*<TextField source="code" />*/}
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const DiscountCodesTitle = ({ record }) => {
  return <span>Code {record ? `"${record.type}"` : ''}</span>;
};

const CodesField = ({ record }) => {
  return <div>
    <Typography variant="h6" color="grey"> Codes : </Typography>
    {record.code.map(item => (
      <div>
        <Typography variant="h8">{item}</Typography> 
        <br/>
      </div>
    ))}
  </div>
};

export const DiscountCodesShow = (props) => (
  <Show title={<DiscountCodesTitle />} {...props}>
      <SimpleShowLayout>
          <TextField source="id" disabled />
          <TextField source="type" />
          <CodesField />
      </SimpleShowLayout>
  </Show>
);
