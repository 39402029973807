import React from "react";
// import { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "Janvier",
    nbr: 40,
  },
  {
    name: "Février",
    nbr: 30,
  },
  {
    name: "Mars",
    nbr: 20,
  },
  {
    name: "Avril",
    nbr: 27,
  },
  {
    name: "Mai",
    nbr: 18,
  },
  {
    name: "Juin",
    nbr: 23,
  },
  {
    name: "Juillet",
    nbr: 9,
  },
  {
    name: "Aout",
    nbr: 19,
  },
  {
    name: "Septembre",
    nbr: 15,
  },
  {
    name: "Octobre",
    nbr: 24,
  },
  {
    name: "Novembre",
    nbr: 17,
  },
  {
    name: "Décembre",
    nbr: 21,
  },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`} resto</p>
      </div>
    );
  }

  return null;
};

function DashedLineChart() {
  return (
    <div>
      <h1 style={{ color: "#d40562" }}>Dashed Line Chart</h1>
      <h4>Suivi mensuel pour 2021</h4>
      <BarChart
        width={490}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar dataKey="nbr" barSize={20} fill="#8884d8" />
      </BarChart>
    </div>
  );
}

export default DashedLineChart;
