import React, { useState, useEffect, useContext } from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  Toolbar,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  useNotify,
  LinearProgress,
  useRefresh,
  useRedirect,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { UserContext } from "../../context/UserContext";
import Switch from "@material-ui/core/Switch";
const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function FeedAdCreate(props) {
  const history = useHistory();
  const { classes } = props;
  const [loader, setLoader] = useState(false);
  const [feedAd, setFeedAd] = useState({
    link: "",
    image: "",
  });
  const { createFeedAd } = useContext(UserContext);
  const handleChange = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    setFeedAd({ ...feedAd, [key]: value });
  };

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleChangeImage = (e) => {
    const value = e;
    const key = "image";
    setFeedAd({ ...feedAd, [key]: value });
  };

  const onSuccess = () => {
    notify(`Feed Ad created`);
    redirect("/feedAds");
    refresh();
  };
  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };

  async function handleSave() {
    await createFeedAd(feedAd);
    history.push("/feedAds");
    setLoader(!loader);
    setTimeout(() => {}, 3000);
  }

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="link"
              label="Link"
              className={classes.formInput}
              onChange={handleChange}
              type="url"
            />
          </Grid>
        </Grid>

        <ImageInput
          name="image"
          label="image"
          accept="image/*"
          onChange={handleChangeImage}
        >
          <ImageField name="image" title="title" />
        </ImageInput>

        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(FeedAdCreate);
