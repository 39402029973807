import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
  SimpleShowLayout,
  DeleteButton,
  ImageField,
  ArrayField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

export const BlogArticlesList = (props) => {
  const classes = useStyles();

  return (
    <List {...props} title="List des articles">
      <Datagrid>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="description" />
        <EditButton />
        <ShowButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const BlogArticlesTitle = ({ record }) => {
  return <span>BlogArticles {record ? `"${record.title}"` : ""}</span>;
};
const BlogArticlesImageField = ({ record }) => {
  return record.type === "IMAGE" ? (
    <div>
      <img
        src={record.data}
        title={record.title}
        style={{ height: 150, width: 150 }}
      />
    </div>
  ) : (
    <span>{record.data}</span>
  );
};
export const BlogArticlesShow = (props) => (
  <Show title={<BlogArticlesTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" disabled />
      <TextField source="smallTitle" />
      <TextField source="title" />
      <TextField source="smallDescription" />
      <TextField source="description" />
      <ImageField source="image" />
      <ArrayField source="content">
        <Datagrid>
          <TextField source="title" />
          <TextField source="type" />
          <BlogArticlesImageField />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
