import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  
}));

const OffersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const OffersList = (props) => {
  const classes = useStyles();

  /*const DurationField = ({ record }) => {
    const annee = record.duration.substr(0, 4);
    return <span>{record ? `${annee}` : ''}</span>;
    //return <TextField source="duration" label="duration" />;
  };*/

  return (
    <List 
      //filters={<OffersFilter />} 
      {...props} title="List des offers">
      <Datagrid>
        <TextField source="name" />
        <TextField source="owner.name" label="Owner Name"/>
        <TextField source="duration" label="duration" />
        <TextField source="subtitle1" />
        <TextField source="price" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
