import client from "../apollo/client";
import { LOGIN_ADMIN, LOAD_ME } from "../graphql/user";

const authProvider = {
  login: async ({ username, password }) => {
    const data = {
      email: username,
      password: password,
    };
    
    await client
      .mutate({
        mutation: LOGIN_ADMIN,
        variables: {
          input: data,
        },
      })
      .then(async (res) => {
        console.log(res)
        localStorage.setItem("accessToken", res.data.loginAdmin.accessToken);
        localStorage.setItem("refreshToken", res.data.loginAdmin.refreshToken);
        await client
          .query({
            query: LOAD_ME,
          })
          .then(async (data) => {
            console.log("dataaaaUser:", data.data.me);
            localStorage.setItem("profile", JSON.stringify(data.data.me));
          }).catch(err => {console.log(err)});
      }).catch((error) => {
        console.log(error);
      });
     
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject({ message: false });
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("accessToken")
      ? Promise.resolve()
      : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("profile");
    //localStorage.removeItem("permissions");
    //localStorage.removeItem("auth");
    return Promise.resolve("/login");
  },
  getIdentity: () => {
    const profile = localStorage.getItem("profile");
    try {
      const data = JSON.parse(profile);
      const dataItems = {
        id: data._id,
        firstName: data.firstName,
        lastName: data.lastName,
        fullName: data.fullName,
        reason: data.reason,
        email: data.local.email,
        avatar: data.avatar,
        country: data.country,
        gender: data.gender,
      }
      return Promise.resolve(dataItems);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
