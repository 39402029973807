import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Edit,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  DateTimeInput,
  ImageField,
  Button,
  FileField,
  useGetOne,
  Toolbar,
  SaveButton,
  ArrayInput,
  ImageInput,
  SimpleFormIterator,
  FileInput,
  FormDataConsumer,
} from "react-admin";
import AddIcon from "@material-ui/icons/RemoveCircleOutline";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router";
import Switch from "@material-ui/core/Switch";

const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
  listOfIngredients: {
    overflowX: "scroll",
    overflowY: "hidden",
    display: "flex",
    width: 900,
    marginLeft: "30%",
  },
  removeBtn: {
    backgroundColor: "red",
  },
});
function DelBtn(props) {
  const { classes } = props;
  return (
    <Button
      size="small"
      style={{ float: "right" }}
      {...props}
      onClick={props.onClick}
    >
      <span
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <AddIcon style={{ fontSize: 15, alignSelf: "center" }} />
        <span style={{ fontSize: 12, alignSelf: "center" }}>{"Remove"}</span>
      </span>
    </Button>
  );
}

function SponsorisedPostEdit(props) {
  const { classes } = props;
  const history = useHistory();
  const [sponsorisedPostType, setSponsorisedPostType] = React.useState("OFFER");
  const [canClose, setCanClose] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [isFirst, setIsFirst] = React.useState(false);
  const [content, setContent] = React.useState([]);
  const { updateSponsorisedPost, reloadSponsorisedPost } =
    useContext(UserContext);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedVideoImage, setUploadedVideoImage] = useState(null);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [showNewImage, setShowNewImage] = useState(false);
  //const [image, setImage] = useState(null);
  //const [imageVideo, setImageVideo] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [nameVideo, setNameVideo] = useState(null);

  const getUrlFromPath = () => {
    const paths = window.location.href.split("/");
    let url = paths[5];

    return url;
  };

  const { data, loaded } = useGetOne("sponsorisedPost", getUrlFromPath());

  const [newSponsorisedPost, setNewSponsorisedPost] = useState();
  useEffect(() => {
    if (data) {
      setNewSponsorisedPost({
        id: data._id,
        title: data.title,
        subTitle: data.subTitle,
        type: data.type,
        content: data.content,
        offer: data.offer,
        gain: data.gain,
        croudFund: data.croudFund,
        isFirst: data.isFirst,
        active: data.active,
        canClose: data.canClose,
        coverImage: data.coverImage,
      });

      setSponsorisedPostType(data.type);
      setContent(
        data.content.map((res) => {
          return res.type;
        })
      );
      console.log("qqqqqqqqqqqqqqqq:", {
        id: data._id,
        title: data.title,
        subTitle: data.subTitle,
        type: data.type,
        content: data.content,
        offer: data.offer,
        gain: data.gain,
        croudFund: data.croudFund,
        isFirst: data.isFirst,
        isActive: data.active,
        canClose: data.canClose,
        coverImage: data.coverImage,
      });
    }
  }, [data]);

  const [loader, setLoader] = useState(false);

  if (!loaded && !newSponsorisedPost) return <span>Loading</span>;

  const handleChange = (event) => {
    let field = null;
    let value = null;
    console.log(event);
    if (event && event.path) {
      value = event;
      field = "coverImage";
    } else {
      value = event.target.value;
      field = event.target.name;
    }
    if (
      field.split(".") &&
      field.split(".")[1] &&
      field.split(".")[0] !== "categories[0]"
    ) {
      field = field.split(".")[1];
    }

    console.log(field, value, { ...newSponsorisedPost, [field]: value });
    setNewSponsorisedPost({ ...newSponsorisedPost, [field]: value });
  };
  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setNewSponsorisedPost({ ...newSponsorisedPost, categories: value });
  };

  function removeItem(index) {
    setNewSponsorisedPost((prevnewSponsorisedPost) => {
      let items = prevnewSponsorisedPost;
      let listImages = [...items.oldImages];
      listImages.splice(index, 1);
      const updatedGain = { ...prevnewSponsorisedPost, oldImages: listImages };
      return updatedGain;
    });
  }

  const handleAddPicture = () => {
    const fileInput = document.getElementById("newImageInput");
    fileInput.click();
  };
  const handleActiveChange = (event) => {
    setNewSponsorisedPost({
      ...newSponsorisedPost,
      active: event.target.checked,
    });
  };
  const handleNewImageChange = (event) => {
    const img = event.target.files[0];
    if (img) {
      setNewSponsorisedPost((prevnewSponsorisedPost) => {
        let items = { ...prevnewSponsorisedPost };
        let listNewImages = items.images;

        const updatedNewImages = [...listNewImages, img];
        items = { ...prevnewSponsorisedPost, images: updatedNewImages };
        return items;
      });
      setShowNewImage(true);
      setNewImage(img);
    }
  };
  const handleContentChange = (event, i, key) => {
    let value;
    if (event && event.path) {
      value = event;
    } else {
      value = event.target.value;
    }
    if (key === "type") {
      setContent((oldArr) => {
        let arr = oldArr;
        arr[i] = value;
        return arr;
      });
    }
    setNewSponsorisedPost((prevnewSponsorisedPost) => {
      let arr = [...prevnewSponsorisedPost.content];
      arr[i] = { ...arr[i], [key]: value };
      const updatedGain = { ...prevnewSponsorisedPost, content: arr };
      return updatedGain;
    });
    console.log(event.target);
  };
  const removeContent = (i) => {
    setContent((oldArr) => {
      let arr = oldArr;
      arr.splice(i, 1);
      return arr;
    });
    setNewSponsorisedPost((prevnewSponsorisedPost) => {
      let arr = [...prevnewSponsorisedPost.content];
      arr.splice(i, 1);
      const updatedGain = { ...prevnewSponsorisedPost, content: arr };
      return updatedGain;
    });
  };

  function removeNewItem(index) {
    setNewSponsorisedPost((prevnewSponsorisedPost) => {
      let items = prevnewSponsorisedPost;
      let listNewImages = [...items.images];
      listNewImages.splice(index, 1);
      const updatedGain = { ...prevnewSponsorisedPost, images: listNewImages };
      return updatedGain;
    });
  }

  async function handleSave() {
    console.log("update:", newSponsorisedPost);
    await updateSponsorisedPost(getUrlFromPath(), newSponsorisedPost);
    setLoader(!loader);
    setTimeout(() => {
      history.push("/SponsorisedPost");
    }, 3000);
  }

  const ImagesListField = ({ record }) => {
    return (
      <div>
        <Typography variant="h6" color="grey">
          {" "}
          Images :{" "}
        </Typography>
        {newSponsorisedPost &&
          newSponsorisedPost.oldImages.length &&
          newSponsorisedPost.oldImages.map((item, index) => (
            <div>
              <Grid container direction="row">
                <Grid item xs={2}>
                  <img
                    src={item}
                    alt="image"
                    style={{ height: 100, width: 200 }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => removeItem(index)}
                  >
                    <DeleteIcon style={{ color: "#FF0000" }} />
                  </IconButton>
                </Grid>
              </Grid>
              <br />
            </div>
          ))}
      </div>
    );
  };

  const NewImagesListField = () => {
    return (
      <div style={{ display: showNewImage ? "block" : "none" }}>
        {newSponsorisedPost &&
          newSponsorisedPost.images.length &&
          newSponsorisedPost.images.map((item, index) => (
            <div>
              <Grid container direction="row">
                <Grid item xs={2}>
                  <img
                    src={URL.createObjectURL(item)}
                    alt="image"
                    style={{ height: 100, width: 200 }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => removeNewItem(index)}
                  >
                    <DeleteIcon style={{ color: "#FF0000" }} />
                  </IconButton>
                </Grid>
              </Grid>
              <br />
            </div>
          ))}
      </div>
    );
  };

  const SponsorisedPostTitle = ({ record }) => {
    return <span>Cround Funded {record ? `"${record.name}"` : ""}</span>;
  };

  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };

  return (
    <Edit title={<SponsorisedPostTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={1}>
            <span>is first</span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={
                newSponsorisedPost && newSponsorisedPost.isFirst
                  ? newSponsorisedPost.isFirst
                  : false
              }
              color="primary"
              name="isFirst"
              source="isFirst"
              label="isFirst"
              inputProps={{ "aria-label": "primary checkbox" }}
              onChange={(e) => {
                setIsFirst(e.target.checked);
                setNewSponsorisedPost({
                  ...newSponsorisedPost,
                  isFirst: e.target.checked,
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <span>Active sponsorisedPost</span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={
                newSponsorisedPost && newSponsorisedPost.active
                  ? newSponsorisedPost.active
                  : false
              }
              color="primary"
              name="active"
              source="active"
              label="Active sponsorisedPost"
              inputProps={{ "aria-label": "primary checkbox" }}
              onChange={(e) => {
                setNewSponsorisedPost({
                  ...newSponsorisedPost,
                  active: e.target.checked,
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={1}>
            <span>can close</span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={
                newSponsorisedPost && newSponsorisedPost.canClose
                  ? newSponsorisedPost.canClose
                  : false
              }
              color="primary"
              name="canClose"
              label="canClose"
              source="canClose"
              inputProps={{ "aria-label": "primary checkbox" }}
              onChange={(e) => {
                console.log(e.target.checked);
                setNewSponsorisedPost({
                  ...newSponsorisedPost,
                  canClose: e.target.checked,
                });
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <SelectInput
              source="type"
              choices={[
                { id: "OFFER", name: "offer Post" },
                { id: "CROUDFUND", name: "croudfund Post" },
                { id: "GAIN", name: "gain Post" },
                { id: "MARKETPLACE", name: "marketplace Post" },
                { id: "SIMPLE", name: "simple Post" },
              ]}
              name="type"
              label="sponsorisedPost Type"
              style={{ width: "100%" }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={4}>
            <TextInput
              source="title"
              onChange={handleChange}
              name="title"
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              source="subTitle"
              name="subTitle"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
        </Grid>
        {newSponsorisedPost &&
        newSponsorisedPost.type &&
        newSponsorisedPost.type !== "SIMPLE" ? (
          newSponsorisedPost.type === "OFFER" ? (
            <Grid item xs={4}>
              <ReferenceInput
                source="offer._id"
                name="offer"
                style={{ width: "80%" }}
                reference="offers"
                onChange={handleChange}
              >
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </Grid>
          ) : newSponsorisedPost.type === "CROUDFUND" ? (
            <Grid item xs={4}>
              <ReferenceInput
                label="croudFund"
                source="croudFund"
                name="croudFund"
                style={{ width: "80%" }}
                reference="croudFundeds"
                onChange={handleChange}
              >
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </Grid>
          ) : newSponsorisedPost.type === "GAIN" ? (
            <Grid item xs={4}>
              <ReferenceInput
                source="gain"
                name="gain"
                style={{ width: "80%" }}
                reference="gains"
                onChange={handleChange}
              >
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </Grid>
          ) : (
            <Grid
              container
              xs={12}
              direction="row"
              style={{ width: "100%", flexWrap: "wrap" }}
            >
              <Grid item xs={4}>
                <ReferenceInput
                  source="offer"
                  name="offer"
                  style={{ width: "80%" }}
                  reference="offers"
                  onChange={handleChange}
                >
                  <SelectInput optionText="name" optionValue="id" />
                </ReferenceInput>
              </Grid>

              <Grid item xs={4}>
                <ReferenceInput
                  name="croudFund"
                  source="croudFund"
                  style={{ width: "80%" }}
                  onChange={handleChange}
                  reference="croudFundeds"
                >
                  <SelectInput optionText="name" optionValue="id" />
                </ReferenceInput>
              </Grid>

              <Grid item xs={4}>
                <ReferenceInput
                  source="gain"
                  name="gain"
                  style={{ width: "80%" }}
                  onChange={handleChange}
                  reference="gains"
                >
                  <SelectInput optionText="name" optionValue="id" />
                </ReferenceInput>
              </Grid>
            </Grid>
          )
        ) : (
          <></>
        )}

        <Grid container direction="row" style={{ width: "100%" }}></Grid>

        <ImageField
          source="coverImage"
          name="image"
          label="current Image"
          title="title"
        />
        <ImageInput
          source="coverImage"
          label="coverImage"
          name="coverImage"
          onChange={handleChange}
          accept="image/*"
        >
          <ImageField source="coverImage" name="image" title="title" />
        </ImageInput>

        <Grid container direction="row" style={{ width: "100%" }}></Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={12}>
            {newSponsorisedPost &&
              newSponsorisedPost.content.map((el, i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      borderBottom: "1px solid gray",
                      marginBottom: 5,
                    }}
                  >
                    <div style={{ fontSize: 20 }}>{i}</div>

                    <div
                      style={{
                        width: "80%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <SelectInput
                        choices={[
                          { id: "TEXTBLOCK", name: "text block" },
                          { id: "TITLE", name: "title" },
                          { id: "VIDEO", name: "video" },
                          { id: "IMAGE", name: "image" },
                          { id: "OFFERACTION", name: "offer action" },
                          {
                            id: "CROUDFUNDACTION",
                            name: "croudfund action",
                          },
                          { id: "GAINACTION", name: "gain action" },
                          { id: "OFFER", name: "offer card" },
                          { id: "CROUDFUND", name: "croudfund card" },
                          { id: "GAIN", name: "gain card" },
                        ]}
                        source={"content[" + i + "].type"}
                        label={"content " + i + " type"}
                        style={{ width: "50%" }}
                        onChange={(e) => {
                          handleContentChange(e, i, "type");
                        }}
                      />
                      {content[i] === "OFFER" ||
                      content[i] === "OFFERACTION" ? (
                        <ReferenceInput
                          source={"content[" + i + "].offer"}
                          onChange={(e) => {
                            handleContentChange(e, i, "offer");
                          }}
                          label={"content" + i + "offer"}
                          reference="offers"
                        >
                          <SelectInput optionText="name" optionValue="id" />
                        </ReferenceInput>
                      ) : content[i] === "GAIN" ||
                        content[i] === "GAINACTION" ? (
                        <ReferenceInput
                          source={"content[" + i + "].gain"}
                          onChange={(e) => {
                            handleContentChange(e, i, "gain");
                          }}
                          label={"content" + i + "gain"}
                          reference="gains"
                        >
                          <SelectInput optionText="name" optionValue="id" />
                        </ReferenceInput>
                      ) : content[i] === "CROUDFUND" ||
                        content[i] === "CROUDFUNDACTION" ? (
                        <ReferenceInput
                          label={"content" + i + "croudfund"}
                          onChange={(e) => {
                            handleContentChange(e, i, "croudFund");
                          }}
                          reference="croudFundeds"
                          source={"content[" + i + "].croudFund"}
                        >
                          <SelectInput optionText="name" optionValue="id" />
                        </ReferenceInput>
                      ) : content[i] === "TITLE" ? (
                        <TextInput
                          source={"content[" + i + "].title"}
                          onChange={(e) => {
                            handleContentChange(e, i, "title");
                          }}
                          label="title"
                        />
                      ) : content[i] === "TEXTBLOCK" ? (
                        <TextInput
                          source={"content[" + i + "].text"}
                          onChange={(e) => {
                            handleContentChange(e, i, "text");
                          }}
                          multiline
                          label="text"
                          style={{ width: "100%" }}
                        />
                      ) : content[i] === "IMAGE" ? (
                        <>
                          <ImageField
                            source={"content[" + i + "].image"}
                            name="image"
                            label="current image"
                            title="title"
                          />
                          <ImageInput
                            source={"content[" + i + "].image"}
                            onChange={(e) => {
                              handleContentChange(e, i, "image");
                            }}
                            label="image"
                            accept="image/*"
                          >
                            <ImageField name="image" title="title" />
                          </ImageInput>
                        </>
                      ) : content[i] === "VIDEO" ? (
                        <FileInput
                          onChange={(e) => {
                            handleContentChange(e, i, "video");
                          }}
                          source={"content[" + i + "].video"}
                          label="video"
                          accept="video/mp4"
                        >
                          <FileField name="video" title="title" />
                        </FileInput>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      <DelBtn
                        {...props}
                        onClick={() => {
                          removeContent(i);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            <Button
              onClick={() => {
                setContent((oldArr) => {
                  let arr = oldArr;
                  arr.push("");
                  return arr;
                });

                setNewSponsorisedPost((prevnewSponsorisedPost) => {
                  let arr = [...prevnewSponsorisedPost.content];
                  arr.push({ type: "TEXTBLOCK" });
                  const updatedGain = {
                    ...prevnewSponsorisedPost,
                    content: arr,
                  };
                  return updatedGain;
                });
              }}
            >
              <>
                <AddCircleOutlineIcon />
                {"add"}
              </>
            </Button>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
}

export default withStyles(styles)(SponsorisedPostEdit);
