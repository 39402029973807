import React, { useState, useEffect, useContext } from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  Toolbar,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  useNotify,
  LinearProgress,
  useRefresh,
  useRedirect,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { UserContext } from "../../context/UserContext";
import Switch from "@material-ui/core/Switch";
const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function EstablishmentsCreate(props) {
  const history = useHistory();
  const { classes } = props;
  const [establishmentType, setEstablishmentType] = React.useState("OFFER");
  const [loader, setLoader] = React.useState(false);
  const [establishment, setEstablishment] = React.useState({
    type: "",
    image: "",
    name: "",
    address: "",
    logo: "",
    caloriesPerMinute: 0,
    activityType: "",
    useGps: false,
    value: 0,
  });
  const { createEstablishment } = useContext(UserContext);
  const handleChange = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    setEstablishment({ ...establishment, [key]: value });
  };

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onPlaceChange = (e) => {
    geocodeByAddress(e)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log(lat, lng);
        setEstablishment({ ...establishment, address: { lat, lng } });
      });
  };
  const handleChangeImage = (e) => {
    const value = e;
    const key = "image";
    setEstablishment({ ...establishment, [key]: value });
  };
  const handleChangeLogo = (e) => {
    const value = e;
    const key = "logo";
    setEstablishment({ ...establishment, [key]: value });
  };
  const onSuccess = () => {
    notify(`establishment created`);
    redirect("/establishments");
    refresh();
  };
  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };
  const getUrlFromPath = () => {
    const paths = window.location.href.split("/");
    let url = paths[5];

    return url;
  };
  async function handleSave() {
    console.log("update:", establishment);
    await createEstablishment(establishment);
    history.push("/establishments");
    setLoader(!loader);
    setTimeout(() => {}, 3000);
  }
  const handleGpsChange = (event) => {
    setEstablishment({ ...establishment, useGps: event.target.checked });
  };
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <span>activate GPS</span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={
                establishment && establishment.useGps
                  ? establishment.useGps
                  : false
              }
              onChange={handleGpsChange}
              color="primary"
              name="useGps"
              label="activate GPS"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="name"
              label="name"
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <SelectInput
              choices={[
                { id: "SHOP", name: "Magasin" },
                { id: "GYM", name: "GYM/Sale de sport" },
                { id: "CLUB", name: "Club" },
              ]}
              name="type"
              label="Type d'etablisement"
              style={{ width: "100%" }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          {establishment.type === "GYM" || establishment.type === "CLUB" ? (
            <>
              <Grid item xs={5}>
                <TextInput
                  label="activityType"
                  name="activityType"
                  className={classes.formInput}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <NumberInput
                  label="caloriesPerMinute"
                  name="caloriesPerMinute"
                  className={classes.formInput}
                  onChange={handleChange}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={5}>
                <NumberInput
                  label="value"
                  name="value"
                  className={classes.formInput}
                  onChange={handleChange}
                />
              </Grid>
            </>
          )}
        </Grid>
        <GooglePlacesAutocomplete
          apiKey="AIzaSyAk0IGxCNSynONzCyc5YVHdNHJfAShwNkg"
          selectProps={{
            name: "address",
            isClearable: true,
            onChange: (o) => {
              onPlaceChange(o.label);
            },
          }}
        />
        <ImageInput
          name="image"
          label="image"
          accept="image/*"
          onChange={handleChangeImage}
        >
          <ImageField name="image" title="title" />
        </ImageInput>
        <ImageInput
          name="logo"
          label="logo"
          onChange={handleChangeLogo}
          accept="image/*"
        >
          <ImageField name="logo" title="title" />
        </ImageInput>
        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(EstablishmentsCreate);
