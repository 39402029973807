import React, { useState } from "react";
import {
  CREATE_CODES,
  UPDATE_CODE,
  UPDATE_MARKET_PLACE_PICTURE,
  UPDATE_CROUD_FUNDED,
  UPDATE_OFFER,
  UPDATE_GAIN,
  UPDATE_SPONSORISEDPOST,
  CREATE_ESTABLISHMENT,
  UPDATE_ESTABLISHMENT,
  CREATE_TEAM,
  UPDATE_TEAM,
  CREATE_TEAM_GROUP,
  UPDATE_TEAM_GROUP,
  CREATE_FEED_AD,
  UPDATE_FEED_AD,
  CREATE_JACKPOT,
  UPDATE_JACKPOT,
  CREATE_BLOG_SECTION,
  CREATE_BLOG_ARTICLE,
  CREATE_BLOG_CATEGORY,
  UPDATE_BLOG_CATEGORY,
  UPDATE_BLOG_SECTION,
  UPDATE_BLOG_ARTICLE,
} from "../graphql/user";
import client from "../apollo/client";
import { gql } from "@apollo/client";

const UserContext = React.createContext();

function UserContextProvider({ children }) {
  const [address, setAddress] = useState({});
  const [activeUser, setActiveUser] = useState({
    userId: "",
    avatar: "",
    local: { email: "", password: "" },
    firstName: "activeUser",
    lastName: "activeUser",
    gender: "",
    description: "",
    birthDate: "",
    birthDateDisplay: true,
    phoneNumber: "",
    city: "",
    languages: "",
    fullName: "",
  });
  const [reloadOffers, setReloadOffers] = useState(false);
  const [reloadCrouds, setReloadCrouds] = useState(false);

  async function createCodes(inputData) {
    let data = {
      type: inputData.type,
      code: inputData.code,
    };

    await client
      .mutate({
        mutation: CREATE_CODES,
        variables: {
          input: data,
        },
      })
      .then(async (res) => {
        console.log("res create code: ", res);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  }

  async function updateCodes(id, inputData) {
    const data = {
      type: inputData.type,
      code: inputData.code,
    };

    await client
      .mutate({
        mutation: UPDATE_CODE,
        variables: {
          id: id,
          input: data,
        },
      })
      .then(async (res) => {
        console.log("res update code: ", res);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  }

  async function updateCroudFunded(id, inputData, imageVideo, video) {
    let urlImages = [];
    console.log("here");
    if (inputData.images) {
      urlImages = await Promise.all(
        inputData.images
          .map(async (image) => {
            const url = await new Promise((resolve, reject) => {
              client
                .mutate({
                  mutation: UPDATE_MARKET_PLACE_PICTURE,
                  variables: {
                    file: image,
                  },
                })
                .then((res) => {
                  console.log("dataRes", res.data.uploadMarketPlacePicture);
                  if (res.data && res.data.uploadMarketPlacePicture) {
                    resolve(res.data.uploadMarketPlacePicture);
                  } else reject("http://test.com");
                });
            });
            console.log("images: ", url);
            return url;
          })
          .filter((res) => res != null)
      );
    }

    let imgList = inputData.oldImages.concat(urlImages);

    const croudTime = inputData.remainingTime.toString();
    const heure = croudTime.substr(11, 2);
    const min = croudTime.substr(14, 2);
    const time = heure + "." + min;

    let urlCoverVideo;
    if (typeof imageVideo === "string") {
      urlCoverVideo = imageVideo;
    } else {
      urlCoverVideo = await new Promise((resolve, reject) => {
        client
          .mutate({
            mutation: UPDATE_MARKET_PLACE_PICTURE,
            variables: {
              file: imageVideo,
            },
          })
          .then((res) => {
            console.log("dataRes", res.data.uploadMarketPlacePicture);
            if (res.data && res.data.uploadMarketPlacePicture) {
              resolve(res.data.uploadMarketPlacePicture);
            } else reject("http://test.com");
          });
      });
    }

    let urlVideo;
    if (typeof video === "string") {
      urlVideo = video;
    } else {
      urlVideo = await new Promise((resolve, reject) => {
        client
          .mutate({
            mutation: UPDATE_MARKET_PLACE_PICTURE,
            variables: {
              file: video,
            },
          })
          .then((res) => {
            console.log("dataRes", res.data.uploadMarketPlacePicture);
            if (res.data && res.data.uploadMarketPlacePicture) {
              resolve(res.data.uploadMarketPlacePicture);
            } else reject("http://test.com");
          });
      });
    }
    let categoriesList;
    console.log("categ", inputData.categories);
    if (typeof inputData.categories === "string") {
      categoriesList = [];
      categoriesList.push(inputData.categories);
    } else {
      const idCategory = inputData.categories[0]._id;
      categoriesList = [];
      categoriesList.push(idCategory);
    }
    const data = {
      name: inputData.name,
      //owner: inputData.owner,
      subtitle1: inputData.subtitle1,
      subtitle2: inputData.subtitle2,
      distanceToShowDonation: parseFloat(inputData.distanceToShowDonation),
      startingDate: inputData.startingDate,
      currency: inputData.currency,
      amount_to_rise_goal: parseFloat(inputData.amount_to_rise_goal),
      remainingTime: parseFloat(time),
      status: inputData.status,
      donationInProgress: parseFloat(inputData.donationInProgress),
      donationCeiling: parseFloat(inputData.donationCeiling),
      projectDescription: inputData.projectDescription,
      images: imgList,
      videoCover: urlCoverVideo,
      team_id: inputData.team_id,
      teamGroup_id: inputData.teamGroup_id,
      teamSubGroup_id: "",
      video: urlVideo,
      categories: categoriesList,
      biography: inputData.biography,
    };

    await client
      .mutate({
        mutation: UPDATE_CROUD_FUNDED,
        variables: {
          id: id,
          input: data,
        },
      })
      .then(async (res) => {
        console.log("res update croud: ", res);
        setReloadCrouds(true);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  }
  async function updateGain(id, inputData) {
    let urlImages = [];
    console.log("here");
    if (inputData.images) {
      urlImages = await Promise.all(
        inputData.images
          .map(async (image) => {
            const url = await new Promise((resolve, reject) => {
              client
                .mutate({
                  mutation: UPDATE_MARKET_PLACE_PICTURE,
                  variables: {
                    file: image,
                  },
                })
                .then((res) => {
                  console.log("dataRes", res.data.uploadMarketPlacePicture);
                  if (res.data && res.data.uploadMarketPlacePicture) {
                    resolve(res.data.uploadMarketPlacePicture);
                  } else reject("http://test.com");
                });
            });
            console.log("images: ", url);
            return url;
          })
          .filter((res) => res != null)
      );
    }

    let imgList = inputData.oldImages.concat(urlImages);
    let categoriesList;
    console.log("categ", inputData.categories);
    if (typeof inputData.categories === "string") {
      categoriesList = [];
      categoriesList.push(inputData.categories);
    } else {
      const idCategory = inputData.categories[0]._id;
      categoriesList = [];
      categoriesList.push(idCategory);
    }
    console.log(inputData);
    const data = {
      name: inputData.name,
      subtitle: inputData.subtitle,
      details: inputData.details,

      images: imgList,
      categories: categoriesList,
      active: inputData.active,

      price: {
        spows: parseInt(inputData.spows),
        parrains: parseInt(inputData.parrains),
      },
      description: inputData.description,

      //owner: inputData.owner,
    };

    await client
      .mutate({
        mutation: UPDATE_GAIN,
        variables: {
          id: id,
          input: data,
        },
      })
      .then(async (res) => {
        console.log("res update croud: ", res);
        setReloadCrouds(true);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  }

  async function updateSponsorisedPost(id, inputData) {
    let coverImage = inputData.coverImage;
    if (typeof inputData.coverImage === "object") {
      coverImage = await new Promise((resolve, reject) => {
        client
          .mutate({
            mutation: UPDATE_MARKET_PLACE_PICTURE,
            variables: {
              file: inputData.coverImage,
            },
          })
          .then((res) => {
            console.log("dataRes", res.data.uploadMarketPlacePicture);
            if (res.data && res.data.uploadMarketPlacePicture) {
              resolve(res.data.uploadMarketPlacePicture);
            } else reject("http://test.com");
          });
      });

      console.log("image uploading", coverImage);
    }

    let content = inputData.content.length
      ? await Promise.all(
          inputData.content.map(async (el) => {
            if (el.type === "IMAGE" && typeof el.image === "object") {
              el.image = await new Promise((resolve, reject) => {
                client
                  .mutate({
                    mutation: UPDATE_MARKET_PLACE_PICTURE,
                    variables: {
                      file: el.image,
                    },
                  })
                  .then((res) => {
                    console.log("dataRes", res.data.uploadMarketPlacePicture);
                    if (res.data && res.data.uploadMarketPlacePicture) {
                      resolve(res.data.uploadMarketPlacePicture);
                    } else reject("http://test.com");
                  });
              });
            }
            if (el.type === "VIDEO" && typeof el.video === "object") {
              el.video = await new Promise((resolve, reject) => {
                client
                  .mutate({
                    mutation: UPDATE_MARKET_PLACE_PICTURE,
                    variables: {
                      file: el.video,
                    },
                  })
                  .then((res) => {
                    console.log("dataRes", res.data.uploadMarketPlacePicture);
                    if (res.data && res.data.uploadMarketPlacePicture) {
                      resolve(res.data.uploadMarketPlacePicture);
                    } else reject("http://test.com");
                  });
              });
            }
            return {
              _id: el._id,
              offer: el.offer,
              gain: el.gain,
              croudFund: el.croudFund,
              title: el.title,
              text: el.text,
              type: el.type,
              image: el.image,
              video: el.video,
            };
          })
        )
      : [];

    const data = {
      title: inputData.title,
      subTitle: inputData.subTitle,
      type: inputData.type,
      content: content,
      offer: inputData.offer,
      gain: inputData.gain,
      croudFund: inputData.croudFund,
      isFirst: inputData.isFirst,
      active: inputData.active,
      canClose: inputData.canClose,
      coverImage: coverImage,
    };

    console.log(data);
    await client
      .mutate({
        mutation: UPDATE_SPONSORISEDPOST,
        variables: {
          id: id,
          input: data,
        },
      })
      .then(async (res) => {
        console.log("res update croud: ", res);
        setReloadCrouds(true);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  }
  async function updateOffer(id, inputData) {
    let urlImages = [];

    if (inputData.images) {
      urlImages = await Promise.all(
        inputData.images
          .map(async (image) => {
            const url = await new Promise((resolve, reject) => {
              client
                .mutate({
                  mutation: UPDATE_MARKET_PLACE_PICTURE,
                  variables: {
                    file: image,
                  },
                })
                .then((res) => {
                  console.log("dataRes", res.data.uploadMarketPlacePicture);
                  if (res.data && res.data.uploadMarketPlacePicture) {
                    resolve(res.data.uploadMarketPlacePicture);
                  } else reject("http://test.com");
                });
            });
            console.log("images: ", url);
            return url;
          })
          .filter((res) => res != null)
      );
    }

    let imgList;
    if (inputData.oldImages) {
      imgList = inputData.oldImages.concat(urlImages);
    } else {
      imgList = [];
    }

    let categoriesList;
    if (typeof inputData.categories === "string") {
      categoriesList = [];
      categoriesList.push(inputData.categories);
    } else {
      const idCategory = inputData.categories[0]._id;
      categoriesList = [];
      categoriesList.push(idCategory);
    }

    const data = {
      name: inputData.name,
      owner: inputData.owner._id,
      country: inputData.country,
      city: inputData.city,
      discount: inputData.discount,
      distanceToShowOffer: parseFloat(inputData.distanceToShowOffer),
      duration: inputData.duration,
      subtitle1: inputData.subtitle1,
      subtitle2: inputData.subtitle2,
      price: parseFloat(inputData.price),
      startDate: inputData.startDate,
      type: inputData.type,
      categories: categoriesList,
      UrlLinkOffer: inputData.UrlLinkOffer,
      description: inputData.description,
      offerDetail: inputData.offerDetail,
      offerInstruction: inputData.offerInstruction,
      images: imgList,
      active: inputData.active,
    };

    await client
      .mutate({
        mutation: UPDATE_OFFER,
        variables: {
          id: id,
          input: data,
        },
      })
      .then(async (res) => {
        console.log("res update offer: ", res);
        setReloadOffers(true);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  }

  async function updateActiveOffre(id, inputData) {
    const data = {
      active: inputData,
    };

    await client
      .mutate({
        mutation: UPDATE_OFFER,
        variables: {
          id: id,
          input: data,
        },
      })
      .then(async (res) => {
        console.log("res update active offer: ", res);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  }
  async function createExerciceTypes(ExerciceType) {
    const createExerciceType = gql`
      mutation createExerciceTypes($input: CreateExerciceTypesInput!) {
        createExerciceTypes(input: $input) {
          _id
          name
          garminName
          polarName
          suuntoName
          fitbitName
          appleName
          isActive
          defaultImage
          calsPerHour
          icon
          isGPS
        }
      }
    `;

    let ExerciceTypeData = ExerciceType;
    console.log(ExerciceTypeData);
    let image =
      typeof ExerciceTypeData.defaultImage !== "string"
        ? await new Promise((resolve, reject) => {
            client
              .mutate({
                mutation: UPDATE_MARKET_PLACE_PICTURE,
                variables: {
                  file: ExerciceTypeData.defaultImage,
                },
              })
              .then((res) => {
                console.log("dataRes", res.data.uploadMarketPlacePicture);
                if (res.data && res.data.uploadMarketPlacePicture) {
                  resolve(res.data.uploadMarketPlacePicture);
                } else reject("http://test.com");
              });
          })
        : ExerciceTypeData.defaultImage;
    let icon =
      typeof ExerciceTypeData.icon !== "string"
        ? await new Promise((resolve, reject) => {
            client
              .mutate({
                mutation: UPDATE_MARKET_PLACE_PICTURE,
                variables: {
                  file: ExerciceTypeData.icon,
                },
              })
              .then((res) => {
                console.log("dataRes", res.data.uploadMarketPlacePicture);
                if (res.data && res.data.uploadMarketPlacePicture) {
                  resolve(res.data.uploadMarketPlacePicture);
                } else reject("http://test.com");
              });
          })
        : ExerciceTypeData.icon;
    const ExerciceTypeItem = {
      id: ExerciceTypeData._id,
      name: ExerciceTypeData.name,
      garminName: ExerciceTypeData.garminName,
      polarName: ExerciceTypeData.polarName,
      suuntoName: ExerciceTypeData.suuntoName,
      fitbitName: ExerciceTypeData.fitbitName,
      appleName: ExerciceTypeData.appleName,
      isActive: ExerciceTypeData.isActive,
      calsPerHour: +ExerciceTypeData.calsPerHour,
      defaultImage: image,
      icon: icon,
      isGPS: ExerciceTypeData.isGPS,
    };

    console.log("ExerciceTypeItem:", ExerciceTypeItem);
    let data;

    await client
      .mutate({
        mutation: createExerciceType,
        variables: {
          input: ExerciceTypeItem,
        },
      })
      .then((res) => {
        data = res.data.ExerciceType;
      });

    return {
      data: { ...data, id: 1 },
    };
  }
  async function updateExerciceTypes(ExerciceType) {
    const updateExerciceType = gql`
      mutation updateExerciceTypes(
        $_id: ID!
        $input: UpdateExerciceTypesInput!
      ) {
        updateExerciceTypes(_id: $_id, input: $input)
      }
    `;

    let ExerciceTypeData = ExerciceType;
    console.log(ExerciceTypeData);
    let image =
      typeof ExerciceTypeData.defaultImage !== "string"
        ? await new Promise((resolve, reject) => {
            client
              .mutate({
                mutation: UPDATE_MARKET_PLACE_PICTURE,
                variables: {
                  file: ExerciceTypeData.defaultImage,
                },
              })
              .then((res) => {
                console.log("dataRes", res.data.uploadMarketPlacePicture);
                if (res.data && res.data.uploadMarketPlacePicture) {
                  resolve(res.data.uploadMarketPlacePicture);
                } else reject("http://test.com");
              });
          })
        : ExerciceTypeData.defaultImage;
    let icon =
      typeof ExerciceTypeData.icon !== "string"
        ? await new Promise((resolve, reject) => {
            client
              .mutate({
                mutation: UPDATE_MARKET_PLACE_PICTURE,
                variables: {
                  file: ExerciceTypeData.icon,
                },
              })
              .then((res) => {
                console.log("dataRes", res.data.uploadMarketPlacePicture);
                if (res.data && res.data.uploadMarketPlacePicture) {
                  resolve(res.data.uploadMarketPlacePicture);
                } else reject("http://test.com");
              });
          })
        : ExerciceTypeData.icon;
    const ExerciceTypeItem = {
      name: ExerciceTypeData.name,
      garminName: ExerciceTypeData.garminName,
      polarName: ExerciceTypeData.polarName,
      suuntoName: ExerciceTypeData.suuntoName,
      fitbitName: ExerciceTypeData.fitbitName,
      appleName: ExerciceTypeData.appleName,
      isActive: ExerciceTypeData.isActive,
      calsPerHour: +ExerciceTypeData.calsPerHour,
      defaultImage: image,
      icon: icon,
      isGPS: ExerciceTypeData.isGPS,
    };

    console.log("ExerciceTypeItem:", ExerciceTypeItem);
    let data;

    await client
      .mutate({
        mutation: updateExerciceType,
        variables: {
          _id: ExerciceTypeData.id,
          input: ExerciceTypeItem,
        },
      })
      .then((res) => {
        data = res.data.ExerciceType;
      });

    return {
      data: { ...data, id: 1 },
    };
  }

  async function createTeam(Team) {
    let urlImage = "";
    urlImage = await new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: UPDATE_MARKET_PLACE_PICTURE,
          variables: {
            file: Team.image,
          },
        })
        .then((res) => {
          console.log("dataRes", res.data.uploadMarketPlacePicture);
          if (res.data && res.data.uploadMarketPlacePicture) {
            resolve(res.data.uploadMarketPlacePicture);
          } else reject("http://test.com");
        });
    });

    const TeamItem = {
      name: Team.name,
      managerId: Team.managerId,
      description: Team.description,
      code: Team.code,
      image: urlImage,
      pro: true,
    };

    let data;
    await client
      .mutate({
        mutation: CREATE_TEAM,
        variables: {
          input: TeamItem,
        },
      })
      .then((res) => {
        data = res.data.createTeam;
      });

    return {
      data: { ...data, id: 1 },
    };
  }
  async function editTeam(Team, id) {
    let urlImage = "";
    urlImage =
      typeof Team.image !== "string"
        ? await new Promise((resolve, reject) => {
            client
              .mutate({
                mutation: UPDATE_MARKET_PLACE_PICTURE,
                variables: {
                  file: Team.image,
                },
              })
              .then((res) => {
                console.log("dataRes", res.data.uploadMarketPlacePicture);
                if (res.data && res.data.uploadMarketPlacePicture) {
                  resolve(res.data.uploadMarketPlacePicture);
                } else reject("http://test.com");
              });
          })
        : Team.image;

    const TeamItem = Object.fromEntries(
      Object.entries({
        name: Team.name,
        managerId: Team.managerId,
        description: Team.description,
        _id: id,
        image: urlImage,
      }).filter(([_, v]) => v.length)
    );

    let data;
    await client
      .mutate({
        mutation: UPDATE_TEAM,
        variables: {
          input: TeamItem,
        },
      })
      .then((res) => {
        data = res.data.updateTeam;
      });

    return {
      data: { ...data, id: 1 },
    };
  }

  async function createTeamGroup(TeamGroup) {
    let urlImage = "";
    urlImage = await new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: UPDATE_MARKET_PLACE_PICTURE,
          variables: {
            file: TeamGroup.image,
          },
        })
        .then((res) => {
          console.log("dataRes", res.data.uploadMarketPlacePicture);
          if (res.data && res.data.uploadMarketPlacePicture) {
            resolve(res.data.uploadMarketPlacePicture);
          } else reject("http://test.com");
        });
    });

    const TeamGroupItem = {
      name: TeamGroup.name,
      managerId: TeamGroup.managerId,
      description: TeamGroup.description,
      team: TeamGroup.team,
      image: urlImage,
    };

    let data;
    await client
      .mutate({
        mutation: CREATE_TEAM_GROUP,
        variables: {
          input: TeamGroupItem,
        },
      })
      .then((res) => {
        data = res.data.createTeamGroup;
      });

    return {
      data: { ...data, id: 1 },
    };
  }
  async function editTeamGroup(TeamGroup, id) {
    let urlImage = "";
    urlImage =
      typeof TeamGroup.image !== "string"
        ? await new Promise((resolve, reject) => {
            client
              .mutate({
                mutation: UPDATE_MARKET_PLACE_PICTURE,
                variables: {
                  file: TeamGroup.image,
                },
              })
              .then((res) => {
                console.log("dataRes", res.data.uploadMarketPlacePicture);
                if (res.data && res.data.uploadMarketPlacePicture) {
                  resolve(res.data.uploadMarketPlacePicture);
                } else reject("http://test.com");
              });
          })
        : TeamGroup.image;

    const TeamGroupItem = Object.fromEntries(
      Object.entries({
        name: TeamGroup.name,
        managerId: TeamGroup.managerId,
        description: TeamGroup.description,
        _id: id,
        image: urlImage,
      }).filter(([_, v]) => v.length)
    );

    let data;
    await client
      .mutate({
        mutation: UPDATE_TEAM_GROUP,
        variables: {
          input: TeamGroupItem,
        },
      })
      .then((res) => {
        data = res.data.updateTeamGroup;
      });

    return {
      data: { ...data, id: 1 },
    };
  }

  async function createEstablishment(Establishment) {
    let urlImage = "";
    urlImage = await new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: UPDATE_MARKET_PLACE_PICTURE,
          variables: {
            file: Establishment.image,
          },
        })
        .then((res) => {
          console.log("dataRes", res.data.uploadMarketPlacePicture);
          if (res.data && res.data.uploadMarketPlacePicture) {
            resolve(res.data.uploadMarketPlacePicture);
          } else reject("http://test.com");
        });
    });
    let urlLogo = "";
    urlLogo = await new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: UPDATE_MARKET_PLACE_PICTURE,
          variables: {
            file: Establishment.logo,
          },
        })
        .then((res) => {
          console.log("dataRes", res.data.uploadMarketPlacePicture);
          if (res.data && res.data.uploadMarketPlacePicture) {
            resolve(res.data.uploadMarketPlacePicture);
          } else reject("http://test.com");
        });
    });
    const dataItem = {
      type: Establishment.type,
      image: urlImage,
      name: Establishment.name,
      address: Establishment.address,
      logo: urlLogo,
      caloriesPerMinute: +Establishment.caloriesPerMinute,
      activityType: Establishment.activityType,
      value: +Establishment.value,
      useGps: Establishment.useGps,
    };

    let data;
    await client
      .mutate({
        mutation: CREATE_ESTABLISHMENT,
        variables: {
          input: dataItem,
        },
      })
      .then((res) => {
        data = res.data.CroudFunded;
      });

    return {
      data: { ...data, id: 1 },
    };
  }
  async function editEstablishment(id, Establishment) {
    let urlImage = "";
    if (typeof Establishment.image === "string") {
      urlImage = Establishment.image;
    } else {
      urlImage = await new Promise((resolve, reject) => {
        client
          .mutate({
            mutation: UPDATE_MARKET_PLACE_PICTURE,
            variables: {
              file: Establishment.image,
            },
          })
          .then((res) => {
            console.log("dataRes", res.data.uploadMarketPlacePicture);
            if (res.data && res.data.uploadMarketPlacePicture) {
              resolve(res.data.uploadMarketPlacePicture);
            } else reject("http://test.com");
          });
      });
    }
    let urlLogo = "";
    if (typeof Establishment.logo === "string") {
      urlLogo = Establishment.logo;
    } else {
      urlLogo = await new Promise((resolve, reject) => {
        client
          .mutate({
            mutation: UPDATE_MARKET_PLACE_PICTURE,
            variables: {
              file: Establishment.logo,
            },
          })
          .then((res) => {
            console.log("dataRes", res.data.uploadMarketPlacePicture);
            if (res.data && res.data.uploadMarketPlacePicture) {
              resolve(res.data.uploadMarketPlacePicture);
            } else reject("http://test.com");
          });
      });
    }
    const dataItem = {
      type: Establishment.type,
      image: urlImage,
      name: Establishment.name,
      address: Establishment.address,
      logo: urlLogo,
      caloriesPerMinute: +Establishment.caloriesPerMinute,
      activityType: Establishment.activityType,
      value: +Establishment.value,
      useGps: Establishment.useGps,
    };

    let data;

    await client
      .mutate({
        mutation: UPDATE_ESTABLISHMENT,
        variables: {
          id: id,
          input: dataItem,
        },
      })
      .then((res) => {
        data = res.data.CroudFunded;
      });

    return {
      data: { ...data, id: 1 },
    };
  }

  /// feed ADs

  async function createFeedAd(feedAd) {
    let urlImage = "";
    urlImage = await new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: UPDATE_MARKET_PLACE_PICTURE,
          variables: {
            file: feedAd.image,
          },
        })
        .then((res) => {
          if (res.data && res.data.uploadMarketPlacePicture) {
            resolve(res.data.uploadMarketPlacePicture);
          } else reject("http://test.com");
        });
    });

    const feedAdItem = {
      link: feedAd.link,
      image: urlImage,
    };

    let data;
    await client
      .mutate({
        mutation: CREATE_FEED_AD,
        variables: {
          input: feedAdItem,
        },
      })
      .then((res) => {
        data = res.data.createFeedAd;
      });

    return {
      data: { ...data, id: 1 },
    };
  }
  async function editFeedAd(feedAd, id) {
    let urlImage = "";
    urlImage =
      typeof feedAd.image !== "string"
        ? await new Promise((resolve, reject) => {
            client
              .mutate({
                mutation: UPDATE_MARKET_PLACE_PICTURE,
                variables: {
                  file: feedAd.image,
                },
              })
              .then((res) => {
                if (res.data && res.data.uploadMarketPlacePicture) {
                  resolve(res.data.uploadMarketPlacePicture);
                } else reject("http://test.com");
              });
          })
        : feedAd.image;

    const feedAdItem = Object.fromEntries(
      Object.entries({
        link: feedAd.link,
        image: urlImage,
      }).filter(([_, v]) => v.length)
    );

    let data;
    await client
      .mutate({
        mutation: UPDATE_FEED_AD,
        variables: {
          id: id,
          input: feedAdItem,
        },
      })
      .then((res) => {
        data = res.data.updateFeedAd;
      });

    return {
      data: { ...data, id: 1 },
    };
  }

  /// jackpot

  async function createJackpot(jackpot) {
    let urlImage = "";
    urlImage = await new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: UPDATE_MARKET_PLACE_PICTURE,
          variables: {
            file: jackpot.image,
          },
        })
        .then((res) => {
          if (res.data && res.data.uploadMarketPlacePicture) {
            resolve(res.data.uploadMarketPlacePicture);
          } else reject("http://test.com");
        });
    });

    const jackpotItem = {
      text: jackpot.text,
      image: urlImage,
    };

    let data;
    await client
      .mutate({
        mutation: CREATE_JACKPOT,
        variables: {
          input: jackpotItem,
        },
      })
      .then((res) => {
        data = res.data.createJackpot;
      });

    return {
      data: { ...data, id: 1 },
    };
  }
  async function editJackpot(jackpot, id) {
    let urlImage = "";
    urlImage =
      typeof jackpot.image !== "string"
        ? await new Promise((resolve, reject) => {
            client
              .mutate({
                mutation: UPDATE_MARKET_PLACE_PICTURE,
                variables: {
                  file: jackpot.image,
                },
              })
              .then((res) => {
                if (res.data && res.data.uploadMarketPlacePicture) {
                  resolve(res.data.uploadMarketPlacePicture);
                } else reject("http://test.com");
              });
          })
        : jackpot.image;

    const jackpotItem = Object.fromEntries(
      Object.entries({
        text: jackpot.text,
        image: urlImage,
      }).filter(([_, v]) => v.length)
    );

    let data;
    await client
      .mutate({
        mutation: UPDATE_JACKPOT,
        variables: {
          id: id,
          input: jackpotItem,
        },
      })
      .then((res) => {
        data = res.data.updateJackpot;
      });

    return {
      data: { ...data, id: 1 },
    };
  }

  async function createBlogSection(BlogSection) {
    let data;
    await client
      .mutate({
        mutation: CREATE_BLOG_SECTION,
        variables: {
          input: BlogSection,
        },
      })
      .then((res) => {
        data = res.data.createTeamGroup;
      });

    return {
      data: { ...data, id: 1 },
    };
  }

  async function createBlogArticle(BlogArticle) {
    let urlImage = "";

    BlogArticle.image &&
      (urlImage = await new Promise((resolve, reject) => {
        client
          .mutate({
            mutation: UPDATE_MARKET_PLACE_PICTURE,
            variables: {
              file: BlogArticle.image,
            },
          })
          .then((res) => {
            console.log("dataRes", res.data.uploadMarketPlacePicture);
            if (res.data && res.data.uploadMarketPlacePicture) {
              resolve(res.data.uploadMarketPlacePicture);
            } else reject("http://test.com");
          });
      }));
    const finalContent = await Promise.all(
      BlogArticle?.content.map(async (el) => {
        let urlImage = "";
        if (el.type !== "IMAGE" && el.type !== "VIDEO") {
          return el;
        }
        urlImage = await new Promise((resolve, reject) => {
          client
            .mutate({
              mutation: UPDATE_MARKET_PLACE_PICTURE,
              variables: {
                file: el.data,
              },
            })
            .then((res) => {
              console.log("dataRes", res.data.uploadMarketPlacePicture);
              if (res.data && res.data.uploadMarketPlacePicture) {
                resolve(res.data.uploadMarketPlacePicture);
              } else reject("http://test.com");
            });
        });
        const content = { ...el, data: urlImage };
        return content;
      })
    );

    const BlogArticleItem = {
      title: BlogArticle.title,
      smallTitle: BlogArticle.smallTitle,
      smallDescription: BlogArticle.smallDescription,
      description: BlogArticle.description,
      show: true,
      image: urlImage,
      content: finalContent,
      categories: BlogArticle.categories,
    };

    let data;
    await client
      .mutate({
        mutation: CREATE_BLOG_ARTICLE,
        variables: {
          input: BlogArticleItem,
        },
      })
      .then((res) => {
        data = res.data.createBlogArticle;
      });

    return {
      data: { ...data, id: 1 },
    };
  }
  async function createBlogCategory(BlogCategory) {
    let urlImage = "";
    BlogCategory.image &&
      (urlImage = await new Promise((resolve, reject) => {
        client
          .mutate({
            mutation: UPDATE_MARKET_PLACE_PICTURE,
            variables: {
              file: BlogCategory.image,
            },
          })
          .then((res) => {
            if (res.data && res.data.uploadMarketPlacePicture) {
              resolve(res.data.uploadMarketPlacePicture);
            } else reject("http://test.com");
          });
      }));

    const BlogCategoryItem = {
      image: urlImage,
      name: BlogCategory.name,
      description: BlogCategory.description,
      articles: BlogCategory.articles,
      show: BlogCategory.show,
    };

    let data;
    await client
      .mutate({
        mutation: CREATE_BLOG_CATEGORY,
        variables: {
          input: BlogCategoryItem,
        },
      })
      .then((res) => {
        data = res.data.createBlogCategory;
      });

    return {
      data: { ...data, id: 1 },
    };
  }
  async function editBlogCategory(BlogCategory, id) {
    let urlImage = "";
    BlogCategory.image &&
      (urlImage =
        typeof BlogCategory.image !== "string"
          ? await new Promise((resolve, reject) => {
              client
                .mutate({
                  mutation: UPDATE_MARKET_PLACE_PICTURE,
                  variables: {
                    file: BlogCategory.image,
                  },
                })
                .then((res) => {
                  if (res.data && res.data.uploadMarketPlacePicture) {
                    resolve(res.data.uploadMarketPlacePicture);
                  } else reject("http://test.com");
                });
            })
          : BlogCategory.image);

    const BlogCategoryItem = Object.fromEntries(
      Object.entries({
        _id: id,
        image: urlImage,
        name: BlogCategory.name,
        description: BlogCategory.description,
        articles: BlogCategory.articles,
        show: BlogCategory.show,
      }).filter(([_, v]) => v.length)
    );

    let data;
    await client
      .mutate({
        mutation: UPDATE_BLOG_CATEGORY,
        variables: {
          input: BlogCategoryItem,
        },
      })
      .then((res) => {
        data = res.data.updateBlogCategory;
      });

    return {
      data: { ...data, id: 1 },
    };
  }
  async function editBlogSection(BlogSection, id) {
    const BlogSectionItem = {
      _id: id,
      displayIndex: BlogSection.displayIndex,
      name: BlogSection.name,
      type: BlogSection.type,
      categories: BlogSection.categories,
      articles: BlogSection.articles,
      show: BlogSection.show,
      attributionType: BlogSection.attributionType,
      teams: BlogSection.teams,
      teamgroups: BlogSection.teamgroups,
    };

    let data;
    await client
      .mutate({
        mutation: UPDATE_BLOG_SECTION,
        variables: {
          input: BlogSectionItem,
        },
      })
      .then((res) => {
        data = res.data.updateBlogSection;
      });

    return {
      data: { ...data, id: 1 },
    };
  }

  async function editBlogArticle(BlogArticle, id) {
    let urlImage = "";
    BlogArticle.image &&
      (urlImage =
        typeof BlogArticle.image !== "string"
          ? await new Promise((resolve, reject) => {
              client
                .mutate({
                  mutation: UPDATE_MARKET_PLACE_PICTURE,
                  variables: {
                    file: BlogArticle.image,
                  },
                })
                .then((res) => {
                  console.log("dataRes", res.data.uploadMarketPlacePicture);
                  if (res.data && res.data.uploadMarketPlacePicture) {
                    resolve(res.data.uploadMarketPlacePicture);
                  } else reject("http://test.com");
                });
            })
          : BlogArticle.image);

    const finalContent = await Promise.all(
      BlogArticle?.content.map(async (el) => {
        let urlImage = "";
        if (el.type !== "IMAGE") {
          const content = Object.fromEntries(
            Object.entries({
              title: el.title,
              type: el.type,
              data: el.data,
            }).filter(([_, v]) => v.length)
          );
          return content;
        }
        if (el.type === "IMAGE" && typeof el.data === "string") {
          const content = Object.fromEntries(
            Object.entries({
              title: el.title,
              type: el.type,
              data: el.data,
            }).filter(([_, v]) => v.length)
          );
          return content;
        }
        urlImage = await new Promise((resolve, reject) => {
          client
            .mutate({
              mutation: UPDATE_MARKET_PLACE_PICTURE,
              variables: {
                file: el.data,
              },
            })
            .then((res) => {
              console.log("dataRes", res.data.uploadMarketPlacePicture);
              if (res.data && res.data.uploadMarketPlacePicture) {
                resolve(res.data.uploadMarketPlacePicture);
              } else reject("http://test.com");
            });
        });

        const content = { ...el, data: urlImage };
        return content;
      })
    );

    const BlogArticleItem = {
      _id: id,
      title: BlogArticle.title,
      smallTitle: BlogArticle.smallTitle,
      smallDescription: BlogArticle.smallDescription,
      description: BlogArticle.description,
      show: BlogArticle.show,
      image: urlImage,
      content: finalContent,
      categories: BlogArticle.categories,
    };

    let data;
    await client
      .mutate({
        mutation: UPDATE_BLOG_ARTICLE,
        variables: {
          input: BlogArticleItem,
        },
      })
      .then((res) => {
        data = res.data.updateBlogArticle;
      });

    return {
      data: { ...data, id: 1 },
    };
  }
  return (
    <UserContext.Provider
      value={{
        activeUser,
        setActiveUser,
        createCodes,
        updateCodes,
        updateCroudFunded,
        editTeam,
        updateOffer,
        reloadOffers,
        createTeam,
        updateGain,
        setReloadOffers,
        createExerciceTypes,
        updateExerciceTypes,
        reloadCrouds,
        setReloadCrouds,
        updateActiveOffre,
        updateSponsorisedPost,
        setAddress,
        address,
        createEstablishment,
        editEstablishment,
        createFeedAd,
        editFeedAd,
        createJackpot,
        editJackpot,
        createTeamGroup,
        editTeamGroup,
        createBlogSection,
        createBlogArticle,
        createBlogCategory,
        editBlogCategory,
        editBlogSection,
        editBlogArticle,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { UserContextProvider, UserContext };
