import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  Show,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
  SimpleForm,
  SimpleShowLayout,
  RichTextField,
  SelectInput,
  ReferenceInput,
  useNotify,
  useRefresh,
  SimpleFormIterator,
  ArrayInput,
  useRedirect,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({}));

const CategoriesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const CategoriesList = (props) => (
  <List
    //filters={<CategoriesFilter />}
    {...props}
    title="List des categories"
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="type" />
      <TextField source="description" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

const CategoryTitle = ({ record, setCatType }) => {
  if (record && record.type) setCatType(record.type);
  return <span>Category {record ? `"${record.name}"` : ""}</span>;
};

export const CategoriesEdit = (props) => {
  const [catType, setCatType] = React.useState("OFFER");
  const handleChange = (event) => {
    const value = event.target.value;
    setCatType(value);
  };
  React.useEffect(() => {}, []);
  return (
    <Edit
      title={<CategoryTitle setCatType={(e) => setCatType(e)} />}
      {...props}
    >
      <SimpleForm>
        <TextInput source="name" />
        {/*<TextInput source="nameOffer" disabled />*/}

        <SelectInput
          source="type"
          choices={[
            { id: "OFFER", name: "offer category" },
            { id: "CROUDFUND", name: "croudfund category" },
            { id: "GAIN", name: "gain category" },
          ]}
          name="type"
          label="Category Type"
          onChange={handleChange}
        />
        {catType === "OFFER" ? (
          <ArrayInput label="Samples" source="samples">
            <SimpleFormIterator>
              <ReferenceInput reference="offers">
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        ) : catType === "CROUDFUND" ? (
          <ArrayInput label="Samples" source="samples">
            <SimpleFormIterator>
              <ReferenceInput reference="croudFundeds">
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        ) : (
          <ArrayInput label="Samples" source="samples">
            <SimpleFormIterator>
              <ReferenceInput reference="gains">
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        )}
        <TextInput source="description" />
      </SimpleForm>
    </Edit>
  );
};

export const CategoriesShow = (props) => (
  <Show title={<CategoryTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <RichTextField source="description" />
    </SimpleShowLayout>
  </Show>
);

export const CategoriesCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`Category created`);
    redirect("/categories");
    refresh();
  };
  const [catType, setCatType] = React.useState("OFFER");
  const handleChange = (event) => {
    const value = event.target.value;
    setCatType(value === "OFFER");
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <TextInput source="name" />
        {/*<TextInput source="nameOffer" disabled />*/}

        <SelectInput
          source="type"
          choices={[
            { id: "OFFER", name: "offer category" },
            { id: "CROUDFUND", name: "croudfund category" },
            { id: "GAIN", name: "gain category" },
          ]}
          name="type"
          label="Category Type"
          onChange={handleChange}
        />
        {catType === "OFFER" ? (
          <ArrayInput label="Samples" source="samples">
            <SimpleFormIterator>
              <ReferenceInput reference="offers">
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        ) : catType === "CROUDFUND" ? (
          <ArrayInput label="Samples" source="samples">
            <SimpleFormIterator>
              <ReferenceInput reference="croudFundeds">
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        ) : (
          <ArrayInput label="Samples" source="samples">
            <SimpleFormIterator>
              <ReferenceInput reference="gains">
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        )}
        <TextInput multiline source="description" />
      </SimpleForm>
    </Create>
  );
};
