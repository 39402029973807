import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

const SponsorisedPostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const SponsorisedPostList = (props) => {
  const classes = useStyles();

  return (
    <List
      //filters={<SponsorisedPostFilter />}
      {...props}
      title="List des SponsorisedPost"
    >
      <Datagrid>
        <TextField source="title" />
        <TextField source="subTitle" />
        <TextField source="type" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
