import React, { useState, useEffect, useContext } from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  ImageInput,
  ImageField,
  Toolbar,
  SaveButton,
  useNotify,
  LinearProgress,
  useRefresh,
  useRedirect,
  FileInput,
  required,
  useGetList,
} from "react-admin";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { UserContext } from "../../context/UserContext";
import Switch from "@material-ui/core/Switch";
const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function BlogArticleEdit(props) {
  const history = useHistory();
  const { classes } = props;
  const [loader, setLoader] = React.useState(false);
  const [BlogArticle, setBlogArticle] = React.useState({
    image: "",
    title: "",
    smallTitle: "",
    smallDescription: "",
    description: "",
    show: true,
    categories: [],
  });
  const [content, setContent] = React.useState([]);
  const { editBlogArticle } = useContext(UserContext);

  const handleChangeImage = (e) => {
    const value = e;
    const key = "image";

    setBlogArticle((previousState) => {
      return { ...previousState, [key]: value };
    });
  };
  const handleChange = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    setBlogArticle((previousState) => {
      return { ...previousState, [key]: value };
    });
  };

  const handleContentChange = (idx, evt) => {
    const key = evt.target.name;
    const value = evt.target.value;
    const newContent = content.map((content, sidx) => {
      if (idx !== sidx) return content;
      return { ...content, [key]: value };
    });
    setContent(newContent);
  };
  const handleContentImageChange = (idx, evt) => {
    const value = evt.target.files[0];
    const newContent = content.map((content, sidx) => {
      if (idx !== sidx) return content;
      return { ...content, data: value };
    });
    setContent(newContent);
  };
  const setShow = (event) => {
    const value = event.target.checked;
    setBlogArticle((previousState) => {
      return { ...previousState, show: value };
    });
  };
  const handleAddContent = (evt) => {
    let value = evt.target.value;
    if (!value) value = content.at(-1)?.type || "TEXT";
    setContent(content.concat([{ title: "", type: value, data: "" }]));
  };
  const handleRemoveContent = (idx) => {
    setContent(content.filter((s, sidx) => idx !== sidx));
  };
  const { data, loaded } = useGetList("BlogArticles");
  const { data: categoriesData, loaded: categoriesLoaded } =
    useGetList("BlogCategories");
  const categoriesDatafiltered = Object.values(categoriesData);
  const getUrlFromPath = () => {
    const paths = window.location.href.split("/");
    let url = paths[5];

    return url;
  };
  useEffect(() => {
    if (data) {
      const item = data[getUrlFromPath()];
      setBlogArticle({
        image: item?.image,
        title: item?.title,
        smallTitle: item?.smallTitle,
        smallDescription: item?.smallDescription,
        description: item?.description,
        show: item?.show,
        categories: item?.categories,
      });
      setContent(item?.content);
    }
  }, [data]);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`BlogArticle Editd`);
    redirect("/BlogArticles");
    refresh();
  };
  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };

  async function handleSave() {
    console.log("update:", BlogArticle);
    const data = { ...BlogArticle, content: content };
    await editBlogArticle(data, getUrlFromPath());
    history.push("/BlogArticles");
    setLoader(!loader);
    setTimeout(() => {}, 3000);
  }
  const { show, categories } = BlogArticle;

  return (
    <Edit onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="title"
              label="title"
              multiline
              className={classes.formInput}
              onChange={handleChange}
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              name="smallTitle"
              label="smallTitle"
              multiline
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="description"
              label="description"
              multiline
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              name="smallDescription"
              label="smallDescription"
              multiline
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid container direction="row" style={{ width: "100%" }}>
            <ImageField source="image" label="old image" title="title" />
            <ImageInput
              name="image"
              label="image"
              accept="image/*"
              onChange={handleChangeImage}
            >
              <ImageField name="image" title="title" />
            </ImageInput>
          </Grid>
          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item xs={1}>
              <span>Show</span>
            </Grid>
            <Grid item xs={4}>
              <Switch
                checked={show}
                color="primary"
                name="show"
                source="show"
                label="show"
                inputProps={{ "aria-label": "primary checkbox" }}
                onChange={setShow}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="categories-multiple-name-label">
                  Associated Categories
                </InputLabel>
                <Select
                  labelId="categories-multiple-name-label"
                  id="categories-multiple-name"
                  name="categories"
                  multiple
                  value={categories}
                  onChange={handleChange}
                  input={<OutlinedInput label="Associated Categories" />}
                >
                  {categoriesDatafiltered?.map((item) => (
                    <MenuItem key={item.name} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <h5>Content</h5>
          <Grid container direction="row" style={{ width: "100%" }}>
            {content?.map((content, idx) => (
              <Grid
                container
                direction="row"
                style={{ width: "100%", marginBottom: 20 }}
              >
                {/* <h1>{content.type}</h1> */}
                <Grid item xs={3}>
                  <TextField
                    label="title"
                    name="title"
                    multiline
                    variant="filled"
                    fullWidth
                    onChange={(e) => {
                      handleContentChange(idx, e);
                    }}
                    value={content.title}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  {content.type !== "IMAGE" && content.type !== "VIDEO" ? (
                    <TextField
                      label="data"
                      name="data"
                      variant="filled"
                      multiline={content.type === "TEXT" ? true : false}
                      fullWidth
                      onChange={(e) => {
                        handleContentChange(idx, e);
                      }}
                      value={content.data}
                    />
                  ) : (
                    <>
                      <input
                        name="data"
                        label="data"
                        type="file"
                        accept={
                          content.type === "IMAGE" ? "image/*" : "video/mp4"
                        }
                        onChange={(e) => {
                          handleContentImageChange(idx, e);
                        }}
                      />
                      {content.data && (
                        <div>
                          {content.type === "IMAGE" ? (
                            <img
                              src={
                                typeof content.data === "string"
                                  ? content.data
                                  : URL.createObjectURL(content.data)
                              }
                              title={content.title}
                              style={{ height: 150, width: 150 }}
                            />
                          ) : (
                            <video controls title={content.title}>
                              <source
                                style={{ height: 100, width: 100 }}
                                src={
                                  typeof content.data === "string"
                                    ? content.data
                                    : URL.createObjectURL(content.data)
                                }
                              />
                            </video>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                  <TextField
                    disabled
                    variant="filled"
                    fullWidth
                    value={content.type}
                  />
                </Grid>

                <Button
                  variant="contained"
                  onClick={() => handleRemoveContent(idx)}
                  style={{ height: 25, minWidth: 25, marginLeft: 25 }}
                >
                  -
                </Button>
              </Grid>
            ))}

            <FormControl variant="filled" style={{ width: "30%" }}>
              <InputLabel id="type-select">Type</InputLabel>
              <Select
                labelId="type-select"
                id="select-filled"
                onClick={(e) => {
                  handleAddContent(e);
                }}
              >
                <MenuItem value="TEXT">TEXT</MenuItem>
                <MenuItem value="LINK">LINK</MenuItem>
                <MenuItem value="VIDEO">VIDEO</MenuItem>
                <MenuItem value="VIMEO">VIMEO</MenuItem>
                <MenuItem value="IMAGE">IMAGE</MenuItem>
                <MenuItem value="AUDIO">AUDIO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Edit>
  );
}

export default withStyles(styles)(BlogArticleEdit);
