import React from "react";
import { 
    Show, 
    SimpleShowLayout,
    TextField,
    RichTextField, 
    ImageField,
    useGetOne
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  
});

function OffersShow(props) {
    const { classes } = props;

    const getUrlFromPath = () => {
        const paths = window.location.href.split("/");
        let url = paths[5];
        
        return url;
      };
    
    const { data, loaded } = useGetOne('offers', getUrlFromPath());

    if (!loaded) return <span>Loading</span>;

    const ImagesListField = ({ record }) => {
        return <div>
          <Typography variant="h6" color="grey"> Images : </Typography>
          {record.images.map(item => (
            <div>
              <img src={item} alt="image" style={{ height: 100, width: 200}}/>
              <br/> <br/>
            </div>
          ))}
        </div>
    };

    const OfferTitle = ({ record }) => {
        return <span>Offer {record ? `"${record.name}"` : ''}</span>;
    };

  return (
    <Show title={<OfferTitle />} {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="owner.name" />
            <TextField source="city" />
            <TextField source="country" />
            <TextField source="discount" />
            <TextField source="discountCode" />
            <TextField source="distanceToShowOffer" />
            <TextField source="duration" />
            <TextField source="subtitle1" />
            <TextField source="subtitle2" />
            <TextField source="price" />
            <TextField source="startDate" />
            <TextField source="type" />
            <TextField source="categories" />
            <TextField source="UrlLinkOffer" />
            <RichTextField source="description" />
            <TextField source="offerDetail" />
            <TextField source="offerInstruction" />
            <ImagesListField record={data} source="images" />
        </SimpleShowLayout>
    </Show>
  );
}

export default withStyles(styles)(OffersShow);
