import React, { useState, useEffect, useContext } from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  RadioButtonGroupInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  Toolbar,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  useNotify,
  LinearProgress,
  useRefresh,
  useRedirect,
  SelectArrayInput,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { UserContext } from "../../context/UserContext";
import Switch from "@material-ui/core/Switch";
const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function BlogCategoryCreate(props) {
  const history = useHistory();
  const { classes } = props;
  const [loader, setLoader] = React.useState(false);
  const [BlogCategory, setBlogCategory] = React.useState({
    name: "",
    description: "",
    image: "",
    articles: "",
    show: true,
  });
  const { createBlogCategory } = useContext(UserContext);
  const handleChange = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    setBlogCategory((previousState) => {
      return { ...previousState, [key]: value };
    });
  };
  const setShow = (event) => {
    const value = event.target.checked;
    setBlogCategory((previousState) => {
      return { ...previousState, show: value };
    });
  };
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleChangeImage = (e) => {
    const value = e;
    const key = "image";
    setBlogCategory((previousState) => {
      return { ...previousState, [key]: value };
    });
  };

  const onSuccess = () => {
    notify(`BlogCategory created`);
    redirect("/BlogCategories");
    refresh();
  };
  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };

  async function handleSave() {
    console.log("update:", BlogCategory);
    await createBlogCategory(BlogCategory);
    history.push("/BlogCategories");
    setLoader(!loader);
    setTimeout(() => {}, 3000);
  }
  const { show } = BlogCategory;

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="name"
              label="name"
              multiline
              className={classes.formInput}
              onChange={handleChange}
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              name="description"
              label="description"
              multiline
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid container direction="row" style={{ width: "100%" }}>
            <ImageInput
              name="image"
              label="image"
              accept="image/*"
              onChange={handleChangeImage}
            >
              <ImageField name="image" title="title" />
            </ImageInput>
          </Grid>

          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item xs={5}>
              <ReferenceArrayInput
                label="articles"
                source="articles"
                reference="BlogArticles"
                className={classes.formInput}
                onChange={handleChange}
              >
                <SelectArrayInput optionText="title" />
              </ReferenceArrayInput>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={1}>
            <span>Show</span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={show}
              color="primary"
              name="show"
              source="show"
              label="show"
              inputProps={{ "aria-label": "primary checkbox" }}
              onChange={setShow}
            />
          </Grid>
        </Grid>

        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(BlogCategoryCreate);
