import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  
}));

const CroudFundedsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const CroudFundedsList = (props) => {
  const classes = useStyles();

  return (
    <List 
      //filters={<CroudFundedsFilter />} 
      {...props} title="List des Croud Fundeds">
      <Datagrid>
        <TextField source="name" />
        <TextField source="subtitle1" />
        <TextField source="subtitle2" />
        <TextField source="projectDescription" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
