import * as React from "react";
import "./App.css";
import "./assets/css/Style.css";
import { Component } from "react";
import buildGraphQLProvider from "ra-data-graphql";
import { Admin, Resource } from "react-admin";
import {
  CategoriesList,
  CategoriesEdit,
  CategoriesCreate,
  CategoriesShow,
} from "./components/categories/Categories";
import {
  PartnersList,
  PartnersEdit,
  PartnersShow,
  PartnersCreate,
} from "./components/partners/Partners";
import {
  DiscountCodesList,
  DiscountCodesShow,
} from "./components/discountCodes/DiscountCodes";

import {
  EstablishmentsList,
  EstablishmentsShow,
} from "./components/establishments/Establishments";

import EstablishmentsEdit from "./components/establishments/EstablishmentsEdit";
import EstablishmentsCreate from "./components/establishments/EstablishmentsCreate";
import DiscountCodesCreate from "./components/discountCodes/DiscountCodesCreate";
import DiscountCodesEdit from "./components/discountCodes/DiscountCodesEdit";
import { UsersList, UserShow } from "./components/users/Users";
import { CroudFundedsList } from "./components/croudFundeds/CroudFundeds";
import CroundFundedsEdit from "./components/croudFundeds/CroundFundedsEdit";
import CroudFundedsCreate from "./components/croudFundeds/CroudFundedsCreate";
import CroudFundedsShow from "./components/croudFundeds/CroudFundedsShow";

import { GainsList } from "./components/gains/Gains";
import GainsEdit from "./components/gains/GainsEdit";
import GainsCreate from "./components/gains/GainsCreate";
import GainsShow from "./components/gains/GainsShow";
import { OffersList } from "./components/offers/Offers";
import OffersEdit from "./components/offers/OffersEdit";
import OffersCreate from "./components/offers/OffersCreate";
import OffersShow from "./components/offers/OffersShow";

import { JackpotList, JackpotShow } from "./components/jackpot/Jackpot";
import JackpotEdit from "./components/jackpot/JackpotEdit";
import JackpotCreate from "./components/jackpot/JackpotCreate";

import { SponsorisedPostList } from "./components/sponsorisedPost/SponsorisedPost";
import SponsorisedPostEdit from "./components/sponsorisedPost/SponsorisedPostEdit";
import SponsorisedPostCreate from "./components/sponsorisedPost/SponsorisedPostCreate";
import SponsorisedPostShow from "./components/sponsorisedPost/SponsorisedPostShow";
//import Dashboard from "./components/Dashboard";
import Dashboard from "./components/analytics";
import authProvider from "./components/AuthProvider";
import UserIcon from "@material-ui/icons/Group";
import { theme } from "./components/theme";
import client from "./apollo/client";
import gql from "graphql-tag";
import { Route } from "react-router";
import ProfileEdit from "./components/profile/ProfileEdit";
import ProfileShow from "./components/profile/ProfileShow";
import MyLayout from "./components/layout/MyLayout";
import { UPDATE_MARKET_PLACE_PICTURE } from "./graphql/user";
import CategoryIcon from "@material-ui/icons/Category";
import {
  HomeOutlined,
  MonetizationOn,
  Accessibility,
} from "@material-ui/icons";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import AttachMoney from "@material-ui/icons/AttachMoney";

import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { TeamsList, TeamsShow } from "./components/teams/Teams";
import TeamsEdit from "./components/teams/TeamsEdit";
import TeamsCreate from "./components/teams/TeamsCreate";
import {
  ExerciceTypesList,
  ExerciceTypesShow,
} from "./components/exerciceTypes/ExerciceTypes";
import ExerciceTypesEdit from "./components/exerciceTypes/ExerciceTypesEdit";
import ExerciceTypesCreate from "./components/exerciceTypes/ExerciceTypesCreate";

// feeds components
import { FeedAdsList, FeedAdShow } from "./components/feedAds/FeedAds";
import FeedAdCreate from "./components/feedAds/FeedAdCreate";
import FeedAdEdit from "./components/feedAds/FeedAdEdit";
import AutoAwesomeMotionIcon from "@material-ui/icons/BurstMode";
import {
  TeamGroupShow,
  TeamsGroupsList,
} from "./components/teamsGroups/TeamsGroups";
import TeamGroupsEdit from "./components/teamsGroups/TeamGroupsEdit";
import TeamGroupsCreate from "./components/teamsGroups/TeamGroupsCreate";
import {
  BlogSectionsList,
  BlogSectionsShow,
} from "./components/blogSections/BlogSections";
import BlogSectionCreate from "./components/blogSections/BlogSectionCreate";
import {
  BlogCategoriesList,
  BlogCategoriesShow,
} from "./components/blogCategories/BlogCategories";
import BlogArticleCreate from "./components/blogArticles/BlogArticleCreate";
import {
  BlogArticlesList,
  BlogArticlesShow,
} from "./components/blogArticles/BlogArticles";
import BlogCategoryCreate from "./components/blogCategories/BlogCategoryCreate";
import BlogCategorysEdit from "./components/blogCategories/BlogCategoryEdit";
import BlogSectionEdit from "./components/blogSections/BlogSectionEdit";
import BlogArticleEdit from "./components/blogArticles/BlogArticleEdit";
const buildQuery =
  (introspectionResults) => (raFetchType, resourceName, params) => {
    console.log("type:", raFetchType, "resource:", resourceName);
    console.log("params:", params);
    if (resourceName === "categories") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getSwCategories {
                getSwCategories {
                  _id
                  name
                  type
                  samples
                  description
                }
              }
            `,
            parseResponse: (response) => {
              console.log("res:", response);
              return {
                data: response.data.getSwCategories.map((category, i) => {
                  return {
                    index: i,
                    id: category._id,
                    name: category.name,
                    description: category.description,
                    type: category.type,
                    samples: category.samples,
                    //idOffer: category.offer._id,
                    //nameOffer: category.offer.name,
                    //priceOffer: category.offer.price,
                  };
                }),
                total: response.data.getSwCategories.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getSwCategoryById($id: ID!) {
                getSwCategoryById(_id: $id) {
                  _id
                  name
                  type
                  description
                }
              }
            `,
            parseResponse: (response) => {
              let data = response.data.SwCategory;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getSwCategories {
                getSwCategories {
                  _id
                  name
                  type
                  description
                }
              }
            `,
            parseResponse: (response) => {
              console.log("res:", response);
              return {
                data: response.data.getSwCategories.map((category, i) => {
                  return {
                    index: i,
                    id: category._id,
                    name: category.name,
                    description: category.description,
                    type: category.type,
                    sample: category.sample,
                    /*idOffer: category.offer._id,
                      nameOffer: category.offer.name,
                      priceOffer: category.offer.price,*/
                  };
                }),
                total: response.data.getSwCategories.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "CREATE":
          let categoryData = params.data;
          const data = {
            name: categoryData.name,
            description: categoryData.description,
            type: categoryData.type,
            samples: categoryData.samples,
          };
          console.log(data);
          return {
            query: gql`
              mutation createSwCategory($input: CreateSwCategoryInput!) {
                createSwCategory(input: $input) {
                  _id
                  name
                  type
                  description
                }
              }
            `,
            parseResponse: (response) => {
              let data = response.data.SwCategory;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { input: data },
            parseError: (error) => console.log(error),
          };
        case "UPDATE":
          let categData = params.data;
          const inputData = {
            name: categData.name,
            description: categData.description,
            type: categData.type,

            samples: categData.samples,
          };
          return {
            query: gql`
              mutation updateSwCategory(
                $id: ID!
                $input: UpdateSwCategoryInput!
              ) {
                updateSwCategory(_id: $id, input: $input) {
                  _id
                  name
                  type
                  description
                }
              }
            `,
            parseResponse: (response) => {
              return {
                data: { id: "", result: "updated" },
              };
            },
            variables: { id: params.id, input: inputData },
            parseError: (error) => console.log(error),
          };
        case "DELETE":
          return {
            query: gql`
              mutation deleteSwCategory($id: ID!) {
                deleteSwCategory(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };
        case "DELETE_MANY":
          return {
            query: gql`
              mutation deleteSwCategories($ids: [ID]!) {
                deleteSwCategories(_ids: $ids)
              }
            `,
            parseResponse: (response) => {
              return {
                data: [{ id: "", result: "deleted" }],
              };
            },
            variables: { ids: params.ids },
            parseError: (error) => console.log(error),
          };
        default:
          break;
      }
    } else if (resourceName === "partners") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getSwPartners {
                getSwPartners {
                  _id
                  name
                  logo
                  picture
                  adresseLine
                  iconPicto
                  croudFunds {
                    _id
                    name
                    images
                  }
                  offers {
                    _id
                    name
                  }
                  discountCodes {
                    _id
                    code
                    offer {
                      _id
                      name
                    }
                    partner {
                      _id
                      name
                    }
                  }
                  zipCode
                  country
                  city
                  catchementCity
                  catchementCountry
                }
              }
            `,
            parseResponse: (response) => {
              console.log("res:", response);
              return {
                data: response.data.getSwPartners.map((partner, i) => {
                  return {
                    index: i,
                    id: partner._id,
                    name: partner.name,
                    logo: partner.logo,
                    picture: partner.picture,
                    adresseLine: partner.adresseLine,
                    iconPicto: partner.iconPicto,
                    /*croudFundsId: partner.croudFunds._id,
                    croudFundsName: partner.croudFunds.name,
                    croudFundsImages: partner.croudFunds.images,
                    offersId: partner.offers._id,
                    offersName: partner.offers.name,
                    discountCodesId: partner.discountCodes._id,
                    discountCodesCode: partner.discountCodes.code,
                    discountCodesOfferId: partner.discountCodes.offer._id,
                    discountCodesOfferName: partner.discountCodes.offer.name,
                    discountCodesPartnerId: partner.discountCodes.partner._id,
                    discountCodesPartnerName: partner.discountCodes.partner.name,*/
                    zipCode: partner.zipCode,
                    country: partner.country,
                    city: partner.city,
                    catchementCity: partner.catchementCity,
                    catchementCountry: partner.catchementCountry,
                  };
                }),
                total: response.data.getSwPartners.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getSwPartnerById($id: String!) {
                getSwPartnerById(_id: $id) {
                  _id
                  name
                  logo
                  picture
                  adresseLine
                  iconPicto
                  zipCode
                  country
                  city
                }
              }
            `,
            parseResponse: (response) => {
              let data = response.data.SwPartner;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getSwPartners {
                getSwPartners {
                  _id
                  name
                  logo
                  picture
                  adresseLine
                  iconPicto
                  croudFunds {
                    _id
                    name
                    images
                  }
                  offers {
                    _id
                    name
                  }
                  discountCodes {
                    _id
                    code
                    offer {
                      _id
                      name
                    }
                    partner {
                      _id
                      name
                    }
                  }
                  zipCode
                  country
                  city
                  catchementCity
                  catchementCountry
                }
              }
            `,
            parseResponse: (response) => {
              console.log("res:", response);
              return {
                data: response.data.getSwPartners.map((partner, i) => {
                  return {
                    index: i,
                    id: partner._id,
                    name: partner.name,
                    logo: partner.logo,
                    picture: partner.picture,
                    adresseLine: partner.adresseLine,
                    iconPicto: partner.iconPicto,
                    /*croudFundsId: partner.croudFunds._id,
                      croudFundsName: partner.croudFunds.name,
                      croudFundsImages: partner.croudFunds.images,
                      offersId: partner.offers._id,
                      offersName: partner.offers.name,
                      discountCodesId: partner.discountCodes._id,
                      discountCodesCode: partner.discountCodes.code,
                      discountCodesOfferId: partner.discountCodes.offer._id,
                      discountCodesOfferName: partner.discountCodes.offer.name,
                      discountCodesPartnerId: partner.discountCodes.partner._id,
                      discountCodesPartnerName: partner.discountCodes.partner.name,*/
                    zipCode: partner.zipCode,
                    country: partner.country,
                    city: partner.city,
                    catchementCity: partner.catchementCity,
                    catchementCountry: partner.catchementCountry,
                  };
                }),
                total: response.data.getSwPartners.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "CREATE":
          return {
            query: gql`
              query me {
                me {
                  _id
                }
              }
            `,

            parseResponse: async (response) => {
              const createSWPartner = gql`
                mutation createSwPartner($input: CreatePartnerInput!) {
                  createSwPartner(input: $input) {
                    _id
                    name
                    logo
                    picture
                    adresseLine
                    iconPicto
                    zipCode
                    country
                    city
                  }
                }
              `;
              let partnerInputData = params.data;

              const urlLogoImage = await new Promise((resolve, reject) => {
                client
                  .mutate({
                    mutation: UPDATE_MARKET_PLACE_PICTURE,
                    variables: {
                      file: partnerInputData.logo.rawFile,
                    },
                  })
                  .then((res) => {
                    console.log("dataRes", res.data.uploadMarketPlacePicture);
                    if (res.data && res.data.uploadMarketPlacePicture) {
                      resolve(res.data.uploadMarketPlacePicture);
                    } else reject("http://test.com");
                  });
              });

              const partnerDataItem = {
                logo: urlLogoImage,
                iconPicto: urlLogoImage,
                picture: urlLogoImage,
                name: partnerInputData.name,
                description: partnerInputData.description,
                adresseLine: partnerInputData.adresseLine,
                zipCode: partnerInputData.zipCode,
                country: partnerInputData.country,
                city: partnerInputData.city,
              };

              console.log("partnerDataItem:", partnerDataItem);
              let data;

              await client
                .mutate({
                  mutation: createSWPartner,
                  variables: {
                    input: partnerDataItem,
                  },
                })
                .then((res) => {
                  data = res.data.SwPartner;
                });

              return {
                data: { ...data, id: 1 },
              };
            },
            parseError: (error) => console.log(error),
          };
        case "UPDATE":
          return {
            query: gql`
              query me {
                me {
                  _id
                }
              }
            `,

            parseResponse: async (response) => {
              const updateSWPartner = gql`
                mutation updateSwPartner($id: ID!, $input: UpdatePartnerInput) {
                  updateSwPartner(_id: $id, input: $input) {
                    _id
                    name
                  }
                }
              `;
              let partnerData = params.data;
              let image;

              const urlPicImage = await new Promise((resolve, reject) => {
                client
                  .mutate({
                    mutation: UPDATE_MARKET_PLACE_PICTURE,
                    variables: {
                      file: partnerData.logo.rawFile,
                    },
                  })
                  .then((res) => {
                    console.log("dataRes", res.data.uploadMarketPlacePicture);
                    if (res.data && res.data.uploadMarketPlacePicture) {
                      resolve(res.data.uploadMarketPlacePicture);
                    } else reject("http://test.com");
                  });
              });

              if (typeof partnerData.logo === "string") {
                image = partnerData.logo;
              } else {
                image = urlPicImage;
              }

              const partData = {
                name: partnerData.name,
                adresseLine: partnerData.adresseLine,
                zipCode: partnerData.zipCode,
                country: partnerData.country,
                city: partnerData.city,
                iconPicto: image,
                logo: image,
                picture: image,
              };

              let data;

              await client
                .mutate({
                  mutation: updateSWPartner,
                  variables: {
                    id: params.id,
                    input: partData,
                  },
                })
                .then((res) => {
                  data = res.data.SwPartner;
                });

              return {
                data: { ...data, id: 1 },
              };
            },
            parseError: (error) => console.log(error),
          };
        case "DELETE":
          return {
            query: gql`
              mutation deleteSwPartner($id: ID!) {
                deleteSwPartner(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };
        case "DELETE_MANY":
          return {
            query: gql`
              mutation deleteSwPartners($ids: [ID]!) {
                deleteSwPartners(_ids: $ids)
              }
            `,
            parseResponse: (response) => {
              return {
                data: [{ id: "", result: "deleted" }],
              };
            },
            variables: { ids: params.ids },
            parseError: (error) => console.log(error),
          };
        default:
          break;
      }
    } else if (resourceName === "discountCodes") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query codes {
                codes {
                  _id
                  type
                  code
                }
              }
            `,
            parseResponse: (response) => {
              console.log("res:", response);
              return {
                data: response.data.codes.map((code, i) => {
                  return {
                    index: i,
                    id: code._id,
                    type: code.type,
                    code: code.code,
                    //codes: code.codes,
                  };
                }),
                total: response.data.codes.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query code($id: ID!) {
                code(_id: $id) {
                  _id
                  type
                  code
                }
              }
            `,
            parseResponse: (response) => {
              let data = response.data.SwPartner;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query codes {
                codes {
                  _id
                  type
                  code
                }
              }
            `,
            parseResponse: (response) => {
              console.log("res:", response);
              return {
                data: response.data.codes.map((code, i) => {
                  return {
                    index: i,
                    id: code._id,
                    type: code.type,
                    code: code.code,
                  };
                }),
                total: response.data.codes.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "CREATE":
          let codeData = params.data;
          const dataItem = {
            type: codeData.type,
            code: codeData.code,
          };
          return {
            query: gql`
              mutation createCode($input: CreateCodeInput!) {
                createCode(input: $input) {
                  _id
                  type
                  code
                }
              }
            `,
            parseResponse: (response) => {
              let data = response.data.Code;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { input: dataItem },
            parseError: (error) => console.log(error),
          };
        case "UPDATE":
          let codeInputData = params.data;
          const inputCodeData = {
            type: codeInputData.type,
            //code: codeInputData.code,
            //codes: codeInputData.codes
          };
          return {
            query: gql`
              mutation updateCode($id: ID!, $input: UpdateCodeInput) {
                updateCode(_id: $id, input: $input) {
                  _id
                  type
                  code
                  codes
                }
              }
            `,
            parseResponse: (response) => {
              return {
                data: { id: "", result: "updated" },
              };
            },
            variables: { id: params.id, input: inputCodeData },
            parseError: (error) => console.log(error),
          };
        case "DELETE":
          return {
            query: gql`
              mutation deleteCode($id: ID!) {
                deleteCode(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };
        case "DELETE_MANY":
          return {
            query: gql`
              mutation deleteCodes($ids: [ID]!) {
                deleteCodes(_ids: $ids)
              }
            `,
            parseResponse: (response) => {
              return {
                data: [{ id: "", result: "deleted" }],
              };
            },
            variables: { ids: params.ids },
            parseError: (error) => console.log(error),
          };
        default:
          break;
      }
    } else if (resourceName === "establishments") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getEstablishmentsBackoffice {
                getEstablishmentsBackoffice {
                  _id
                  image
                  name
                  address {
                    lat
                    lng
                  }
                  logo
                  type
                  caloriesPerMinute
                  activityType
                  value
                  useGps
                }
              }
            `,
            parseResponse: (response) => {
              console.log("res:", response);
              return {
                data: response.data.getEstablishmentsBackoffice.map(
                  (Establishment, i) => {
                    return {
                      index: i,
                      id: Establishment._id,
                      image: Establishment.image,
                      name: Establishment.name,
                      address: Establishment.address,
                      logo: Establishment.logo,
                      type: Establishment.type,
                      caloriesPerMinute: Establishment.caloriesPerMinute,
                      value: Establishment.value,
                      useGps: Establishment.useGps,
                      activityType: Establishment.activityType,
                      //Establishments: Establishment.Establishments,
                    };
                  }
                ),
                total: response.data.getEstablishmentsBackoffice.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getEstablishmentById($id: ID!) {
                getEstablishmentById(_id: $id) {
                  _id
                  image
                  name
                  address {
                    lat
                    lng
                  }
                  logo
                  type
                  caloriesPerMinute
                  activityType
                  value
                  useGps
                }
              }
            `,
            parseResponse: (response) => {
              let data = response.data.getEstablishmentById;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getEstablishmentsBackoffice {
                getEstablishmentsBackoffice {
                  _id
                  image
                  name
                  address {
                    lat
                    lng
                  }
                  logo
                  type
                  caloriesPerMinute
                  activityType
                  value
                  useGps
                }
              }
            `,
            parseResponse: (response) => {
              console.log("res:", response);
              return {
                data: response.data.getEstablishmentsBackoffice.map(
                  (Establishment, i) => {
                    return {
                      index: i,
                      id: Establishment._id,
                      image: Establishment.image,
                      name: Establishment.name,
                      address: Establishment.address,
                      logo: Establishment.logo,
                      type: Establishment.type,
                      caloriesPerMinute: Establishment.caloriesPerMinute,
                      value: Establishment.value,
                      useGps: Establishment.useGps,
                      activityType: Establishment.activityType,
                    };
                  }
                ),
                total: response.data.getEstablishmentsBackoffice.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "CREATE":
          return {
            query: gql`
              query me {
                me {
                  _id
                }
              }
            `,

            parseResponse: async (response) => {
              let Establishment = params.data;
              let urlImage = "";
              urlImage = await new Promise((resolve, reject) => {
                client
                  .mutate({
                    mutation: UPDATE_MARKET_PLACE_PICTURE,
                    variables: {
                      file: Establishment.image.rawFile,
                    },
                  })
                  .then((res) => {
                    console.log("dataRes", res.data.uploadMarketPlacePicture);
                    if (res.data && res.data.uploadMarketPlacePicture) {
                      resolve(res.data.uploadMarketPlacePicture);
                    } else reject("http://test.com");
                  });
              });
              let urlLogo = "";
              urlLogo = await new Promise((resolve, reject) => {
                client
                  .mutate({
                    mutation: UPDATE_MARKET_PLACE_PICTURE,
                    variables: {
                      file: Establishment.logo.rawFile,
                    },
                  })
                  .then((res) => {
                    console.log("dataRes", res.data.uploadMarketPlacePicture);
                    if (res.data && res.data.uploadMarketPlacePicture) {
                      resolve(res.data.uploadMarketPlacePicture);
                    } else reject("http://test.com");
                  });
              });
              const dataItem = {
                type: Establishment.type,
                image: urlImage,
                name: Establishment.name,
                address: Establishment.address,
                logo: urlLogo,
                caloriesPerMinute: Establishment.caloriesPerMinute,
                activityType: Establishment.activityType,
                value: Establishment.value,
                useGps: Establishment.useGps,
              };
              const createEstablishment = gql`
                mutation createEstablishment(
                  $input: CreateEstablishmentInput!
                ) {
                  createEstablishment(input: $input) {
                    _id
                    image
                    name
                    address {
                      lat
                      lng
                    }
                    logo
                    type
                    activityType
                    caloriesPerMinute
                    value
                    useGps
                  }
                }
              `;
              let data;
              await client
                .mutate({
                  mutation: createEstablishment,
                  variables: {
                    input: dataItem,
                  },
                })
                .then((res) => {
                  data = res.data.CroudFunded;
                });

              return {
                data: { ...data, id: 1 },
              };
            },
          };

        case "UPDATE":
          return {
            query: gql`
              query me {
                me {
                  _id
                }
              }
            `,

            parseResponse: async (response) => {
              let Establishment = params.data;

              let urlImage = "";
              urlImage = await new Promise((resolve, reject) => {
                client
                  .mutate({
                    mutation: UPDATE_MARKET_PLACE_PICTURE,
                    variables: {
                      file: Establishment.image.rawFile,
                    },
                  })
                  .then((res) => {
                    console.log("dataRes", res.data.uploadMarketPlacePicture);
                    if (res.data && res.data.uploadMarketPlacePicture) {
                      resolve(res.data.uploadMarketPlacePicture);
                    } else reject("http://test.com");
                  });
              });
              let urlLogo = "";
              urlLogo = await new Promise((resolve, reject) => {
                client
                  .mutate({
                    mutation: UPDATE_MARKET_PLACE_PICTURE,
                    variables: {
                      file: Establishment.logo.rawFile,
                    },
                  })
                  .then((res) => {
                    console.log("dataRes", res.data.uploadMarketPlacePicture);
                    if (res.data && res.data.uploadMarketPlacePicture) {
                      resolve(res.data.uploadMarketPlacePicture);
                    } else reject("http://test.com");
                  });
              });

              const inputEstablishmentData = {
                image: urlImage,
                name: Establishment.name,
                address: Establishment.address,
                logo: urlLogo,
                type: Establishment.type,
                caloriesPerMinute: Establishment.caloriesPerMinute,
                value: Establishment.value,

                useGps: Establishment.useGps,
                activityType: Establishment.activityType,
              };
              const updateEstablishment = gql`
                mutation updateEstablishment(
                  $id: ID!
                  $input: UpdateEstablishmentInput
                ) {
                  updateEstablishment(_id: $id, input: $input) {
                    _id
                    image
                    name
                    address {
                      lat
                      lng
                    }
                    logo
                    activityType
                    type
                    caloriesPerMinute
                    value
                    useGps
                  }
                }
              `;
              let data;

              await client
                .mutate({
                  mutation: updateEstablishment,
                  variables: {
                    id: Establishment.id,
                    input: inputEstablishmentData,
                  },
                })
                .then((res) => {
                  data = res.data.CroudFunded;
                });

              return {
                data: { ...data, id: 1 },
              };
            },
          };
        case "DELETE":
          return {
            query: gql`
              mutation deleteEstablishment($id: ID!) {
                deleteEstablishment(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };
        case "DELETE_MANY":
          return {
            query: gql`
              mutation deleteEstablishments($ids: [ID]!) {
                deleteEstablishments(_ids: $ids)
              }
            `,
            parseResponse: (response) => {
              return {
                data: [{ id: "", result: "deleted" }],
              };
            },
            variables: { ids: params.ids },
            parseError: (error) => console.log(error),
          };
        default:
          break;
      }
    } else if (resourceName === "users") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              # query users {
              #   users {
              #     _id
              #     firstName
              #     lastName
              #     avatar
              #     fullName
              #     local {
              #       email
              #     }
              #     google {
              #       email
              #     }
              #     facebook {
              #       email
              #     }
              #   }

              query users($limit: Int!, $offset: Int!) {
                users(limit: $limit, offset: $offset) {
                  _id
                  firstName
                  lastName
                  avatar
                  fullName
                  local {
                    email
                  }
                  google {
                    email
                  }
                  facebook {
                    email
                  }
                }
              }
            `,
            variables: {
              limit: 200,
              offset: 0,
            },
            parseResponse: (response) => {
              console.log("res:", response);
              return {
                data: response.data.users.map((user, i) => {
                  let emailInfo;
                  if (user.local && user.local.email) {
                    emailInfo = user.local.email;
                  } else if (user.google && user.google.email) {
                    emailInfo = user.google.email;
                  } else if (user.facebook && user.facebook.email) {
                    emailInfo = user.facebook.email;
                  }

                  return {
                    index: i,
                    id: user._id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    avatar: user.avatar,
                    fullName: user.fullName,
                    email: emailInfo,
                  };
                }),
                total: response.data.users.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query user($id: ID!) {
                user(_id: $id) {
                  _id
                  firstName
                  lastName
                  avatar
                  fullName
                  local {
                    email
                  }
                  google {
                    email
                  }
                  facebook {
                    email
                  }
                }
              }
            `,
            parseResponse: (response) => {
              let data = response.data.user;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query users {
                users {
                  _id
                  firstName
                  lastName
                  avatar
                  fullName
                  local {
                    email
                  }
                  google {
                    email
                  }
                  facebook {
                    email
                  }
                }
              }
            `,
            parseResponse: (response) => {
              console.log("res:", response);

              return {
                data: response.data.users.map((user, i) => {
                  let emailInfo;
                  if (user.local && user.local.email) {
                    emailInfo = user.local.email;
                  } else if (user.google && user.google.email) {
                    emailInfo = user.google.email;
                  } else if (user.facebook && user.facebook.email) {
                    emailInfo = user.facebook.email;
                  }

                  return {
                    index: i,
                    id: user._id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    avatar: user.avatar,
                    fullName: user.fullName,
                    email: emailInfo,
                  };
                }),
                total: response.data.users.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        default:
          break;
      }
    } else if (resourceName === "croudFundeds") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getCroudFundedsBackoffice {
                getCroudFundedsBackoffice(page: null, limit: null) {
                  _id
                  name
                  images
                  videoCover
                  video
                  remainingTime
                  subtitle1
                  subtitle2
                  distanceToShowDonation
                  projectDescription
                  startingDate
                  currency
                  status
                  amount_to_rise_goal
                  team_id
                  teamGroup_id
                  teamSubGroup_id

                  donationInProgress
                  donationCeiling
                  categories {
                    _id
                    name
                  }
                  picto
                  owner {
                    _id
                    firstName
                    lastName
                    fullName
                    avatar
                    local {
                      email
                    }
                  }
                  biography
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getCroudFundedsBackoffice.map(
                  (croudFunded, i) => {
                    const heure =
                      croudFunded.remainingTime &&
                      croudFunded.remainingTime.toString().substr(0, 2);
                    const min =
                      croudFunded.remainingTime &&
                      croudFunded.remainingTime.toString().substr(3, 2);
                    let minTime;
                    if (min && min.length == 1) {
                      minTime = min + "0";
                    } else if (min && min.length == 2) {
                      minTime = min;
                    } else {
                      minTime = "00";
                    }
                    console.log("croudFunded:", croudFunded);
                    return {
                      index: i,
                      id: croudFunded._id,
                      name: croudFunded.name,
                      images: croudFunded.images,
                      remainingTime: new Date(
                        croudFunded.startingDate + "T" + heure + ":" + minTime
                      ),
                      subtitle1: croudFunded.subtitle1,
                      subtitle2: croudFunded.subtitle2,
                      distanceToShowDonation:
                        croudFunded.distanceToShowDonation,
                      projectDescription: croudFunded.projectDescription,
                      startingDate: croudFunded.startingDate,
                      currency: croudFunded.currency,
                      status: croudFunded.status,
                      amount_to_rise_goal: croudFunded.amount_to_rise_goal,
                      donationInProgress: croudFunded.donationInProgress,
                      donationCeiling: croudFunded.donationCeiling,
                      owner: croudFunded.owner._id,
                      picto: croudFunded.picto,
                      videoCover: croudFunded.videoCover,
                      video: croudFunded.video,
                      biography: croudFunded.biography,
                      categories: croudFunded.categories,
                      team_id: croudFunded.team_id,
                      teamGroup_id: croudFunded.teamGroup_id,
                      teamSubGroup_id: croudFunded.teamSubGroup_id,
                      email:
                        croudFunded.owner.local &&
                        croudFunded.owner.local.email,
                    };
                  }
                ),
                total: response.data.getCroudFundedsBackoffice.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getCroudFundedsBackoffice {
                getCroudFundedsBackoffice(page: null, limit: null) {
                  _id
                  name
                  images
                  videoCover
                  video
                  team_id
                  teamGroup_id
                  teamSubGroup_id
                  remainingTime
                  subtitle1
                  subtitle2
                  distanceToShowDonation
                  projectDescription
                  startingDate
                  currency
                  status
                  amount_to_rise_goal
                  donationInProgress
                  donationCeiling
                  picto
                  owner {
                    _id
                    firstName
                    lastName
                    fullName
                    avatar
                    local {
                      email
                    }
                  }
                  biography
                }
              }
            `,
            parseResponse: (response) => {
              return {
                data: response.data.getCroudFundedsBackoffice.map(
                  (croudFunded, i) => {
                    const heure =
                      croudFunded.remainingTime &&
                      croudFunded.remainingTime.toString().substr(0, 2);
                    const min =
                      croudFunded.remainingTime &&
                      croudFunded.remainingTime.toString().substr(3, 2);
                    let minTime;
                    if (min && min.length == 1) {
                      minTime = min + "0";
                    } else if (min && min.length == 2) {
                      minTime = min;
                    } else {
                      minTime = "00";
                    }
                    console.log("croudFunded:", croudFunded);
                    return {
                      index: i,
                      id: croudFunded._id,
                      name: croudFunded.name,
                      images: croudFunded.images,
                      remainingTime: new Date(
                        croudFunded.startingDate + "T" + heure + ":" + minTime
                      ),
                      subtitle1: croudFunded.subtitle1,
                      subtitle2: croudFunded.subtitle2,
                      distanceToShowDonation:
                        croudFunded.distanceToShowDonation,
                      projectDescription: croudFunded.projectDescription,
                      startingDate: croudFunded.startingDate,
                      currency: croudFunded.currency,
                      status: croudFunded.status,
                      amount_to_rise_goal: croudFunded.amount_to_rise_goal,
                      donationInProgress: croudFunded.donationInProgress,
                      donationCeiling: croudFunded.donationCeiling,
                      owner: croudFunded.owner._id,
                      picto: croudFunded.picto,
                      videoCover: croudFunded.videoCover,
                      video: croudFunded.video,
                      biography: croudFunded.biography,
                      team_id: croudFunded.team_id,
                      teamGroup_id: croudFunded.teamGroup_id,
                      teamSubGroup_id: croudFunded.teamSubGroup_id,
                      email:
                        croudFunded.owner.local &&
                        croudFunded.owner.local.email,
                    };
                  }
                ),
                total: response.data.getCroudFundedsBackoffice.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getCroudFundedById($id: ID!) {
                getCroudFundedById(_id: $id) {
                  _id
                  name
                  owner {
                    _id
                    firstName
                    lastName
                    fullName
                    avatar
                  }
                  subtitle1
                  subtitle2
                  distanceToShowDonation
                  startingDate
                  currency
                  amount_to_rise_goal
                  remainingTime
                  status
                  donationInProgress
                  donationCeiling
                  projectDescription
                  images
                  picto
                  videoCover
                  video
                  biography
                }
              }
            `,

            parseResponse: (response) => {
              let data = response.data.CroudFunded;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "CREATE":
          return {
            query: gql`
              query me {
                me {
                  _id
                }
              }
            `,

            parseResponse: async (response) => {
              const createCroudFund = gql`
                mutation createCroudFunded($input: CreateCroudFundedInput!) {
                  createCroudFunded(input: $input) {
                    _id
                    name
                    subtitle1
                    subtitle2
                    distanceToShowDonation
                    startingDate
                    team_id
                    teamGroup_id
                    teamSubGroup_id
                    currency
                    amount_to_rise_goal
                    remainingTime
                    status
                    donationInProgress
                    donationCeiling
                    projectDescription
                    videoCover
                    images
                    picto
                    givers {
                      _id
                      firstName
                      avatar
                    }
                    biography
                    video
                  }
                }
              `;

              let croudFundedsData = params.data;

              const croudTime = croudFundedsData.remainingTime.toString();
              const heure = croudTime.substr(16, 2);
              const min = croudTime.substr(19, 2);
              const time = heure + "." + min;
              const categoriesList = [];
              categoriesList.push(croudFundedsData.categories);
              let urlImages = [];
              urlImages = await Promise.all(
                croudFundedsData.images
                  .map(async (image) => {
                    const url = await new Promise((resolve, reject) => {
                      client
                        .mutate({
                          mutation: UPDATE_MARKET_PLACE_PICTURE,
                          variables: {
                            file: image.image.rawFile,
                          },
                        })
                        .then((res) => {
                          console.log(
                            "dataRes",
                            res.data.uploadMarketPlacePicture
                          );
                          if (res.data && res.data.uploadMarketPlacePicture) {
                            resolve(res.data.uploadMarketPlacePicture);
                          } else reject("http://test.com");
                        });
                    });
                    console.log("images: ", url);
                    return url;
                  })
                  .filter((res) => res != null)
              );

              const urlCoverVideo = await new Promise((resolve, reject) => {
                client
                  .mutate({
                    mutation: UPDATE_MARKET_PLACE_PICTURE,
                    variables: {
                      file: croudFundedsData.videoCover.rawFile,
                    },
                  })
                  .then((res) => {
                    console.log("dataRes", res.data.uploadMarketPlacePicture);
                    if (res.data && res.data.uploadMarketPlacePicture) {
                      resolve(res.data.uploadMarketPlacePicture);
                    } else reject("http://test.com");
                  });
              });
              console.log("video cover url:", urlCoverVideo);

              let urlVideo;
              if (croudFundedsData.videoCover.rawFile.size > 200000) {
                alert("S'il vous plaît importer un vidéo moins de 90 secondes");
              } else {
                urlVideo = await new Promise((resolve, reject) => {
                  client
                    .mutate({
                      mutation: UPDATE_MARKET_PLACE_PICTURE,
                      variables: {
                        file: croudFundedsData.video.rawFile,
                      },
                    })
                    .then((res) => {
                      console.log("dataRes", res.data.uploadMarketPlacePicture);
                      if (res.data && res.data.uploadMarketPlacePicture) {
                        resolve(res.data.uploadMarketPlacePicture);
                      } else reject("http://test.com");
                    });
                });
              }

              const croudDataItem = {
                name: croudFundedsData.name,
                owner: croudFundedsData.owner,
                subtitle1: croudFundedsData.subtitle1,
                subtitle2: croudFundedsData.subtitle2,
                team_id: croudFundedsData.team_id,
                teamGroup_id: croudFundedsData.teamGroup_id,
                teamSubGroup_id: "",
                categories: categoriesList,
                distanceToShowDonation: croudFundedsData.distanceToShowDonation,
                startingDate: croudFundedsData.startingDate,
                currency: croudFundedsData.currency,
                amount_to_rise_goal: croudFundedsData.amount_to_rise_goal,
                remainingTime: parseFloat(time),
                status: croudFundedsData.status,
                donationInProgress: croudFundedsData.donationInProgress,
                donationCeiling: croudFundedsData.donationCeiling,
                projectDescription: croudFundedsData.projectDescription,
                images: urlImages,
                videoCover: urlCoverVideo,
                video: urlVideo,
                biography: croudFundedsData.biography,
              };

              console.log("croudDataItem:", croudDataItem);
              let data;

              await client
                .mutate({
                  mutation: createCroudFund,
                  variables: {
                    input: croudDataItem,
                  },
                })
                .then((res) => {
                  data = res.data.CroudFunded;
                });

              return {
                data: { ...data, id: 1 },
              };
            },

            parseError: (error) => console.log(error),
          };

        case "DELETE":
          return {
            query: gql`
              mutation deleteCroudFunded($id: ID!) {
                deleteCroudFunded(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };
        case "DELETE_MANY":
          return {
            query: gql`
              mutation deleteCroudFundeds($ids: [ID]!) {
                deleteCroudFundeds(_ids: $ids)
              }
            `,
            parseResponse: (response) => {
              return {
                data: [{ id: "", result: "deleted" }],
              };
            },
            variables: { ids: params.ids },
            parseError: (error) => console.log(error),
          };
        default:
          break;
      }
    } else if (resourceName === "gains") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getGainsBackoffice {
                getGainsBackoffice {
                  _id
                  name
                  images
                  subtitle
                  details
                  active
                  description
                  active
                  price {
                    spows
                    parrains
                  }
                  categories {
                    _id
                    name
                  }
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getGainsBackoffice.map((Gain, i) => {
                  return {
                    index: i,
                    id: Gain._id,
                    name: Gain.name,
                    subtitle: Gain.subtitle,
                    details: Gain.details,
                    active: Gain.active,

                    categories: Gain.categories,
                    price: {
                      spows: Gain.price.spows,
                      parrains: Gain.price.parrains,
                    },
                    description: Gain.description,
                    images: Gain.images,
                  };
                }),
                total: response.data.getGainsBackoffice.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getGains {
                getGains {
                  _id
                  name
                  images
                  subtitle
                  details
                  description
                  active
                  price {
                    spows
                    parrains
                  }
                  categories {
                    _id
                    name
                  }
                }
              }
            `,
            parseResponse: (response) => {
              return {
                data: response.data.getGains.map((Gain, i) => {
                  return {
                    index: i,
                    id: Gain._id,
                    name: Gain.name,
                    subtitle: Gain.subtitle,
                    details: Gain.details,
                    active: Gain.active,

                    categories: Gain.categories,
                    price: {
                      spows: Gain.price.spows,
                      parrains: Gain.price.parrains,
                    },
                    description: Gain.description,
                    images: Gain.images,
                  };
                }),
                total: response.data.getGains.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getGainById($id: ID!) {
                getGainById(_id: $id) {
                  _id
                  name
                  images
                  subtitle
                  details
                  description
                  active
                  price {
                    spows
                    parrains
                  }
                  categories {
                    _id
                    name
                  }
                }
              }
            `,

            parseResponse: (response) => {
              let data = response.data.Gain;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "CREATE":
          return {
            query: gql`
              query me {
                me {
                  _id
                }
              }
            `,

            parseResponse: async (response) => {
              const createGain = gql`
                mutation createGain($input: CreateGainInput!) {
                  createGain(input: $input) {
                    _id
                    name
                    images
                    subtitle
                    details
                    description
                    active
                    price {
                      spows
                      parrains
                    }
                    categories {
                      _id
                      name
                    }
                  }
                }
              `;

              let gainsData = params.data;
              const categoriesList = [];
              categoriesList.push(gainsData.categories);
              let urlImages = [];
              urlImages = await Promise.all(
                gainsData.images
                  .map(async (image) => {
                    const url = await new Promise((resolve, reject) => {
                      client
                        .mutate({
                          mutation: UPDATE_MARKET_PLACE_PICTURE,
                          variables: {
                            file: image.image.rawFile,
                          },
                        })
                        .then((res) => {
                          console.log(
                            "dataRes",
                            res.data.uploadMarketPlacePicture
                          );
                          if (res.data && res.data.uploadMarketPlacePicture) {
                            resolve(res.data.uploadMarketPlacePicture);
                          } else reject("http://test.com");
                        });
                    });
                    console.log("images: ", url);
                    return url;
                  })
                  .filter((res) => res != null)
              );

              const gainItem = {
                name: gainsData.name,
                subtitle: gainsData.subtitle,
                details: gainsData.details,
                active: gainsData.active,
                categories: categoriesList,
                price: {
                  spows: gainsData.price.spows,
                  parrains: gainsData.price.parrains,
                },
                description: gainsData.description,
                images: urlImages,
              };

              console.log("gainItem:", gainItem);
              let data;

              await client
                .mutate({
                  mutation: createGain,
                  variables: {
                    input: gainItem,
                  },
                })
                .then((res) => {
                  data = res.data.Gain;
                });

              return {
                data: { ...data, id: 1 },
              };
            },

            parseError: (error) => console.log(error),
          };

        case "DELETE":
          return {
            query: gql`
              mutation deleteGain($id: ID!) {
                deleteGain(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };
        case "DELETE_MANY":
          return {
            query: gql`
              mutation deleteGains($ids: [ID]!) {
                deleteGains(_ids: $ids)
              }
            `,
            parseResponse: (response) => {
              return {
                data: [{ id: "", result: "deleted" }],
              };
            },
            variables: { ids: params.ids },
            parseError: (error) => console.log(error),
          };
        default:
          break;
      }
    } else if (resourceName === "Teams") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getTeams {
                getTeams {
                  _id
                  managerId
                  name
                  description
                  image
                  code
                  teamGroups {
                    _id
                    name
                  }
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getTeams.map((team, i) => {
                  return {
                    index: i,
                    id: team._id,
                    name: team.name,
                    description: team.description,
                    image: team.image,
                    managerId: team.managerId,
                    code: team.code,
                    teamGroups: team.teamGroups,
                  };
                }),
                total: response.data.getTeams.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getTeams {
                getTeams {
                  _id
                  managerId
                  name
                  description
                  image
                  code
                }
              }
            `,
            parseResponse: (response) => {
              return {
                data: response.data.getTeams.map((team, i) => {
                  return {
                    index: i,
                    id: team._id,
                    name: team.name,
                    description: team.description,
                    image: team.image,
                    managerId: team.managerId,
                    code: team.code,
                  };
                }),
                total: response.data.getTeams.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getTeamById($id: ID!) {
                getTeamById(_id: $id) {
                  _id
                  managerId
                  name
                  description
                  image
                  code
                }
              }
            `,

            parseResponse: (response) => {
              let data = response.data.getTeamById;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "CREATE":
          return {
            query: gql`
              query me {
                me {
                  _id
                }
              }
            `,

            parseResponse: async (response) => {
              const createTeam = gql`
                mutation createTeam($input: CreateTeamInput!) {
                  createTeam(input: $input) {
                    _id
                    managerId
                    name
                    description
                    image
                    code
                  }
                }
              `;

              let TeamData = params.data;
              const categoriesList = [];
              categoriesList.push(TeamData.categories);
              let image =
                typeof TeamData.image !== "string"
                  ? await new Promise((resolve, reject) => {
                      client
                        .mutate({
                          mutation: UPDATE_MARKET_PLACE_PICTURE,
                          variables: {
                            file: TeamData.image.rawFile,
                          },
                        })
                        .then((res) => {
                          console.log(
                            "dataRes",
                            res.data.uploadMarketPlacePicture
                          );
                          if (res.data && res.data.uploadMarketPlacePicture) {
                            resolve(res.data.uploadMarketPlacePicture);
                          } else reject("http://test.com");
                        });
                    })
                  : TeamData.image;

              const TeamItem = {
                name: TeamData.name,
                managerId: TeamData.managerId,
                description: TeamData.description,
                code: TeamData.code,

                image: image,
              };

              console.log("TeamItem:", TeamItem);
              let data;

              await client
                .mutate({
                  mutation: createTeam,
                  variables: {
                    input: TeamItem,
                  },
                })
                .then((res) => {
                  data = res.data.Team;
                });

              return {
                data: { ...data, id: 1 },
              };
            },

            parseError: (error) => console.log(error),
          };

        case "DELETE":
          return {
            query: gql`
              mutation deleteTeam($id: ID!) {
                deleteTeam(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };
        case "DELETE_MANY":
          return {
            query: gql`
              mutation deleteTeam($ids: [ID]!) {
                deleteTeam(_ids: $ids)
              }
            `,
            parseResponse: (response) => {
              return {
                data: [{ id: "", result: "deleted" }],
              };
            },
            variables: { ids: params.ids },
            parseError: (error) => console.log(error),
          };
        default:
          break;
      }
    } else if (resourceName === "TeamsGroups") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getTeamGroup {
                getTeamGroup {
                  _id
                  managerId
                  name
                  description
                  image
                  team {
                    _id
                    name
                  }
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getTeamGroup.map((teamGroup, i) => {
                  return {
                    index: i,
                    id: teamGroup._id,
                    name: teamGroup.name,
                    description: teamGroup.description,
                    image: teamGroup.image,
                    managerId: teamGroup.managerId,
                    team: teamGroup.team,
                  };
                }),
                total: response.data.getTeamGroup.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getTeamGroup {
                getTeamGroup {
                  _id
                  managerId
                  name
                  description
                  image
                  team {
                    _id
                    name
                  }
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getTeamGroup.map((teamGroup, i) => {
                  return {
                    index: i,
                    id: teamGroup._id,
                    name: teamGroup.name,
                    description: teamGroup.description,
                    image: teamGroup.image,
                    managerId: teamGroup.managerId,
                    team: teamGroup.team?.name,
                  };
                }),
                total: response.data.getTeamGroup.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getTeamGroupById($id: ID!) {
                getTeamGroupById(_id: $id) {
                  _id
                  managerId
                  name
                  description
                  image
                  team {
                    _id
                    name
                  }
                }
              }
            `,

            parseResponse: (response) => {
              let data = response.data.getTeamGroupById;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "DELETE":
          return {
            query: gql`
              mutation deleteTeamGroup($id: ID!) {
                deleteTeamGroup(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };

        default:
          break;
      }
    } else if (resourceName === "exerciceTypes") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getExerciceTypesBO {
                getExerciceTypesBO {
                  _id
                  name
                  garminName
                  polarName
                  suuntoName
                  fitbitName
                  appleName
                  isActive
                  defaultImage
                  calsPerHour
                  icon
                  isGPS
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getExerciceTypesBO.map(
                  (ExerciceType, i) => {
                    return {
                      index: i,
                      id: ExerciceType._id,
                      name: ExerciceType.name,
                      garminName: ExerciceType.garminName,
                      polarName: ExerciceType.polarName,
                      suuntoName: ExerciceType.suuntoName,
                      fitbitName: ExerciceType.fitbitName,
                      appleName: ExerciceType.appleName,
                      isActive: ExerciceType.isActive,
                      defaultImage: ExerciceType.defaultImage,

                      calsPerHour: ExerciceType.calsPerHour,
                      icon: ExerciceType.icon,
                      isGPS: ExerciceType.isGPS,
                    };
                  }
                ),
                total: response.data.getExerciceTypesBO.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getExerciceTypes {
                getExerciceTypes {
                  _id
                  name
                  garminName
                  polarName
                  suuntoName
                  fitbitName
                  calsPerHour
                  appleName
                  isActive
                  defaultImage
                  icon
                  isGPS
                }
              }
            `,
            parseResponse: (response) => {
              return {
                data: response.data.getExerciceTypes.map((ExerciceType, i) => {
                  return {
                    index: i,
                    id: ExerciceType._id,
                    name: ExerciceType.name,
                    garminName: ExerciceType.garminName,
                    polarName: ExerciceType.polarName,
                    suuntoName: ExerciceType.suuntoName,
                    fitbitName: ExerciceType.fitbitName,
                    appleName: ExerciceType.appleName,
                    calsPerHour: ExerciceType.calsPerHour,
                    isActive: ExerciceType.isActive,
                    defaultImage: ExerciceType.defaultImage,
                    icon: ExerciceType.icon,
                    isGPS: ExerciceType.isGPS,
                  };
                }),
                total: response.data.getExerciceTypes.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getExerciceTypesById($id: ID!) {
                getExerciceTypesById(_id: $id) {
                  _id
                  name
                  garminName
                  polarName
                  suuntoName
                  fitbitName
                  calsPerHour
                  appleName
                  isActive
                  defaultImage
                  icon
                  isGPS
                }
              }
            `,

            parseResponse: (response) => {
              let data = response.data.getExerciceTypesById;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "CREATE":
          return {
            query: gql`
              query me {
                me {
                  _id
                }
              }
            `,

            parseResponse: async (response) => {
              const createExerciceType = gql`
                mutation createExerciceTypes($input: CreateExerciceTypeInput!) {
                  createExerciceTypes(input: $input) {
                    _id
                    name
                    garminName
                    polarName
                    suuntoName
                    fitbitName
                    calsPerHour
                    appleName
                    isActive
                    defaultImage
                    icon
                    isGPS
                  }
                }
              `;

              let ExerciceTypeData = params.data;
              const categoriesList = [];
              categoriesList.push(ExerciceTypeData.categories);
              let image =
                typeof ExerciceTypeData.defaultImage !== "string"
                  ? await new Promise((resolve, reject) => {
                      client
                        .mutate({
                          mutation: UPDATE_MARKET_PLACE_PICTURE,
                          variables: {
                            file: ExerciceTypeData.defaultImage.rawFile,
                          },
                        })
                        .then((res) => {
                          console.log(
                            "dataRes",
                            res.data.uploadMarketPlacePicture
                          );
                          if (res.data && res.data.uploadMarketPlacePicture) {
                            resolve(res.data.uploadMarketPlacePicture);
                          } else reject("http://test.com");
                        });
                    })
                  : ExerciceTypeData.defaultImage;
              let icon =
                typeof ExerciceTypeData.icon !== "string"
                  ? await new Promise((resolve, reject) => {
                      client
                        .mutate({
                          mutation: UPDATE_MARKET_PLACE_PICTURE,
                          variables: {
                            file: ExerciceTypeData.icon.rawFile,
                          },
                        })
                        .then((res) => {
                          console.log(
                            "dataRes",
                            res.data.uploadMarketPlacePicture
                          );
                          if (res.data && res.data.uploadMarketPlacePicture) {
                            resolve(res.data.uploadMarketPlacePicture);
                          } else reject("http://test.com");
                        });
                    })
                  : ExerciceTypeData.icon;
              const ExerciceTypeItem = {
                id: ExerciceTypeData._id,
                name: ExerciceTypeData.name,
                garminName: ExerciceTypeData.garminName,
                polarName: ExerciceTypeData.polarName,
                suuntoName: ExerciceTypeData.suuntoName,
                fitbitName: ExerciceTypeData.fitbitName,
                appleName: ExerciceTypeData.appleName,
                isActive: ExerciceTypeData.isActive,
                calsPerHour: ExerciceTypeData.calsPerHour,
                defaultImage: image,
                icon: ExerciceTypeData.icon,
                isGPS: ExerciceTypeData.isGPS,
              };

              console.log("ExerciceTypeItem:", ExerciceTypeItem);
              let data;

              await client
                .mutate({
                  mutation: createExerciceType,
                  variables: {
                    input: ExerciceTypeItem,
                  },
                })
                .then((res) => {
                  data = res.data.ExerciceType;
                });

              return {
                data: { ...data, id: 1 },
              };
            },

            parseError: (error) => console.log(error),
          };

        default:
          break;
      }
    } else if (resourceName === "sponsorisedPost") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getSponsorisedPostsBackoffice {
                getSponsorisedPostsBackoffice {
                  _id
                  offer
                  croudFund
                  gain
                  type
                  title
                  subTitle
                  content {
                    _id
                    offer
                    croudFund
                    gain
                    type
                    title
                    text
                    image
                    video
                  }
                  canClose
                  isFirst
                  coverImage
                  active
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getSponsorisedPostsBackoffice.map(
                  (SponsorisedPost, i) => {
                    return {
                      ...SponsorisedPost,
                      index: i,
                      id: SponsorisedPost._id,
                    };
                  }
                ),
                total: response.data.getSponsorisedPostsBackoffice.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getSponsorisedPosts {
                getSponsorisedPosts {
                  _id
                  offer {
                    _id
                    name
                  }
                  croudFund {
                    _id
                    name
                  }
                  gain {
                    _id
                    name
                  }
                  type
                  title
                  subTitle
                  content {
                    _id
                    offer
                    croudFund
                    gain
                    type
                    title
                    text
                    image
                    video
                  }
                  canClose
                  isFirst
                  coverImage
                  active
                }
              }
            `,
            parseResponse: (response) => {
              return {
                data: response.data.getSponsorisedPosts.map(
                  (SponsorisedPost, i) => {
                    return {
                      ...SponsorisedPost,
                      index: i,
                      id: SponsorisedPost._id,
                    };
                  }
                ),
                total: response.data.getSponsorisedPosts.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getSponsorisedPostById($id: ID!) {
                getSponsorisedPostById(_id: $id) {
                  _id
                  offer {
                    _id
                    name
                  }
                  croudFund {
                    _id
                    name
                  }
                  gain {
                    _id
                    name
                  }
                  type
                  title
                  subTitle
                  content {
                    _id
                    offer
                    croudFund
                    gain
                    type
                    title
                    text
                    image
                    video
                  }
                  canClose
                  isFirst
                  coverImage
                  active
                }
              }
            `,

            parseResponse: (response) => {
              let data = response.data.SponsorisedPost;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "CREATE":
          return {
            query: gql`
              query me {
                me {
                  _id
                }
              }
            `,

            parseResponse: async (response) => {
              const createSponsorisedPost = gql`
                mutation createSponsorisedPost(
                  $input: CreateSponsorisedPostInput!
                ) {
                  createSponsorisedPost(input: $input) {
                    _id
                    type
                    title
                    subTitle
                    content {
                      _id
                      offer
                      croudFund
                      gain
                      type
                      title
                      text
                      image
                      video
                    }
                    canClose
                    isFirst
                    coverImage
                    active
                  }
                }
              `;

              let SponsorisedPostsData = params.data;
              console.log(SponsorisedPostsData);
              const categoriesList = [];
              categoriesList.push(SponsorisedPostsData.categories);
              let urlImage = "";
              urlImage = await new Promise((resolve, reject) => {
                client
                  .mutate({
                    mutation: UPDATE_MARKET_PLACE_PICTURE,
                    variables: {
                      file: SponsorisedPostsData.coverImage.rawFile,
                    },
                  })
                  .then((res) => {
                    console.log("dataRes", res.data.uploadMarketPlacePicture);
                    if (res.data && res.data.uploadMarketPlacePicture) {
                      resolve(res.data.uploadMarketPlacePicture);
                    } else reject("http://test.com");
                  });
              });
              let content = SponsorisedPostsData.content.length
                ? await Promise.all(
                    SponsorisedPostsData.content.map(async (el) => {
                      if (el.type === "IMAGE" && typeof el.image === "object") {
                        el.image = await new Promise((resolve, reject) => {
                          client
                            .mutate({
                              mutation: UPDATE_MARKET_PLACE_PICTURE,
                              variables: {
                                file: el.image.rawFile,
                              },
                            })
                            .then((res) => {
                              console.log(
                                "dataRes",
                                res.data.uploadMarketPlacePicture
                              );
                              if (
                                res.data &&
                                res.data.uploadMarketPlacePicture
                              ) {
                                resolve(res.data.uploadMarketPlacePicture);
                              } else reject("http://test.com");
                            });
                        });
                      }
                      if (el.type === "VIDEO" && typeof el.video === "object") {
                        el.video = await new Promise((resolve, reject) => {
                          client
                            .mutate({
                              mutation: UPDATE_MARKET_PLACE_PICTURE,
                              variables: {
                                file: el.video.rawFile,
                              },
                            })
                            .then((res) => {
                              console.log(
                                "dataRes",
                                res.data.uploadMarketPlacePicture
                              );
                              if (
                                res.data &&
                                res.data.uploadMarketPlacePicture
                              ) {
                                resolve(res.data.uploadMarketPlacePicture);
                              } else reject("http://test.com");
                            });
                        });
                      }
                      return {
                        _id: el._id,
                        offer: el.offer,
                        gain: el.gain,
                        croudFund: el.croudFund,
                        title: el.title,
                        text: el.text,
                        type: el.type,
                        image: el.image,
                        video: el.video,
                      };
                    })
                  )
                : [];
              const SponsorisedPostItem = {
                title: SponsorisedPostsData.title,
                subTitle: SponsorisedPostsData.subTitle,
                type: SponsorisedPostsData.type,
                content: content,
                offer: SponsorisedPostsData.offer,
                gain: SponsorisedPostsData.gain,
                croudFund: SponsorisedPostsData.croudFund,
                isFirst: SponsorisedPostsData.isFirst,
                active: SponsorisedPostsData.active,
                canClose: SponsorisedPostsData.canClose,
                coverImage: urlImage,
              };

              console.log("SponsorisedPostItem:", SponsorisedPostsData.gain);
              let data;

              await client
                .mutate({
                  mutation: createSponsorisedPost,
                  variables: {
                    input: SponsorisedPostItem,
                  },
                })
                .then((res) => {
                  data = res.data.SponsorisedPost;
                });

              return {
                data: { ...data, id: 1 },
              };
            },

            parseError: (error) => console.log(error),
          };

        case "DELETE":
          return {
            query: gql`
              mutation deleteSponsorisedPost($id: ID!) {
                deleteSponsorisedPost(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };
        case "DELETE_MANY":
          return {
            query: gql`
              mutation deleteSponsorisedPosts($ids: [ID]!) {
                deleteSponsorisedPosts(_ids: $ids)
              }
            `,
            parseResponse: (response) => {
              return {
                data: [{ id: "", result: "deleted" }],
              };
            },
            variables: { ids: params.ids },
            parseError: (error) => console.log(error),
          };
        default:
          break;
      }
    } else if (resourceName === "offers") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getSwOffersBackoffice {
                getSwOffersBackoffice {
                  _id
                  name
                  owner {
                    _id
                    name
                  }
                  country
                  city
                  discount
                  discountCode {
                    _id
                    type
                  }
                  distanceToShowOffer
                  duration
                  subtitle1
                  subtitle2
                  price
                  startDate
                  type
                  categories {
                    _id
                    name
                  }
                  currency
                  UrlLinkOffer
                  description
                  offerDetail
                  offerInstruction
                  images
                  active
                }
              }
            `,
            parseResponse: (response) => {
              console.log("res Offers:", response);
              return {
                data: response.data.getSwOffersBackoffice.map((offer, i) => {
                  return {
                    index: i,
                    id: offer._id,
                    name: offer.name,
                    owner: offer.owner,
                    //ownerName: offer.owner.name,
                    country: offer.country,
                    city: offer.city,
                    discount: offer.discount,
                    discountCodeId: offer.discountCode,
                    //discountCodeCode: offer.discountCode.code,
                    distanceToShowOffer: offer.distanceToShowOffer,
                    duration: offer.duration,
                    subtitle1: offer.subtitle1,
                    subtitle2: offer.subtitle2,
                    price: offer.price,
                    startDate: offer.startDate,
                    type: offer.type,
                    categories: offer.categories,
                    currency: offer.currency,
                    UrlLinkOffer: offer.UrlLinkOffer,
                    description: offer.description,
                    offerDetail: offer.offerDetail,
                    offerInstruction: offer.offerInstruction,
                    images: offer.images,
                    active: offer.active,
                  };
                }),
                total: response.data.getSwOffersBackoffice.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getSwOffersBackoffice {
                getSwOffersBackoffice {
                  _id
                  name
                  owner {
                    _id
                    name
                  }
                  country
                  city
                  discount
                  discountCode {
                    _id
                    type
                  }
                  distanceToShowOffer
                  duration
                  subtitle1
                  subtitle2
                  price
                  startDate
                  type
                  categories {
                    _id
                    name
                  }
                  currency
                  UrlLinkOffer
                  description
                  offerDetail
                  offerInstruction
                  images
                  active
                }
              }
            `,
            parseResponse: (response) => {
              console.log("res Offers:", response);
              return {
                data: response.data.getSwOffersBackoffice.map((offer, i) => {
                  return {
                    index: i,
                    id: offer._id,
                    name: offer.name,
                    owner: offer.owner,
                    //ownerName: offer.owner.name,
                    country: offer.country,
                    city: offer.city,
                    discount: offer.discount,
                    discountCodeId: offer.discountCode,
                    //discountCodeCode: offer.discountCode.code,
                    distanceToShowOffer: offer.distanceToShowOffer,
                    duration: offer.duration,
                    subtitle1: offer.subtitle1,
                    subtitle2: offer.subtitle2,
                    price: offer.price,
                    startDate: offer.startDate,
                    type: offer.type,
                    categories: offer.categories,
                    currency: offer.currency,
                    UrlLinkOffer: offer.UrlLinkOffer,
                    description: offer.description,
                    offerDetail: offer.offerDetail,
                    offerInstruction: offer.offerInstruction,
                    images: offer.images,
                    active: offer.active,
                  };
                }),
                total: response.data.getSwOffersBackoffice.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getSwOfferById($id: ID!) {
                getSwOfferById(_id: $id) {
                  _id
                  name
                  owner {
                    _id
                    name
                  }
                  country
                  city
                  discount
                  discountCode {
                    _id
                    type
                  }
                  distanceToShowOffer
                  duration
                  subtitle1
                  subtitle2
                  price
                  startDate
                  type
                  categories {
                    _id
                    name
                  }
                  currency
                  UrlLinkOffer
                  description
                  offerDetail
                  offerInstruction
                  images
                  active
                }
              }
            `,
            parseResponse: (response) => {
              let data = response.data.SwOffer;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "CREATE":
          return {
            query: gql`
              query me {
                me {
                  _id
                }
              }
            `,

            parseResponse: async (response) => {
              const createOffer = gql`
                mutation createSwOffer($input: CreateSwOfferInput!) {
                  createSwOffer(input: $input) {
                    _id
                    name
                  }
                }
              `;
              let offerData = params.data;

              let urlImages = [];
              urlImages = await Promise.all(
                offerData.images
                  .map(async (image) => {
                    const url = await new Promise((resolve, reject) => {
                      client
                        .mutate({
                          mutation: UPDATE_MARKET_PLACE_PICTURE,
                          variables: {
                            file: image.image.rawFile,
                          },
                        })
                        .then((res) => {
                          console.log(
                            "dataRes",
                            res.data.uploadMarketPlacePicture
                          );
                          if (res.data && res.data.uploadMarketPlacePicture) {
                            resolve(res.data.uploadMarketPlacePicture);
                          } else reject("http://test.com");
                        });
                    });
                    console.log("images: ", url);
                    return url;
                  })
                  .filter((res) => res != null)
              );

              const categoriesList = [];
              categoriesList.push(offerData.categories);

              const offerItem = {
                name: offerData.name,
                owner: offerData.owner,
                country: offerData.country,
                city: offerData.city,
                discount: offerData.discount,
                discountCode: offerData.discountCode,
                distanceToShowOffer: parseFloat(offerData.distanceToShowOffer),
                duration: offerData.duration,
                subtitle1: offerData.subtitle1,
                subtitle2: offerData.subtitle2,
                price: parseFloat(offerData.price),
                startDate: offerData.startDate,
                type: offerData.type,
                currency: offerData.currency,
                UrlLinkOffer: offerData.UrlLinkOffer,
                description: offerData.description,
                offerDetail: offerData.offerDetail,
                offerInstruction: offerData.offerInstruction,
                images: urlImages,
                active: !offerData.active,
                categories: categoriesList,
              };

              console.log("offerItem:", offerItem);
              let data;

              await client
                .mutate({
                  mutation: createOffer,
                  variables: {
                    input: offerItem,
                  },
                })
                .then((res) => {
                  data = res.data.SwOffer;
                });

              return {
                data: { ...data, id: 1 },
              };
            },

            parseError: (error) => console.log(error),
          };
        case "DELETE":
          return {
            query: gql`
              mutation deleteSwOffer($id: ID!) {
                deleteSwOffer(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };
        case "DELETE_MANY":
          return {
            query: gql`
              mutation deleteSwOffers($ids: [ID]!) {
                deleteSwOffers(_ids: $ids)
              }
            `,
            parseResponse: (response) => {
              return {
                data: [{ id: "", result: "deleted" }],
              };
            },
            variables: { ids: params.ids },
            parseError: (error) => console.log(error),
          };
        default:
          break;
      }
    } else if (resourceName === "profile") {
      switch (raFetchType) {
        case "GET_ONE":
          const storedProfile = localStorage.getItem("profile");
          if (storedProfile) {
            console.log("eeeeee:", JSON.parse(storedProfile));
            return Promise.resolve({
              data: JSON.parse(storedProfile),
            });
          }

          return Promise.resolve({
            data: {
              firstName: "",
              lastName: "",
              avatar: "",
            },
          });
        case "UPDATE":
          let adminData = params.data;

          /*const urlProfileImage = await new Promise((resolve, reject) => {
            client
              .mutate({
                mutation: UPDATE_MARKET_PLACE_PICTURE,
                variables: {
                  file: adminData.avatar.rawFile,
                },
              })
              .then((res) => {
                console.log("dataRes", res.data.uploadMarketPlacePicture);
                if (res.data && res.data.uploadMarketPlacePicture) {
                  resolve(res.data.uploadMarketPlacePicture);
                } else reject("http://test.com");
              });
          });*/

          const data = {
            //avatar: urlProfileImage,
            firstName: adminData.firstName,
            lastName: adminData.lastName,
            fullName: adminData.fullName,
            country: adminData.country,
            //reason: adminData.reason,
          };

          return {
            query: gql`
              mutation updateUser($id: ID!, $input: UpdateUserInput!) {
                updateUser(_id: $id, input: $input)
              }
            `,
            parseResponse: (response) => {
              localStorage.setItem(
                "profile",
                JSON.stringify({ id: params.data.id, ...params.data })
              );
              return {
                data: { id: "", result: "updated" },
              };
            },
            variables: { id: params.data.id, input: data },
            parseError: (error) => console.log(error),
          };
        default:
          break;
      }
    } else if (resourceName === "feedAds") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getFeedAds {
                getFeedAds {
                  _id
                  image
                  link
                  createdAt
                  updatedAt
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getFeedAds.map((feedAd, i) => {
                  return {
                    index: i,
                    id: feedAd._id,
                    link: feedAd.link,
                    image: feedAd.image,
                  };
                }),
                total: response.data.getFeedAds.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getFeedAds {
                getFeedAds {
                  _id
                  image
                  link
                  createdAt
                  updatedAt
                }
              }
            `,
            parseResponse: (response) => {
              return {
                data: response.data.getFeedAds.map((feedAd, i) => {
                  return {
                    index: i,
                    id: feedAd._id,
                    link: feedAd.link,
                    image: feedAd.image,
                  };
                }),
                total: response.data.getFeedAds.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getFeedAdById($id: ID!) {
                getFeedAdById(_id: $id) {
                  _id
                  image
                  link
                  createdAt
                  updatedAt
                }
              }
            `,

            parseResponse: (response) => {
              let data = response.data.getFeedAdById;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "DELETE":
          return {
            query: gql`
              mutation deleteFeedAd($id: ID!) {
                deleteFeedAd(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };
        default:
          break;
      }
    } else if (resourceName === "jackpot") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getJackpots {
                getJackpots {
                  _id
                  text
                  image
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getJackpots.map((jackpot, i) => {
                  return {
                    index: i,
                    id: jackpot._id,
                    text: jackpot.text,
                    image: jackpot.image,
                  };
                }),
                total: response.data.getJackpots.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_LAST":
          return {
            query: gql`
              query getLastJackpot {
                getLastJackpot {
                  _id
                  text
                  image
                }
              }
            `,
            parseResponse: (response) => {
              return {
                data: response.data.getLastJackpot.map((jackpot, i) => {
                  return {
                    index: i,
                    id: jackpot._id,
                    text: jackpot.text,
                    image: jackpot.image,
                  };
                }),
                total: response.data.getLastJackpot.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getJackpotById($id: ID!) {
                getJackpotById(_id: $id) {
                  _id
                  text
                  image
                }
              }
            `,

            parseResponse: (response) => {
              let data = response.data.getJackpotById;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "DELETE":
          return {
            query: gql`
              mutation deleteJackpot($id: ID!) {
                deleteJackpot(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };
        default:
          break;
      }
    } else if (resourceName === "BlogSections") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getBlogSections {
                getBlogSections {
                  _id
                  displayIndex
                  name
                  type
                  categories {
                    _id
                    name
                    image
                    description
                    articles {
                      _id
                      smallTitle
                      title
                      image
                      smallDescription
                      description
                      content {
                        title
                        type
                        data
                      }
                      show
                    }
                    show
                  }
                  articles {
                    _id
                    smallTitle
                    title
                    image
                    smallDescription
                    description
                    content {
                      title
                      type
                      data
                    }
                    show
                  }
                  attributionType
                  teams {
                    _id
                    name
                  }
                  teamgroups {
                    _id
                    name
                  }
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getBlogSections.map((section, i) => {
                  return {
                    index: i,
                    id: section._id,
                    name: section.name,
                    displayIndex: section.displayIndex,
                    type: section.type,
                    categories: section.categories,
                    articles: section.articles,
                    attributionType: section.attributionType,
                    teams: section.teams,
                    teamgroups: section.teamgroups,
                  };
                }),
                total: response.data.getBlogSections.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getBlogSections {
                getBlogSections {
                  _id
                  displayIndex
                  name
                  type
                  categories {
                    _id
                    name
                    image
                    articles {
                      _id
                      smallTitle
                      title
                      image
                      smallDescription
                      description
                      content {
                        title
                        type
                        data
                      }
                      show
                    }
                    show
                  }
                  articles {
                    _id
                    smallTitle
                    title
                    image
                    smallDescription
                    description
                    content {
                      title
                      type
                      data
                    }
                    show
                  }
                  attributionType
                  teams {
                    _id
                    name
                  }
                  teamgroups {
                    _id
                    name
                  }
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getBlogSections.map((section, i) => {
                  return {
                    index: i,
                    id: section._id,
                    name: section.name,
                    displayIndex: section.displayIndex,
                    type: section.type,
                    categories: section.categories,
                    articles: section.articles,
                    attributionType: section.attributionType,
                    teams: section.teams,
                    teamgroups: section.teamgroups,
                  };
                }),
                total: response.data.getBlogSections.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getBlogSectionById($id: ID!) {
                getBlogSectionById(_id: $id) {
                  _id
                  displayIndex
                  name
                  type
                  categories {
                    _id
                    name
                    image
                    articles {
                      _id
                      smallTitle
                      title
                      image
                      smallDescription
                      description
                      content {
                        title
                        type
                        data
                      }
                      show
                    }
                    show
                  }
                  articles {
                    _id
                    smallTitle
                    title
                    image
                    smallDescription
                    description
                    content {
                      title
                      type
                      data
                    }
                    show
                  }
                  attributionType
                  teams {
                    _id
                    name
                  }
                  teamgroups {
                    _id
                    name
                  }
                }
              }
            `,

            parseResponse: (response) => {
              let data = response.data.getBlogSectionById;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "DELETE":
          return {
            query: gql`
              mutation deleteBlogSection($id: ID!) {
                deleteBlogSection(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };

        default:
          break;
      }
    } else if (resourceName === "BlogCategories") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getBlogCategorys {
                getBlogCategorys {
                  _id
                  name
                  image
                  description
                  articles {
                    _id
                    smallTitle
                    title
                    image
                    smallDescription
                    description
                    content {
                      title
                      type
                      data
                    }
                    show
                  }
                  show
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getBlogCategorys.map((section, i) => {
                  return {
                    index: i,
                    id: section._id,
                    name: section.name,
                    image: section.image,
                    description: section.description,
                    categories: section.categories,
                    articles: section.articles,
                    show: section.show,
                  };
                }),
                total: response.data.getBlogCategorys.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getBlogCategorys {
                getBlogCategorys {
                  _id
                  name
                  image
                  description
                  articles {
                    _id
                    smallTitle
                    title
                    image
                    smallDescription
                    description
                    content {
                      title
                      type
                      data
                    }
                    show
                  }
                  show
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getBlogCategorys.map((section, i) => {
                  return {
                    index: i,
                    id: section._id,
                    name: section.name,
                    image: section.image,
                    description: section.description,
                    categories: section.categories,
                    articles: section.articles,
                    show: section.show,
                  };
                }),
                total: response.data.getBlogCategorys.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getBlogCategoryById($id: ID!) {
                getBlogCategoryById(_id: $id) {
                  _id
                  name
                  image
                  description
                  articles {
                    _id
                    smallTitle
                    title
                    image
                    smallDescription
                    description
                    content {
                      title
                      type
                      data
                    }
                    show
                  }
                  show
                }
              }
            `,

            parseResponse: (response) => {
              let data = response.data.getBlogCategoryById;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "DELETE":
          return {
            query: gql`
              mutation deleteBlogCategory($id: ID!) {
                deleteBlogCategory(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };

        default:
          break;
      }
    } else if (resourceName === "BlogArticles") {
      switch (raFetchType) {
        case "GET_LIST":
          return {
            query: gql`
              query getBlogArticles {
                getBlogArticles {
                  _id
                  smallTitle
                  title
                  image
                  smallDescription
                  description
                  content {
                    title
                    type
                    data
                  }
                  show
                  categories
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getBlogArticles.map((section, i) => {
                  return {
                    index: i,
                    id: section._id,
                    smallTitle: section.smallTitle,
                    title: section.title,
                    image: section.image,
                    smallDescription: section.smallDescription,
                    description: section.description,
                    content: section.content,
                    show: section.show,
                    categories: section.categories,
                  };
                }),
                total: response.data.getBlogArticles.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_MANY":
          return {
            query: gql`
              query getBlogArticles {
                getBlogArticles {
                  _id
                  smallTitle
                  title
                  image
                  smallDescription
                  description
                  content {
                    title
                    type
                    data
                  }
                  show
                }
              }
            `,
            parseResponse: (response) => {
              console.log(response);
              return {
                data: response.data.getBlogArticles.map((section, i) => {
                  return {
                    index: i,
                    id: section._id,
                    smallTitle: section.smallTitle,
                    title: section.title,
                    image: section.image,
                    smallDescription: section.smallDescription,
                    description: section.description,
                    title: section.title,
                    show: section.show,
                  };
                }),
                total: response.data.getBlogArticles.length,
              };
            },
            parseError: (error) => console.log("getListError:", error),
          };
        case "GET_ONE":
          return {
            query: gql`
              query getBlogArticleById($id: ID!) {
                getBlogArticleById(_id: $id) {
                  _id
                  smallTitle
                  title
                  image
                  smallDescription
                  description
                  content {
                    title
                    type
                    data
                  }
                  show
                }
              }
            `,

            parseResponse: (response) => {
              let data = response.data.getBlogArticleById;
              return {
                data: { ...data, id: 1 },
              };
            },
            variables: { id: "" },
            parseError: (error) => console.log(error),
          };
        case "DELETE":
          return {
            query: gql`
              mutation deleteBlogArticle($id: ID!) {
                deleteBlogArticle(_id: $id)
              }
            `,
            parseResponse: (response) => {
              return {
                data: { result: "deleted" },
              };
            },
            variables: { id: params.id },
            parseError: (error) => console.log(error),
          };

        default:
          break;
      }
    }
  };

class App extends Component {
  constructor() {
    super();
    this.state = { dataProvider: null, id: "" };
  }

  componentDidMount() {
    buildGraphQLProvider({
      clientOptions: client,
      buildQuery: buildQuery,
    })
      .then((dataProvider) => this.setState({ dataProvider }))
      .catch((err) => console.log("dataProviderError:", err));
  }

  render() {
    const { dataProvider } = this.state;
    if (!dataProvider) {
      return <div>Loading</div>;
    }

    return (
      <Admin
        layout={MyLayout}
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}
        /*customRoutes={[
          <Route
            key="profile"
            path="/my-profile"
            render={() => <ProfileEdit />}
          />,
        ]}*/
        theme={theme}
        appLayout={MyLayout}
      >
        <Resource
          name="categories"
          list={CategoriesList}
          edit={CategoriesEdit}
          create={CategoriesCreate}
          show={CategoriesShow}
          icon={CategoryIcon}
        />
        <Resource
          name="partners"
          list={PartnersList}
          edit={PartnersEdit}
          create={PartnersCreate}
          show={PartnersShow}
          icon={AssignmentIndIcon}
        />
        <Resource
          name="gains"
          list={GainsList}
          edit={GainsEdit}
          create={GainsCreate}
          show={GainsShow}
          icon={AttachMoney}
        />
        <Resource
          name="discountCodes"
          list={DiscountCodesList}
          edit={DiscountCodesEdit}
          create={DiscountCodesCreate}
          show={DiscountCodesShow}
          icon={TrendingDownIcon}
        />
        <Resource
          name="establishments"
          list={EstablishmentsList}
          edit={EstablishmentsEdit}
          create={EstablishmentsCreate}
          show={EstablishmentsShow}
          icon={HomeOutlined}
        />

        <Resource
          name="sponsorisedPost"
          list={SponsorisedPostList}
          edit={SponsorisedPostEdit}
          create={SponsorisedPostCreate}
          show={SponsorisedPostShow}
          icon={MonetizationOn}
        />
        <Resource
          name="Teams"
          list={TeamsList}
          edit={TeamsEdit}
          create={TeamsCreate}
          show={TeamsShow}
          icon={TrendingDownIcon}
        />
        <Resource
          name="TeamsGroups"
          list={TeamsGroupsList}
          edit={TeamGroupsEdit}
          create={TeamGroupsCreate}
          show={TeamGroupShow}
          icon={TrendingDownIcon}
        />
        <Resource
          name="users"
          list={UsersList}
          show={UserShow}
          icon={UserIcon}
        />
        <Resource
          name="exerciceTypes"
          list={ExerciceTypesList}
          edit={ExerciceTypesEdit}
          create={ExerciceTypesCreate}
          show={ExerciceTypesShow}
          icon={Accessibility}
        />
        <Resource
          name="croudFundeds"
          list={CroudFundedsList}
          edit={CroundFundedsEdit}
          create={CroudFundedsCreate}
          show={CroudFundedsShow}
          icon={SupervisedUserCircleIcon}
        />
        <Resource
          name="offers"
          list={OffersList}
          edit={OffersEdit}
          create={OffersCreate}
          show={OffersShow}
          icon={LocalOfferIcon}
        />
        <Resource
          name="feedAds"
          list={FeedAdsList}
          edit={FeedAdEdit}
          create={FeedAdCreate}
          show={FeedAdShow}
          icon={AutoAwesomeMotionIcon}
        />
        <Resource
          name="jackpot"
          list={JackpotList}
          edit={JackpotEdit}
          create={JackpotCreate}
          show={JackpotShow}
          icon={LocalOfferIcon}
        />
        <Resource
          name="BlogSections"
          list={BlogSectionsList}
          create={BlogSectionCreate}
          edit={BlogSectionEdit}
          show={BlogSectionsShow}
          icon={TrendingDownIcon}
        />
        <Resource
          name="BlogCategories"
          list={BlogCategoriesList}
          create={BlogCategoryCreate}
          edit={BlogCategorysEdit}
          show={BlogCategoriesShow}
          icon={TrendingDownIcon}
        />
        <Resource
          name="BlogArticles"
          list={BlogArticlesList}
          create={BlogArticleCreate}
          edit={BlogArticleEdit}
          show={BlogArticlesShow}
          icon={TrendingDownIcon}
        />
      </Admin>
    );
  }
}
export default App;
