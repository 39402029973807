import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  ShowButton,
  Show,
  SimpleShowLayout,
  ImageField,
} from "react-admin";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({}));

const UsersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const UsersList = (props) => {
  const classes = useStyles();
  return (
    <List
      //filters={<UsersFilter />}
      {...props}
      title="List des utilisateurs"
      pagination={""}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const UserTitle = ({ record }) => {
  return (
    <span>User {record ? `"${record.firstName} ${record.lastName}"` : ""}</span>
  );
};

export const UserShow = (props) => (
  <Show title={<UserTitle />} {...props}>
    <SimpleShowLayout>
      <Grid container direction="row">
        <Grid item xs={3}>
          <ImageField label="" source="avatar" />
        </Grid>
        <Grid item xs={9}>
          <Grid container direction="row" style={{ marginBottom: 15 }}>
            <Grid item xs={2}>
              <Typography variant="h8" color="grey">
                Firstname
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField source="firstName" />
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginBottom: 15 }}>
            <Grid item xs={2}>
              <Typography variant="h8" color="grey">
                Lastname
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField source="lastName" />
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginBottom: 15 }}>
            <Grid item xs={2}>
              <Typography variant="h8" color="grey">
                Email
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField source="email" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);
