import React, {
    createContext,
    useState,
    useCallback,
    useMemo,
    useContext
  } from "react";
  import {
    TextInput,
    ImageInput,
    ImageField,
    SimpleForm,
    required,
    useDataProvider,
    useNotify,
    SaveContextProvider,
    useGetIdentity,
    Resource
  } from "react-admin";
  import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
  
const styles = (theme) => ({
    formInput: {
      width: "400px",
      marginLeft: 50
    },
    image: {
        width: "100%",
        heigth: "50%",
        borderRadius: "50%"
    }
  });

  const ProfileContext = createContext();
  
  export const ProfileProvider = ({ children }) => {
    const [profileVersion, setProfileVersion] = useState(0);
    const context = useMemo(
      () => ({
        profileVersion,
        refreshProfile: () =>
          setProfileVersion((currentVersion) => currentVersion + 1)
      }),
      [profileVersion]
    );
  
    return (
      <ProfileContext.Provider value={context}>
        {children}
      </ProfileContext.Provider>
    );
  };
  
  export const useProfile = () => useContext(ProfileContext);
  
  function ProfileEdit ({ staticContext, ...props }) {
    const { classes } = props;
    
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [saving, setSaving] = useState();
    const { refreshProfile } = useProfile();
  
    const { loaded, identity } = useGetIdentity();
  
    const handleSave = useCallback(
      (values) => {
        setSaving(true);
        dataProvider.UPDATE("profile",
          { data: values },
          {
            onSuccess: ({ data }) => {
              setSaving(false);
              notify("Your profile has been updated", "info", {
                _: "Your profile has been updated"
              });
              refreshProfile();
            },
            onFailure: () => {
              setSaving(false);
              notify(
                "A technical error occured while updating your profile. Please try later.",
                "warning",
                {
                  _:
                    "A technical error occured while updating your profile. Please try later."
                }
              );
            }
          }
        );
      },
      [dataProvider, notify, refreshProfile]
    );
  
    const saveContext = useMemo(
      () => ({
        save: handleSave,
        saving
      }),
      [saving, handleSave]
    );
  
    if (!loaded) {
      return null;
    }
  console.log("EditProfile:", identity)
    return (
      <SaveContextProvider value={saveContext} >
        <SimpleForm save={handleSave} record={identity ? identity : {}} >
        <Grid container direction="row">
          <Grid item xs={3}>
            {identity.avatar ? (
              <img label="" src={identity.avatar} className={classes.image} />
            ):(
              <ImageInput source="avatar" label="rofile pictures" accept="image/*">
                <ImageField source="avatar" title="title" />
              </ImageInput>
            )}
          </Grid>
          <Grid item xs={8}>
            <TextInput
              label="Prénom"
              source="firstName"
              className={classes.formInput}
            />
            <TextInput
              label="Nom"
              source="lastName"
              className={classes.formInput}
            />
            <TextInput
              labnel="Email"
              source="email"
              disabled
              className={classes.formInput}
            />
            <TextInput
              label="Country"
              source="country"
              className={classes.formInput}
            />
            {/*<TextInput
              multiline
              label="Reason"
              source="reason"
              className={classes.formInput}
            />*/}
          </Grid>
        </Grid>
        </SimpleForm>
      </SaveContextProvider>
    );
  };
  
  export default withStyles(styles)(ProfileEdit);