import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Edit,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  DateTimeInput,
  ImageField,
  FileField,
  useGetOne,
  Toolbar,
  SaveButton,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router";
import Switch from "@material-ui/core/Switch";

const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
  listOfIngredients: {
    overflowX: "scroll",
    overflowY: "hidden",
    display: "flex",
    width: 900,
    marginLeft: "30%",
  },
});

function GainsEdit(props) {
  const { classes } = props;
  const history = useHistory();
  const { updateGain, reloadGains } = useContext(UserContext);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedVideoImage, setUploadedVideoImage] = useState(null);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [showNewImage, setShowNewImage] = useState(false);
  //const [image, setImage] = useState(null);
  //const [imageVideo, setImageVideo] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [nameVideo, setNameVideo] = useState(null);

  const getUrlFromPath = () => {
    const paths = window.location.href.split("/");
    let url = paths[5];

    return url;
  };

  const { data, loaded } = useGetOne("gains", getUrlFromPath());

  const [newGain, setNewGain] = useState();
  useEffect(() => {
    if (data) {
      setNewGain({
        id: data._id,
        name: data.name,
        subtitle: data.subtitle,
        details: data.details,

        oldImages: data.images,
        images: [],
        categories: data.categories,
        price: data.price,
        spows: data.price.spows,
        parrains: data.price.parrains,
        description: data.description,
        active: data.active ? data.active : false,
      });

      console.log("qqqqqqqqqqqqqqqq:", newGain);
    }
  }, [data]);

  const [loader, setLoader] = useState(false);

  if (!loaded && !newGain) return <span>Loading</span>;

  const handleChange = (event) => {
    const value = event.target.value;
    let field = event.target.name;
    if (
      field.split(".") &&
      field.split(".")[1] &&
      field.split(".")[0] !== "categories[0]"
    ) {
      field = field.split(".")[1];
    }
    console.log(field);
    setNewGain({ ...newGain, [field]: value });
  };
  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setNewGain({ ...newGain, categories: value });
  };

  function removeItem(index) {
    setNewGain((prevnewGain) => {
      let items = prevnewGain;
      let listImages = [...items.oldImages];
      listImages.splice(index, 1);
      const updatedGain = { ...prevnewGain, oldImages: listImages };
      return updatedGain;
    });
  }

  const handleAddPicture = () => {
    const fileInput = document.getElementById("newImageInput");
    fileInput.click();
  };
  const handleActiveChange = (event) => {
    setNewGain({ ...newGain, active: event.target.checked });
  };
  const handleNewImageChange = (event) => {
    const img = event.target.files[0];
    if (img) {
      setNewGain((prevnewGain) => {
        let items = { ...prevnewGain };
        let listNewImages = items.images;

        const updatedNewImages = [...listNewImages, img];
        items = { ...prevnewGain, images: updatedNewImages };
        return items;
      });
      setShowNewImage(true);
      setNewImage(img);
    }
  };

  function removeNewItem(index) {
    setNewGain((prevnewGain) => {
      let items = prevnewGain;
      let listNewImages = [...items.images];
      listNewImages.splice(index, 1);
      const updatedGain = { ...prevnewGain, images: listNewImages };
      return updatedGain;
    });
  }

  function handleSave() {
    console.log("update:", newGain);
    updateGain(getUrlFromPath(), newGain);
    setLoader(!loader);
    setTimeout(() => {
      history.push("/gains");
    }, 3000);
  }

  const ImagesListField = ({ record }) => {
    return (
      <div>
        <Typography variant="h6" color="grey">
          {" "}
          Images :{" "}
        </Typography>
        {newGain &&
          newGain.oldImages.length &&
          newGain.oldImages.map((item, index) => (
            <div>
              <Grid container direction="row">
                <Grid item xs={2}>
                  <img
                    src={item}
                    alt="image"
                    style={{ height: 100, width: 200 }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => removeItem(index)}
                  >
                    <DeleteIcon style={{ color: "#FF0000" }} />
                  </IconButton>
                </Grid>
              </Grid>
              <br />
            </div>
          ))}
      </div>
    );
  };

  const NewImagesListField = () => {
    return (
      <div style={{ display: showNewImage ? "block" : "none" }}>
        {newGain &&
          newGain.images.length &&
          newGain.images.map((item, index) => (
            <div>
              <Grid container direction="row">
                <Grid item xs={2}>
                  <img
                    src={URL.createObjectURL(item)}
                    alt="image"
                    style={{ height: 100, width: 200 }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => removeNewItem(index)}
                  >
                    <DeleteIcon style={{ color: "#FF0000" }} />
                  </IconButton>
                </Grid>
              </Grid>
              <br />
            </div>
          ))}
      </div>
    );
  };

  const GainsTitle = ({ record }) => {
    return <span>Cround Funded {record ? `"${record.name}"` : ""}</span>;
  };

  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };

  return (
    <Edit title={<GainsTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <span>Active Gain</span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={newGain && newGain.active ? newGain.active : false}
              onChange={handleActiveChange}
              color="primary"
              name="active"
              label="Active Gain"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              source="name"
              name="name"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <ReferenceInput
              label="Categories"
              source="categories[0]._id"
              reference="categories"
              validate={[required()]}
              //name="categories"
              onChange={handleCategoryChange}
              className={classes.formInput}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              multiline
              source="subtitle"
              name="subtitle"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              multiline
              source="details"
              name="details"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <NumberInput
              source="price.spows"
              label="Value in spows"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <NumberInput
              source="price.parrains"
              label="Value in parrains"
              onChange={handleChange}
              className={classes.formInput}
            />
          </Grid>
        </Grid>

        <TextInput
          multiline
          source="description"
          name="description"
          onChange={handleChange}
          style={{ width: "100%" }}
        />

        <ImagesListField record={data} />

        <NewImagesListField />
        <Grid container direction="row" onClick={() => handleAddPicture()}>
          <Grid item xs={1}>
            <IconButton edge="end" color="inherit">
              <AddCircleOutlineIcon />
            </IconButton>
            <input
              type="file"
              id="newImageInput"
              onChange={handleNewImageChange}
              hidden="hidden"
              accept="image/*"
            />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>

        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Edit>
  );
}

export default withStyles(styles)(GainsEdit);
