import React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  ArrayInput,
  SimpleFormIterator,
  useNotify,
  useRefresh,
  FormDataConsumer,
  useRedirect,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function SponsorisedPostCreate(props) {
  const { classes } = props;
  const [sponsorisedPostType, setSponsorisedPostType] = React.useState("OFFER");
  const [canClose, setCanClose] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [isFirst, setIsFirst] = React.useState(false);
  const [content, setContent] = React.useState([]);
  const handleChange = (event) => {
    const value = event.target.value;
    setSponsorisedPostType(value);
  };
  const handleContentChange = (event, item) => {
    console.log(item);
    const value = event.target.value;
    let i = item.split("[")[1].split("]")[0];

    setContent((oldArr) => {
      let arr = oldArr;
      arr[i] = value;
      return arr;
    });
    console.log(event.target);
  };
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`Gain created`);
    redirect("/sponsorisedPost");
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={1}>
            <span>is first</span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={isFirst}
              color="primary"
              name="isFirst"
              source="isFirst"
              label="isFirst"
              inputProps={{ "aria-label": "primary checkbox" }}
              onChange={(e) => {
                setIsFirst(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <span>Active sponsorisedPost</span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={active}
              color="primary"
              name="active"
              source="active"
              label="Active sponsorisedPost"
              inputProps={{ "aria-label": "primary checkbox" }}
              onChange={(e) => {
                setActive(e.target.checked);
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={1}>
            <span>can close</span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={canClose}
              color="primary"
              name="canClose"
              label="canClose"
              source="canClose"
              inputProps={{ "aria-label": "primary checkbox" }}
              onChange={(e) => {
                console.log(e.target.checked);
                setCanClose(e.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <SelectInput
              source="type"
              choices={[
                { id: "OFFER", name: "offer Post" },
                { id: "CROUDFUND", name: "croudfund Post" },
                { id: "GAIN", name: "gain Post" },
                { id: "MARKETPLACE", name: "marketplace Post" },
                { id: "SIMPLE", name: "simple Post" },
              ]}
              name="type"
              label="sponsorisedPost Type"
              style={{ width: "100%" }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={4}>
            <TextInput source="title" className={classes.formInput} />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput source="subTitle" className={classes.formInput} />
          </Grid>
        </Grid>
        {sponsorisedPostType && sponsorisedPostType !== "SIMPLE" ? (
          sponsorisedPostType === "OFFER" ? (
            <Grid item xs={4}>
              <ReferenceInput
                source="offer"
                style={{ width: "80%" }}
                reference="offers"
              >
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </Grid>
          ) : sponsorisedPostType === "CROUDFUND" ? (
            <Grid item xs={4}>
              <ReferenceInput
                source="croudFund"
                style={{ width: "80%" }}
                reference="croudFundeds"
              >
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </Grid>
          ) : sponsorisedPostType === "GAIN" ? (
            <Grid item xs={4}>
              <ReferenceInput
                source="gain"
                style={{ width: "80%" }}
                reference="gains"
              >
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </Grid>
          ) : (
            <Grid
              container
              xs={12}
              direction="row"
              style={{ width: "100%", flexWrap: "wrap" }}
            >
              <Grid item xs={4}>
                <ReferenceInput
                  source="offer"
                  style={{ width: "80%" }}
                  reference="offers"
                >
                  <SelectInput optionText="name" optionValue="id" />
                </ReferenceInput>
              </Grid>

              <Grid item xs={4}>
                <ReferenceInput
                  source="croudFund"
                  style={{ width: "80%" }}
                  reference="croudFundeds"
                >
                  <SelectInput optionText="name" optionValue="id" />
                </ReferenceInput>
              </Grid>

              <Grid item xs={4}>
                <ReferenceInput
                  source="gain"
                  style={{ width: "80%" }}
                  reference="gains"
                >
                  <SelectInput optionText="name" optionValue="id" />
                </ReferenceInput>
              </Grid>
            </Grid>
          )
        ) : (
          <></>
        )}

        <Grid container direction="row" style={{ width: "100%" }}></Grid>
        <ImageInput source="coverImage" label="coverImage" accept="image/*">
          <ImageField name="image" title="title" />
        </ImageInput>

        <Grid container direction="row" style={{ width: "100%" }}></Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={12}>
            <ArrayInput direction="column" source="content">
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => {
                    return (
                      <SelectInput
                        choices={[
                          { id: "TEXTBLOCK", name: "text block" },
                          { id: "TITLE", name: "title" },
                          { id: "VIDEO", name: "video" },
                          { id: "IMAGE", name: "image" },
                          { id: "OFFERACTION", name: "offer action" },
                          { id: "CROUDFUNDACTION", name: "croudfund action" },
                          { id: "GAINACTION", name: "gain action" },
                          { id: "OFFER", name: "offer card" },
                          { id: "CROUDFUND", name: "croudfund card" },
                          { id: "GAIN", name: "gain card" },
                        ]}
                        source={
                          "content[" +
                          getSource("index").split("[")[1].split("]")[0] +
                          "].type"
                        }
                        label={
                          "content " +
                          getSource("index").split("[")[1].split("]")[0] +
                          " type"
                        }
                        style={{ width: "50%" }}
                        onChange={(e) => {
                          handleContentChange(e, getSource("index"));
                        }}
                      />
                    );
                  }}
                </FormDataConsumer>
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => {
                    return content[
                      getSource("index").split("[")[1].split("]")[0]
                    ] === "OFFER" ||
                      content[
                        getSource("index").split("[")[1].split("]")[0]
                      ] === "OFFERACTION" ? (
                      <ReferenceInput
                        source={
                          "content[" +
                          getSource("index").split("[")[1].split("]")[0] +
                          "].offer"
                        }
                        label={
                          "content" +
                          getSource("index").split("[")[1].split("]")[0] +
                          "offer"
                        }
                        reference="offers"
                      >
                        <SelectInput optionText="name" optionValue="id" />
                      </ReferenceInput>
                    ) : content[
                        getSource("index").split("[")[1].split("]")[0]
                      ] === "GAIN" ||
                      content[
                        getSource("index").split("[")[1].split("]")[0]
                      ] === "GAINACTION" ? (
                      <ReferenceInput
                        source={
                          "content[" +
                          getSource("index").split("[")[1].split("]")[0] +
                          "].gain"
                        }
                        label={
                          "content" +
                          getSource("index").split("[")[1].split("]")[0] +
                          "gain"
                        }
                        reference="gains"
                      >
                        <SelectInput optionText="name" optionValue="id" />
                      </ReferenceInput>
                    ) : content[
                        getSource("index").split("[")[1].split("]")[0]
                      ] === "CROUDFUND" ||
                      content[
                        getSource("index").split("[")[1].split("]")[0]
                      ] === "CROUDFUNDACTION" ? (
                      <ReferenceInput
                        label={
                          "content" +
                          getSource("index").split("[")[1].split("]")[0] +
                          "croudfund"
                        }
                        reference="croudFundeds"
                        source={
                          "content[" +
                          getSource("index").split("[")[1].split("]")[0] +
                          "].croudFund"
                        }
                      >
                        <SelectInput optionText="name" optionValue="id" />
                      </ReferenceInput>
                    ) : content[
                        getSource("index").split("[")[1].split("]")[0]
                      ] === "TITLE" ? (
                      <TextInput
                        source={
                          "content[" +
                          getSource("index").split("[")[1].split("]")[0] +
                          "].title"
                        }
                        label="title"
                      />
                    ) : content[
                        getSource("index").split("[")[1].split("]")[0]
                      ] === "TEXTBLOCK" ? (
                      <TextInput
                        source={
                          "content[" +
                          getSource("index").split("[")[1].split("]")[0] +
                          "].text"
                        }
                        multiline
                        label="text"
                        style={{ width: "100%" }}
                      />
                    ) : content[
                        getSource("index").split("[")[1].split("]")[0]
                      ] === "IMAGE" ? (
                      <ImageInput
                        source={
                          "content[" +
                          getSource("index").split("[")[1].split("]")[0] +
                          "].image"
                        }
                        label="image"
                        accept="image/*"
                      >
                        <ImageField name="image" title="title" />
                      </ImageInput>
                    ) : content[
                        getSource("index").split("[")[1].split("]")[0]
                      ] === "VIDEO" ? (
                      <FileInput
                        source={
                          "content[" +
                          getSource("index").split("[")[1].split("]")[0] +
                          "].video"
                        }
                        label="video"
                        accept="video/mp4"
                      >
                        <FileField name="video" title="title" />
                      </FileInput>
                    ) : (
                      <></>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(SponsorisedPostCreate);
