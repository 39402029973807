import React from "react";
import { UserMenu, MenuItemLink } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import { useProfile } from "../profile/ProfileEdit";

const MyUserMenu = (props) => {
  const { profileVersion } = useProfile();

  return (
    <UserMenu key={profileVersion} {...props}>
      {/*<MenuItemLink
        to="/my-profile"
        primaryText="Mon Profil"
        leftIcon={<SettingsIcon />}
      />*/}
    </UserMenu>
  );
};

export default MyUserMenu;
