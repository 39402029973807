import React, { useState, useEffect, useContext } from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  Toolbar,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  useNotify,
  LinearProgress,
  useRefresh,
  useRedirect,
  useGetOne,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { UserContext } from "../../context/UserContext";
import Switch from "@material-ui/core/Switch";
import { gql, useLazyQuery } from "@apollo/client";
const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function ExerciceTypesEdit(props) {
  const history = useHistory();
  const { classes } = props;
  const [loader, setLoader] = React.useState(false);
  const [exerciceTypes, setExerciceTypes] = React.useState({
    id: "",
    name: "",
    garminName: "",
    polarName: "",
    suuntoName: "",
    fitbitName: "",
    appleName: "",
    isActive: false,
    defaultImage: "",
    icon: "",
    calsPerHour: "",
    isGPS: false,
  });
  const { updateExerciceTypes } = useContext(UserContext);
  const handleChange = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    setExerciceTypes({ ...exerciceTypes, [key]: value });
  };
  const getUrlFromPath = () => {
    const paths = window.location.href.split("/");
    let url = paths[5];
    console.log(url);
    return url;
  };
  const [getType] = useLazyQuery(
    gql`
      query getExerciceTypesById($id: ID!) {
        getExerciceTypesById(_id: $id) {
          _id
          name
          garminName
          polarName
          suuntoName
          fitbitName
          appleName
          isActive
          defaultImage
          icon
          isGPS
        }
      }
    `,
    {
      onCompleted: (res) => {
        console.log(res);
        const data = res.getExerciceTypesById;
        console.log(data);
        if (data) {
          setExerciceTypes({
            id: data._id,
            name: data.name,
            garminName: data.garminName,
            polarName: data.polarName,
            suuntoName: data.suuntoName,
            fitbitName: data.fitbitName,
            appleName: data.appleName,
            isActive: data.isActive,
            defaultImage: data.defaultImage,
            icon: data.icon.replace(".svg+xml", ""),
            isGPS: data.isGPS,
            calsPerHour: data.calsPerHour,
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    getType({ variables: { id: getUrlFromPath() } });
  }, []);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleChangeImage = (e) => {
    const value = e;
    const key = "defaultImage";
    setExerciceTypes({ ...exerciceTypes, [key]: value });
  };

  const handleChangeIcon = (e) => {
    const value = e;
    const key = "icon";
    setExerciceTypes({ ...exerciceTypes, [key]: value });
  };
  const handleActiveChange = (event) => {
    setExerciceTypes({ ...exerciceTypes, isActive: event.target.checked });
  };
  const handleGPSChange = (event) => {
    setExerciceTypes({ ...exerciceTypes, isGPS: event.target.checked });
  };

  const onSuccess = () => {
    notify(`ExerciceTypes Editd`);
    redirect("/ExerciceTypes");
    refresh();
  };
  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };

  async function handleSave() {
    console.log("update:", exerciceTypes);
    await updateExerciceTypes(exerciceTypes, getUrlFromPath());
    history.push("/ExerciceTypes");
    setLoader(!loader);
    setTimeout(() => {}, 3000);
  }

  return (
    <Edit onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid item xs={4}></Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={2}>
            <span>Active Type</span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={exerciceTypes.isActive}
              onChange={handleActiveChange}
              color="primary"
              name="isActive"
              label="Active Type"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Grid>
          <Grid item xs={2}>
            <span>GPS ? </span>
          </Grid>
          <Grid item xs={4}>
            <Switch
              checked={exerciceTypes.isGPS}
              onChange={handleGPSChange}
              color="primary"
              name="GPS"
              label="GPS"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="name"
              label="name"
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              name="garminName"
              label="garminName"
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="suuntoName"
              label="suuntoName"
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              name="fitbitName"
              label="fitbitName"
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="appleName"
              label="appleName"
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              name="polarName"
              label="polarName"
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <NumberInput
              label="calsPerHour"
              name="calsPerHour"
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <ImageField
          source="defaultImage"
          label="old defaultImage"
          title="title"
        />
        <ImageInput
          name="image"
          label="image"
          accept="image/*"
          onChange={handleChangeImage}
        >
          <ImageField name="defaultImage" title="title" />
        </ImageInput>

        <ImageField source="icon" label="old icon" title="title" />
        <ImageInput
          name="icon"
          label="icon"
          accept="image/*"
          onChange={handleChangeIcon}
        >
          <ImageField name="icon" title="title" />
        </ImageInput>

        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Edit>
  );
}

export default withStyles(styles)(ExerciceTypesEdit);
