import React, { useState, useEffect, useContext } from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  Toolbar,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  useNotify,
  LinearProgress,
  useRefresh,
  useRedirect,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { UserContext } from "../../context/UserContext";
import Switch from "@material-ui/core/Switch";
const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function TeamsCreate(props) {
  const history = useHistory();
  const { classes } = props;
  const [loader, setLoader] = React.useState(false);
  const [Team, setTeam] = React.useState({
    description: "",
    image: "",
    name: "",
    managerId: "",
    code: "",
  });
  const { createTeam } = useContext(UserContext);
  const handleChange = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    setTeam({ ...Team, [key]: value });
  };

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleChangeImage = (e) => {
    const value = e;
    const key = "image";
    setTeam({ ...Team, [key]: value });
  };

  const onSuccess = () => {
    notify(`Team created`);
    redirect("/Teams");
    refresh();
  };
  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton submitOnEnter={true} />
      </Toolbar>
    );
  };

  async function handleSave() {
    console.log("update:", Team);
    await createTeam(Team);
    history.push("/Teams");
    setLoader(!loader);
    setTimeout(() => {}, 3000);
  }

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} save={handleSave}>
        <Grid container direction="row" style={{ width: "100%" }}>
          <Grid item xs={5}>
            <TextInput
              name="name"
              label="name"
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <TextInput
              name="description"
              label="description"
              className={classes.formInput}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item xs={5}>
              <ReferenceInput
                label="manager"
                source="managerId"
                reference="users"
                validate={[required()]}
                className={classes.formInput}
                onChange={handleChange}
              >
                <SelectInput optionText="email" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <TextInput
                name="code"
                label="code"
                className={classes.formInput}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>

        <ImageInput
          name="image"
          label="image"
          accept="image/*"
          onChange={handleChangeImage}
        >
          <ImageField name="image" title="title" />
        </ImageInput>

        <LinearProgress
          style={{ display: loader ? "block" : "none", width: "100%" }}
        />
      </SimpleForm>
    </Create>
  );
}

export default withStyles(styles)(TeamsCreate);
