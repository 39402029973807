import { gql } from "@apollo/client";

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;
export const UPDATE_ESTABLISHMENT = gql`
  mutation updateEstablishment($id: String, $input: UpdateEstablishmentInput) {
    updateEstablishment(id: $id, input: $input)
  }
`;
export const UPDATE_TEAM = gql`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input)
  }
`;
export const CREATE_TEAM = gql`
  mutation createTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      _id
      managerId
      name
      description
      image
      code
    }
  }
`;

export const CREATE_TEAM_GROUP = gql`
  mutation createTeamGroup($input: CreateTeamGroupInput!) {
    createTeamGroup(input: $input) {
      _id
      description
      image
      managerId
      name
    }
  }
`;
export const UPDATE_TEAM_GROUP = gql`
  mutation updateTeamGroup($input: UpdateTeamGroupInput!) {
    updateTeamGroup(input: $input)
  }
`;
export const CREATE_ESTABLISHMENT = gql`
  mutation createEstablishment($input: CreateEstablishmentInput!) {
    createEstablishment(input: $input) {
      _id
      image
      name
      address {
        lat
        lng
      }
      logo
      type
      activityType
      caloriesPerMinute
      value
    }
  }
`;
export const LOGIN_ADMIN = gql`
  mutation loginAdmin($input: LoginUserInput!) {
    loginAdmin(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const LOAD_ME = gql`
  {
    me {
      _id
      local {
        email
      }
      firstName
      lastName
      fullName
      avatar
      gender
      country
      reason
    }
  }
`;

export const CREATE_CODES = gql`
  mutation createCode($input: CreateCodeInput!) {
    createCode(input: $input) {
      _id
      type
      code
    }
  }
`;

export const UPDATE_CODE = gql`
  mutation updateCode($id: ID!, $input: UpdateCodeInput) {
    updateCode(_id: $id, input: $input) {
      _id
      type
      code
    }
  }
`;

export const UPDATE_CROUD_FUNDED = gql`
  mutation updateCroudFunded($id: String, $input: UpdateCroudFundedInput) {
    updateCroudFunded(_id: $id, input: $input) {
      _id
      name
    }
  }
`;
export const UPDATE_GAIN = gql`
  mutation updateGain($id: String, $input: UpdateGainInput) {
    updateGain(_id: $id, input: $input) {
      _id
      name
    }
  }
`;
export const UPDATE_SPONSORISEDPOST = gql`
  mutation updateSponsorisedPost(
    $id: String
    $input: UpdateSponsorisedPostInput
  ) {
    updateSponsorisedPost(id: $id, input: $input)
  }
`;
export const UPDATE_OFFER = gql`
  mutation updateSwOffer($id: String, $input: UpdateSwOfferInput) {
    updateSwOffer(_id: $id, input: $input) {
      _id
    }
  }
`;

export const UPDATE_MARKET_PLACE_PICTURE = gql`
  mutation uploadMarketPlacePicture($file: Upload!) {
    uploadMarketPlacePicture(file: $file)
  }
`;

export const CREATE_FEED_AD = gql`
  mutation createFeedAd($input: CreateFeedAdInput!) {
    createFeedAd(input: $input) {
      _id
      link
      image
    }
  }
`;
export const UPDATE_FEED_AD = gql`
  mutation updateFeedAd($id: ID!, $input: UpdateFeedAdInput) {
    updateFeedAd(_id: $id, input: $input)
  }
`;

export const CREATE_JACKPOT = gql`
  mutation createJackpot($input: CreateJackpotInput!) {
    createJackpot(input: $input) {
      _id
      text
      image
    }
  }
`;

export const UPDATE_JACKPOT = gql`
  mutation updateJackpot($id: ID!, $input: UpdateJackpotInput) {
    updateJackpot(_id: $id, input: $input)
  }
`;

export const CREATE_BLOG_SECTION = gql`
  mutation createBlogSection($input: CreateBlogSectionInput!) {
    createBlogSection(input: $input) {
      _id
      displayIndex
      name
      type
    }
  }
`;
export const UPDATE_BLOG_SECTION = gql`
  mutation updateBlogSection($input: UpdateBlogSectionInput!) {
    updateBlogSection(input: $input)
  }
`;

export const CREATE_BLOG_ARTICLE = gql`
  mutation createBlogArticle($input: CreateBlogArticleInput!) {
    createBlogArticle(input: $input) {
      _id
      title
    }
  }
`;

export const UPDATE_BLOG_ARTICLE = gql`
  mutation updateBlogArticle($input: UpdateBlogArticleInput!) {
    updateBlogArticle(input: $input)
  }
`;

export const CREATE_BLOG_CATEGORY = gql`
  mutation createBlogCategory($input: CreateBlogCategoryInput!) {
    createBlogCategory(input: $input) {
      _id
      name
    }
  }
`;

export const UPDATE_BLOG_CATEGORY = gql`
  mutation updateBlogCategory($input: UpdateBlogCategoryInput!) {
    updateBlogCategory(input: $input)
  }
`;
