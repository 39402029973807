import React from "react";
import { 
    Show, 
    SimpleShowLayout,
    TextField,
    ImageField,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

const styles = (theme) => ({
  formInput: {
    width: "100%",
  },
});

function ProfileShow(props) {
    const { classes } = props;

    const ProfileTitle = ({ record }) => {
        return <span>Profile {record ? `"${record.fullName}"` : ''}</span>;
    };

  return (
    <Show title={<ProfileTitle />} >
      <SimpleShowLayout >
        <Grid container direction="row">
          <Grid item xs={10}>
            <ImageField label="" source="avatar" className={classes.image} />
          </Grid>
          <Grid item xs={2}>
            <TextField source="firstName" className={classes.formInput}/>
            <TextField source="lastName" className={classes.formInput}/>
            <TextField source="email" className={classes.formInput}/>
            <TextField source="country" className={classes.formInput}/>
            <TextField source="gender" className={classes.formInput}/>
          </Grid>
        </Grid>
      </SimpleShowLayout>
  </Show>
  );
}

export default withStyles(styles)(ProfileShow);
