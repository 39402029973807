import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
  SimpleShowLayout,
  DeleteButton,
  ImageField,
  ArrayField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

export const BlogSectionsList = (props) => {
  const classes = useStyles();

  return (
    <List {...props} title="List des sections">
      <Datagrid>
        <TextField source="id" />
        <TextField source="displayIndex" />
        <TextField source="name" />
        <TextField source="type" />
        <TextField source="attributionType" />
        <EditButton />
        <ShowButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const BlogSectionsTitle = ({ record }) => {
  return <span>BlogSections {record ? `"${record.name}"` : ""}</span>;
};
const BlogArticlesImageField = ({ record }) => {
  return record.type === "IMAGE" ? (
    <div>
      <img
        src={record.data}
        title={record.title}
        style={{ height: 150, width: 150 }}
      />
    </div>
  ) : (
    <span>{record.data}</span>
  );
};

export const BlogSectionsShow = (props) => (
  <Show title={<BlogSectionsTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" disabled />
      <TextField source="displayIndex" />
      <TextField source="name" />
      <TextField source="type" />
      <TextField source="attributionType" />
      <ArrayField source="articles">
        <Datagrid>
          <TextField source="_id" />
          <TextField source="smallTitle" />
          <TextField source="title" />
          <TextField source="smallDescription" />
          <TextField source="description" />
          <ArrayField source="content">
            <Datagrid>
              <TextField source="title" />
              <TextField source="type" />
              <BlogArticlesImageField {...props} />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ArrayField>
      <ArrayField source="categories">
        <Datagrid>
          <TextField source="_id" />
          <TextField source="name" />
          <ArrayField source="articles">
            <Datagrid>
              <TextField source="_id" />
              <TextField source="smallTitle" />
              <TextField source="title" />
              <TextField source="smallDescription" />
              <TextField source="description" />
              <ArrayField source="content">
                <Datagrid>
                  <TextField source="title" />
                  <TextField source="type" />
                  <BlogArticlesImageField {...props} />
                </Datagrid>
              </ArrayField>
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ArrayField>
      <ArrayField source="teams">
        <Datagrid>
          <TextField source="_id" />
          <TextField source="name" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="teamgroups">
        <Datagrid>
          <TextField source="_id" />
          <TextField source="name" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
